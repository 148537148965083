import React, { useEffect, useState } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import TemplateForm from "./components/TemplateForm";
import "./components/style.css"
import PreviewRcsTemplate from "./components/PreviewRcsTemplate";
import { getRCSSenderID, NODE_HOST_URL, RCSAPI } from "../api/Api";
import Alert from "../../UI/Alert/Alert";
import Axios from "axios";


const RcsTemplate = (props) => {

  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;

  const updateId = props.match.params.id;

  const [rcsSenderData, setRcsSenderData] = useState([]);
  const [selectSenderId, setselectSenderId] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const [formData, setFormData] = useState({
    templateName: "",
    templateType: "",
    content: "",
    senderId: '',
    botMessageType: '',
    carousels: [
      {
        "card_width": "",
        "media_height": "",
        "card_title": "",
        "media": {
          "file_type": "",
          "url": ""
        },
        "card_description": "",
        "temp_suggestion": []
      },
      {
        "card_width": "",
        "media_height": "",
        "card_title": "",
        "media": {
          "file_type": "",
          "url": ""
        },
        "card_description": "",
        "temp_suggestion": []
      }
    ],
    suggestionsButton: [],
    standaloneCard: {
      card_oriention: "",
      card_width: "",
      media_height: "",
      cardAlign: "",
      media: {},
      card_title: "",
      card_description: "",
      temp_suggestion: [],
    },
    activeTabIndex: "0"
  });

  const [error, setError] = useState({
    isError: false,
    messageType: '',
    errorMessage: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Template Change - Reset data when template changes
  const handleTemplateChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      templateType: value,
      carousels: [
        {
          "card_width": "",
          "media_height": "",
          "card_title": "",
          "media": {
            "file_type": "",
            "url": ""
          },
          "card_description": "",
          "temp_suggestion": []
        },
        {
          "card_width": "",
          "media_height": "",
          "card_title": "",
          "media": {
            "file_type": "",
            "url": ""
          },
          "card_description": "",
          "temp_suggestion": []
        }
      ],
      suggestionsButton: [],
      content: "",
      standaloneCard: {
        card_oriention: "",
        card_width: "",
        media_height: "",
        cardAlign: "",
        media: {},
        card_title: "",
        card_description: "",
        temp_suggestion: [],
      }, // Reset standalone card data
    }));
  };

  // create RCS templte
  const createRCSTemplatre = async () => {

    document.getElementById('name-of-template').scrollIntoView({
      behavior: 'smooth'
    })

    setError({
      isError: false,
      messageType: '',
      errorMessage: ''
    })

    setisLoading(true);

    const payload = {
      "user_id": userId,
      "token": userToken,
      "method": updateId ? "update_temp" : "create_temp",
      "sender_id": formData.senderId,
      "temp_name": formData.templateName,
      "temp_language": "en",
      "temp_category": formData.botMessageType,
      "temp_type": formData.templateType,
    }

    if (updateId) {
      payload['temp_id'] = updateId
    }
    if (formData.templateType === 'text') {
      payload['temp_body'] = formData.content
    }

    if (formData.suggestionsButton.length > 0) {
      payload['temp_suggestion'] = formData.suggestionsButton;
    }

    if (formData.templateType === 'richcardstandalone') {
      payload['rich_card'] = [formData.standaloneCard]
    }

    if (formData.templateType === 'richcardcarousel') {
      payload['rich_card'] = formData.carousels
    }



    // call API to create RCS tempate

    try {
      let { data } = await RCSAPI(payload);
      console.log('data', data)
      if (data.success) {
        setError({
          isError: true,
          messageType: 'alert-success',
          errorMessage: data.message
        })
        setTimeout(() => {
          //  window.location.href = "/dashboard/rcs-template-list"
        }, 1000)
      } else {
        setisLoading(false);
        setError({
          isError: true,
          messageType: 'alert-danger',
          errorMessage: data.message
        })
      }

    } catch (error) {
      setisLoading(false);
      console.log('error', error)
    }

  }

  // fetch al sender id details

  useEffect(() => {
    const getRCSData = async () => {
      try {
        const paylod = {
          "user_id": userId,
          "token": userToken,
          "method": 'retrieve_bot'
        }
        let { data } = await getRCSSenderID(paylod);
        setRcsSenderData(data.data)
      } catch (error) {
        console.log('error', error)
      }
    }
    getRCSData();
  }, [])

  const sernderHandler = (e) => {
    let id = e.target.value;
    if (id) {
      let selectdSenderId = rcsSenderData.filter((item) => item.bot_id = id)
      setselectSenderId(selectdSenderId)

      setFormData((prevData) => ({
        ...prevData, senderId: id, botMessageType: selectdSenderId[0].type
      }));
    } else {
      setselectSenderId('')
      setFormData((prevData) => ({
        ...prevData, senderId: '', botMessageType: ""
      }));
    }

  }

  // fetch template of data form ID
  useEffect(() => {
    if (updateId) {
      const paylod = {
        "user_id": userId,
        "token": userToken,
        "method": 'retrieve_temp_dtl',
        "temp_id": updateId
      }
      const fetchRCSTemplateData = async () => {
        let { data } = await RCSAPI(paylod);
        let fetchData = data.data;
        //  console.log('data ==>', data.data[5])
        setFormData({
          templateName: fetchData.temp_name,
          templateType: fetchData.temp_type,
          content: fetchData.temp_body,
          senderId: fetchData.sender_id,
          botMessageType: fetchData.temp_category,
          carousels: fetchData.temp_type === "richcardcarousel" ? fetchData.rich_card : [],
          suggestionsButton: fetchData.temp_suggestion,
          standaloneCard: fetchData.temp_type === "richcardstandalone" ? fetchData.rich_card[0] : [],
        })
      }
      fetchRCSTemplateData();
    }
  }, [])



  return (

    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body">
                    <form id="formSmstemplate">
                      <div className="row justify-content-center">
                        <div className="mb-3 col-md-12">
                          <div id="name-of-template" className="text-left">
                            <h3 className="font-weight-bold mb-4">
                              {updateId ? 'Update' : ' Create New'}    RCS Template
                            </h3>
                          </div>


                          {error.isError && (
                            <Alert type={error.messageType}>
                              {error.errorMessage}
                            </Alert>
                          )}


                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <input
                                type="text"
                                className="form-control"
                                name="templateName"
                                value={formData.templateName}
                                onChange={handleChange}
                                placeholder="Enter Template Name"
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group drop-down-icons col-md-6">
                              <select
                                className="form-control"
                                name="senderId"
                                value={formData.senderId}
                                onChange={sernderHandler}
                              >
                                <option value="">Select Bot ID</option>
                                {rcsSenderData && rcsSenderData.map((item, i) =>
                                  <option key={i} value={item.bot_id}>{item.bot_name}</option>
                                )}

                              </select>
                            </div>
                            <hr class="m-0" />

                            <div className="form-group col-md-6">
                              <select
                                className="form-control"
                                name="botMessageType"
                                disabled
                                value={selectSenderId[0]?.type}
                                onChange={handleChange}
                              >
                                <option value="">Type</option>
                                <option value="promotional">Promotional</option>
                                <option value="transactional">Transactional</option>
                                <option value="otp">OTP</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group drop-down-icons col-md-12">
                              <select
                                className="form-control"
                                name="templateType"
                                value={formData.templateType}
                                onChange={handleTemplateChange}
                              >
                                <option value="">Select Template Type</option>
                                <option value="text">Text</option>
                                <option value="richcardcarousel">Rich card carousal</option>
                                <option value="richcardstandalone">Rich card Stand Alone</option>
                              </select>
                            </div>
                          </div>

                          <TemplateForm
                            templateType={formData.templateType}
                            formData={formData}
                            setFormData={setFormData}
                          />

                          {isLoading ?

                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btn-sm mt-3 btn-primary"
                              >
                                Loading....
                              </button>
                            </div>
                            :
                            <div className="text-right">
                              <button
                                type="button"
                                onClick={() => createRCSTemplatre()}
                                className="btn btn-sm mt-3 btn-primary"
                              >
                                {updateId ? 'Update' : 'Create'} RCS Template
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card">
                  <div className="">
                    <div className="collapse show" id="collapseExample">
                      <div className="links-set">

                        <PreviewRcsTemplate formData={formData} setFormData={setFormData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <DashboardFooter />
      </div>
    </div>
  );

}

export default RcsTemplate;