import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import imagercs from "../../Dashboard/assets/img/rcs.png";
import { HOST_URL, NODE_HOST_URL } from "../api/Api";
import Axios from "axios";
import Spining from "../../UI/Spining/Spining";

class RcsApply extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;


    super();
    this.state = {
      user_id: userId,
      token: userToken,
      brandDescription: "",
      displayName: "",
      brandName: "",
      brandTagline: "",
      color: "",
      region: "",
      personname: "",
      companyEmail: "",
      designation: "",
      phone: "",
      website: "",
      website_label: '',
      primaryphonenumber: "",
      label_primaryphonenumber: "",
      primaryemail: "",
      label_primaryemail: "",
      email: "",
      bannerImage: "",
      logo: "",
      privacyLink: "",
      termAndCond: "",
      testMobile: "",
      errorMessage: "",
      successMessage: false,
      bgColor: "",
      websitescreenshot: [],
      pan: null,
      adhaar: null,
      gst: null,
      schfiles: [],
      loading: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.fetchOnboardingDetails(id)
    }

  }
  SendlargeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png"];
      if (validTypes.includes(file.type)) {
        this.setState({ bannerImage: [file], successMessage: false });
      } else {
        alert("Please select a valid image file (.jpg or .png)");
      }
    }
  };

  fetchOnboardingDetails = async (id) => {
    const payload = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "onboarding_retrieve_dtl",
      id: id
    }
    try {
      const response = await Axios.post(`${NODE_HOST_URL}/rcs`, payload)
      if (response.data.success) {
        const data = response.data.data;
        console.log(data)
        this.setState({
          displayName: data.display_name,
          brandTagline: data.brand_tagline,
          color: data.color,
          region: data.region,
          personname: data.manager_name,
          designation: data.manager_designation,
          phone: data.manager_phone,
          companyEmail: data.manager_company_email,
          primaryphonenumber: data.primary_phone,
          label_primaryphonenumber: data.primary_phone_label,
          website: data.primary_website,
          website_label: data.primary_website_label,
          primaryemail: data.primary_email,
          label_primaryemail: data.primary_email_label,
          privacyLink: data.privacy_policy,
          termAndCond: data.terms_service,
          schfiles: data.screen_shot,
          pan: data.pan,
          adhaar: data.aadhar,
          gst: data.gst,
          logo: data.logo,
          bannerImage: data.large_image,



        })
      }

    } catch (error) {

    }
  }

  updateDetails = async (e) => {
    const { history } = this.props;
    e.preventDefault();
    const { id } = this.props.match.params;
    const payload = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "onboarding_update",
      id: id,
      displayName: this.state.displayName,
      brandTagline: this.state.brandTagline,
      color: this.state.color,
      region: this.state.region,
      personname: this.state.personname,
      designation: this.state.designation,
      companyEmail: this.state.companyEmail,
      manager_phone: this.state.phone,
      primaryphonenumber: this.state.primaryphonenumber,
      label_primaryphonenumber: this.state.label_primaryphonenumber,
      website: this.state.website,
      website_label: this.state.website_label,
      primaryemail: this.state.primaryemail,
      label_primaryemail: this.state.label_primaryemail,
      privacyLink: this.state.privacyLink,
      termAndCond: this.state.termAndCond,

    }
    try {
      const response = await Axios.post(`${NODE_HOST_URL}/rcs`, payload)
      if (response.data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: "Details updated successfully",
        })

        setTimeout(() => {
          history.push("/dashboard/rcs-onboarding-list");
        }, 3000)

      }

    } catch (error) {
      console.log(error)

    }
  }

  SendSmallImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png"];
      if (validTypes.includes(file.type)) {
        this.setState({ logo: [file], successMessage: false });
      } else {
        alert("Please select a valid image file (.jpg or .png)");
      }
    }
  };


  getImageDetails(image) {
    // Check if the image is an array
    if (image?.length && image?.length > 0) {
      // console.error("The provided image is an array. Please provide a single image object.");
      const resultArray = image.map((x) => {
        const imageScreenshotParts = x.name.split(".");
        return {
          image: x,
          imageName: imageScreenshotParts[0],
          imageType: imageScreenshotParts[1],
        };
      });

      return resultArray;
    }

    if (!image || !image.name) {
      console.error("No image provided or image does not have a name.");
      return { imageName: "", imageType: "" };
    }

    const imageParts = image.name.split(".");

    // Ensure that there is a valid extension (i.e., at least one dot in the name)
    if (imageParts.length < 2) {
      console.error("Invalid image name, missing file extension.");
      return { imageName: image.name, imageType: "" };
    }

    const imageName = imageParts.slice(0, imageParts.length - 1).join(".");
    const imageType = imageParts[imageParts.length - 1];

    return { imageName, imageType };
  }



  async uploadScreenShot(files) {
    // Create an array of promises for file uploads
    const uploadPromises = Array.from(files).map((file) => {
      const formData = new FormData();

      formData.append("amdfile", file);
      formData.append("doc_name", file.name);
      formData.append("doc_type", file.type);
      formData.append("user_id", this.state.user_id);
      formData.append("token", this.state.token);
      formData.append("method", "create");

      //   Return the axios POST request promise
      return Axios.post(
        `${HOST_URL}/uploadFileWhatsapp.php`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    });
    try {
      const responses = await Promise.all(uploadPromises);
      const urls = responses.map((response) => response.data.url);

      return urls.length === 1 ? urls[0] : urls;
    } catch (error) {
      // Handle error if any request fails
      console.error("Error uploading files:", error);
      alert("There was an error uploading the files.");
    }
  }

  async RcsSendData(e) {
    e.preventDefault();
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ loading: true })
    for (let i = 0; i < this.state.schfiles.length; i++) {
      const file = this.state.schfiles[i];
      const fileType = file.type;

      if (
        fileType !== "image/jpeg" &&
        fileType !== "image/png" &&
        fileType !== "image/jpg"
      ) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
          errorMessage: "screenshots must be jpeg,jpg,png",
        });
        return;
      }
    }

    const screenshotUrls = this.state.schfiles ? await this.uploadScreenShot(this.state.schfiles) : null;

    const panUrl = this.state.pan ? await this.uploadScreenShot(this.state.pan) : null;

    const bannerUrl = await this.uploadScreenShot(this.state.bannerImage)
    const logoUrl = await this.uploadScreenShot(this.state.logo)
    const aadharUrl = this.state.adhaar ? await this.uploadScreenShot(this.state.adhaar) : null;

    const gstUrl = this.state.gst ? await this.uploadScreenShot(this.state.gst) : null;


    try {

      const dataforonboarding = {
        user_id: this.state.user_id,
        token: this.state.token,
        method: "onboarding_create",
        displayName: this.state.displayName,
        brandTagline: this.state.brandTagline,
        color: this.state.color,
        largeImage: bannerUrl,
        logo: logoUrl,
        region: this.state.region,
        personname: this.state.personname,
        designation: this.state.designation,
        companyEmail: this.state.companyEmail,
        manager_phone: this.state.phone,
        primaryphonenumber: this.state.primaryphonenumber,
        label_primaryphonenumber: this.state.label_primaryphonenumber,
        primaryemail: this.state.primaryemail,
        label_primaryemail: this.state.label_primaryemail,
        website: this.state.website,
        website_label: this.state.website_label,
        privacyLink: this.state.privacyLink,
        termAndCond: this.state.termAndCond,
        websitecreenshot: screenshotUrls,
        pan: panUrl,
        adhaar: aadharUrl,
        gst: gstUrl
      }
      const response = await Axios.post(`${NODE_HOST_URL}/rcs`, dataforonboarding)
      if (response.success) {
        console.log(response)
      }

    } catch (error) {
      console.error("Error during file upload:", error);
    }
    finally {
      this.setState({ loading: false })
    }
  }

  handleFileChange = (e, fieldName) => {
    const files = Array.from(e.target.files);

    if (fieldName === "websitescreenshot") {
      // If it's "websitescreenshot", allow up to 8 screenshots
      if (files.length + this.state.websitescreenshot.length > 8) {
        alert("You can upload a maximum of 8 screenshots.");
        return;
      }

      this.setState({

        schfiles: files,
      });
    } else {
      this.setState({
        [fieldName]: files,
      });
    }

    this.setState({ successMessage: false });
  };

  renderFilePreview = (fieldName) => {
    const { id } = this.props.match.params;
    const { schfiles } = this.state;

    // Special handling for website screenshots
    if (fieldName === "websitescreenshot") {
      if (!schfiles || schfiles.length === 0) {
        return <p>No files to display.</p>;
      }
      // console.log(this.state.schfiles)
      return (
        <div className="d-flex flex-wrap ">
          {schfiles.map((file, index) => (
            <div key={index} className="preview-thumbnail">
              <img
                src={id ? file : URL.createObjectURL(file)}
                alt={`preview ${index}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  margin: ".2rem"

                }}
              />
            </div>
          ))}
        </div>
      );
    }

    // General handling for other fields
    if (this.state[fieldName]) {
      return (
        <div className="preview-thumbnail">
          <img
            src={id ? this.state[fieldName] : URL.createObjectURL(this.state[fieldName][0])}
            alt={fieldName}
            style={{
              width: "100%",
              maxHeight: "150px",
              objectFit: "contain",
              borderRadius: "5px",
            }}
          />
        </div>
      );
    }

    return null;
  };


  render() {
    const { id } = this.props.match.params;
    return (
      <div className="wrapper">
        {this.state.loading ?
          <Spining />
          :
          <>
            <DashboardLeftMenu />
            <div className="main p-3">

              <DashboardHeader />
              <main className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
                {this.state.successMessage ? (
                  <div className={`${this.state.bgColor} w-100`} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{this.state.errorMessage}</div>
                  </div>
                ) : null}

                <div className="d-flex mb-3 align-items-center w-100 justify-content-between">
                  <div>
                    <h3 className="mb-0">
                      <strong> {id ? "Edit" : "Apply"} </strong> RCS{" "}
                    </h3>
                  </div>
                  <div>
                    <Link
                      className="btn btn-primary btn-sm"
                      to="/dashboard/rcs-onboarding-list"
                    >
                      {" "}
                      Back{" "}
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center w-100" style={{ height: "79vh" }}>
                  <div  className="w-100 mr-md-3" style={{height: "100%", overflowY: "auto", overflowX:"hidden" }}>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">
                          Better carrier messaging for everyone
                        </h5>
                      </div>
                      <hr className="m-0" />
                      <div className="card-body">
                        <form id="newtiecktid" onSubmit={id ? (this.updateDetails) : (this.RcsSendData)}>
                          <div className="row">
                            <div className="mb-3 col-md-12">
                              <label className="col-form-label">
                                {" "}
                                Display Name{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                maxLength={40}
                                value={this.state.displayName}
                                placeholder="Full Name Of Brand"
                                onChange={(event) =>
                                  this.setState({
                                    displayName: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                              <span style={{ color: "red", fontSize: "12px" }}>
                                Note: Maximum 40 characters
                              </span>
                            </div>

                            <div className="mb-3 col-md-12">
                              <label className="col-form-label">
                                {" "}
                                Brand Tagline{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                maxLength={100}
                                placeholder="Tagline Of Brand"
                                value={this.state.brandTagline}
                                onChange={(event) =>
                                  this.setState({
                                    brandTagline: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                              <span style={{ color: "red", fontSize: "12px" }}>
                                Note: Not More than 100 characters
                              </span>
                            </div>

                            <div className="mb-3 col-md-6">
                              <label className="col-form-label"> Color </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="color"
                                value={this.state.color}
                                onChange={(event) =>
                                  this.setState({
                                    color: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                placeholder="Enter Color"
                                className="form-control"
                              />
                            </div>

                            <div className="mb-3 col-md-6">
                              <label className="col-form-label"> Regions </label>
                              <span style={{ color: "red" }}>*</span>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    region: e.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                value={this.state.region}
                              >
                                <option value="">Select Region</option>
                                <option value="india">India</option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label className="col-form-label">
                                Banner Image
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                onChange={this.SendlargeImage}
                                className="form-control"
                                type="file"
                                accept="image/jpeg, image/png"
                                required
                                disabled={id}
                              />
                              <small style={{ color: "red" }}>
                                {" "}
                                Image Dimension: 1440 px * 448 px and Format .jpg,
                                .png
                              </small>
                              {this.state.bannerImage && <div className="d-flex flex-wrap justify-contenet-evenly gap-3 mt-3">
                                {this.renderFilePreview("bannerImage")}
                              </div>}
                            </div>

                            <div className="mb-3 col-md-6">
                              <label className="col-form-label">
                                Small Icons
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                onChange={this.SendSmallImage}
                                className="form-control"
                                type="file"
                                accept="image/jpeg, image/png"
                                required
                                disabled={id}
                              />
                              <small style={{ color: "red" }}>
                                {" "}
                                Image Dimension: 224 px * 224 px and Format .jpg,
                                .png
                              </small>
                              {this.state.logo && <div className="d-flex flex-wrap justify-contenet-evenly gap-3 mt-3">
                                {this.renderFilePreview("logo")}
                              </div>}
                            </div>
                          </div>

                          <div className="row mt-3">
                            <hr
                              style={{
                                border: "1px solid #ccc",
                                margin: "20px 0",
                              }}
                            />
                            <h5 className="ml-2">Business Manager Details</h5>
                            <div className="mb-3 col-md-4">
                              <label className="col-form-label">Name</label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                placeholder="Enter Name"
                                value={this.state.personname}
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    personname: e.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="col-form-label">
                                Designation
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                placeholder="Enter Designation"
                                value={this.state.designation}
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    designation: e.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="col-form-label">
                                Phone Number
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="number"
                                placeholder="Enter Phone Number"
                                value={this.state.phone}
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    phone: e.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-3 col-md-12">
                              <label className="col-form-label">
                                Company Email
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="email"
                                placeholder="Enter Comany email"
                                value={this.state.companyEmail}
                                className="form-control"
                                onChange={(e) =>
                                  this.setState({
                                    companyEmail: e.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                              />
                            </div>
                          </div>
                          {/* <div className="row">
                              <div className="mb-3 col-md-12">
                                <label className="col-form-label">
                                  Website
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  type="text"
                                  placeholder="Enter Website Url"
                                  value={this.state.website}
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      website: e.target.value,
                                      successMessage: false,
                                    })
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-12">
                                <label className="col-form-label">
                                  Label for Website
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  type="text"
                                  placeholder="Enter Website Url"
                                  value={this.state.website_label}
                                  className="form-control"
                                  onChange={(e) =>
                                    this.setState({
                                      website_label: e.target.value,
                                      successMessage: false,
                                    })
                                  }
                                  required
                                />
                              </div>
                            </div> */}

                          <div className="row mb-3">
                            <hr
                              style={{
                                border: "1px solid #ccc",
                                margin: "20px 0",
                              }}
                            />
                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Primary Phone Number{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="number"
                                value={this.state.primaryphonenumber}
                                placeholder="Enter Number"
                                onChange={(event) =>
                                  this.setState({
                                    primaryphonenumber: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>

                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Label for Primary Phone Number{" "} <span style={{ color: "red" }}>*</span>
                              </label>

                              <input
                                type="text"
                                value={this.state.label_primaryphonenumber}
                                placeholder="Enter label for Primary Number"
                                onChange={(event) =>
                                  this.setState({
                                    label_primaryphonenumber: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Website{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                placeholder="Website URL"
                                value={this.state.website}
                                onChange={(event) =>
                                  this.setState({
                                    website: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Label for Website{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                value={this.state.website_label}
                                placeholder="Enter label for Primary Website"
                                onChange={(event) =>
                                  this.setState({
                                    website_label: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Primary Email{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="email"
                                placeholder="Email Address"
                                value={this.state.primaryemail}
                                onChange={(event) =>
                                  this.setState({
                                    primaryemail: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Label for Primary Email{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                value={this.state.label_primaryemail}
                                placeholder="Enter label for Primary Email"
                                onChange={(event) =>
                                  this.setState({
                                    label_primaryemail: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="mb-3 col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Privacy Policy Link{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                placeholder="Privacy Policy  URL"
                                value={this.state.privacyLink}
                                onChange={(event) =>
                                  this.setState({
                                    privacyLink: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>

                            <div className="mb-3 col-md-6">
                              <label className="col-form-label">
                                {" "}
                                Term of Service{" "}
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                placeholder="Term And Condition URL"
                                value={this.state.termAndCond}
                                onChange={(event) =>
                                  this.setState({
                                    termAndCond: event.target.value,
                                    successMessage: false,
                                  })
                                }
                                required
                                className="form-control"
                                id="inputPasswordNew2"
                              />
                            </div>
                          </div>

                          {/* <div style={{ background: '#f7f7f7', borderRadius: 10 }} className="row">
                                                    <div className="mb-3 col-md-12">
                                                        <label className="col-form-label">Test Message Mobile Number</label>
                                                        <input className="form-control" onChange={(event) => this.setState({ testMobile: event.target.value })} placeholder="+91XXXXXXX, +91XXXXXXX, +91XXXXXXX" type="text" />
                                                        <small> Mobile Number Max 3.
                                                        </small>
                                                    </div>
                                                </div> */}

                          <div>
                            {/* Divider line */}
                            <hr
                              style={{
                                border: "1px solid #ccc",
                                margin: "20px 0",
                              }}
                            />

                            <div className="row mt-4">
                              <h5 className="ml-2">Upload</h5>
                              <div className="col-12">
                                <div className="card p-4">
                                  <label className="fw-bold">Website Screen Shot <span style={{ color: "red" }}>*</span></label>
                                  <input
                                    type="file"
                                    className="form-control mt-2"
                                    accept=".jpeg, .jpg, .png"
                                    multiple
                                    onChange={(e) => this.handleFileChange(e, "websitescreenshot")}
                                    required
                                    disabled={id}
                                  />
                                  <span className="text-danger d-block mt-2" style={{ fontSize: "12px" }}>
                                    Maximum 8 screenshots to be shared
                                  </span>
                                  <div className="d-flex flex-wrap justify-contenet-evenly gap-3 mt-3">
                                    {this.renderFilePreview("websitescreenshot")}
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="row">
                              {[
                                { label: "Pan", fieldName: "pan" },
                                { label: "Aadhaar", fieldName: "adhaar" },
                                { label: "GST", fieldName: "gst" },
                              ].map(({ label, fieldName }) => (
                                <div className="col-md-4 col-sm-6 mb-3" key={fieldName}>
                                  <div className="card p-3 h-100">
                                    <label className="fw-bold">{label} <span style={{ color: "red" }}>*</span></label>
                                    <input
                                      type="file"
                                      className="form-control mt-2"
                                      accept="image/*"
                                      onChange={(e) => this.handleFileChange(e, fieldName)}
                                      required
                                      disabled={id}
                                    />
                                    <div className="mt-3 text-center">
                                      {this.renderFilePreview(fieldName)}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                          </div>

                          <button
                            type="submit"
                            className="btn w-100 mt-3 float-right btn-primary"
                          >
                            {id ? "Update" : "Submit"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>


                  <div className="d-none d-md-flex w-md-50" style={{width:"35%", height: "100%",}}>
                    <div className="card w-100" style={{height:"100%"}}>
                      <p className="tooglebox">
                        <Link
                          className=""
                          data-toggle="collapse"
                          to="#collapseExample"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          Example of RCS
                        </Link>
                      </p>
                      <div className="p-2">
                        <div className="collapse show" id="collapseExample">
                          <div className="links-set text-center">
                            <img alt="" src={imagercs} className="img-fluid" style={{height:"70vh"}} />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>

              </main>
              {/* <DashboardFooter /> */}
            </div>
          </>}
      </div>
    );
  }
}

export default RcsApply;
