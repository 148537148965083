import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import { HOST_URL, getCampaignSMSTemplateList, translateAPI } from "../../api/Api";
import { userId, userToken } from "../../../Utils/userAuth";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import style from './rcscampaign.module.css';
import DeleteModal from "./DeleteModal";
const RcsFallback = (props) => {
    const { priorityData, setPriorityData, campaignType, filteredHeaders,
        variableMapping, fallback, altPriorityData, setFormStatus,
        altFormStatus, } = props;
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(priorityData.status ? false : true);
    const [deleteMode, setDeleteMode] = useState(false);
    const [SMSmessage, setSMSmessage] = useState('');
    const [is_unicode, setis_unicode] = useState(false);
    const [language, setLanguage] = useState('');
    const [paramFlags, setParamFlags] = useState([]);

    const saveParamFlags = () => {
        const result = priorityData?.paramData?.map((item) =>
            ({ [item]: false })
        )
        return result
    }

    useEffect(() => {
        const updatedFlags = saveParamFlags();
        setParamFlags(updatedFlags);

    }, [priorityData.paramData]);


    useEffect(() => {
        getDLTSMSTemplateList();
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditMode(false);
        setPriorityData({ status: true })

    }

    const handleDeleteClick = () => {
        setDeleteMode(true)

    }

    const handleDeleteConfirm = () => {
        setFormStatus(false);
        setPriorityData({
            templateId: '',
            smsTemplateList: [],
            smsTemplateDetails: null,
            SMSmessage: '',
            templateName: '',
            templatePreView: null,
            paramData: [],
            bodyVariables: [],
            bodyValue: [],
            previewMessage: '',
            channel: '',
            condition: [],
            paramMapping: null,
            status: false,
            paramFlags: []
        })
    }

    const handleCancel = () => {
        if (priorityData.status) {
            setIsEditMode(false);
        } else {
            handleDeleteConfirm();
        }


    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            cursor: "pointer",
        }),
    };

    const getDLTSMSTemplateList = async () => {
        try {
            const response = await fetch(`${HOST_URL}/sms_template.php?user_id=${userId}&method=retrieve&id=null&token=${userToken}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result) {
                const updatedTemplateList = result.data || [];
                setPriorityData({
                    smsTemplateList: updatedTemplateList,
                });


            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    const mapConditionToOptions = (conditionArray) => {
        return conditionArray.map((condition) => ({
            value: condition,
            label: condition.charAt(0).toUpperCase() + condition.slice(1),
        }));
    };

    const handleSelect = (selectedValues) => {
        const conditions = selectedValues.map((selected) => selected.value);
        setPriorityData({ condition: conditions });
    };

    const selectSMSTemplate = async (value) => {

        setPriorityData({
            previewMessage: '',
            templateId: value,
            paramData: [],
            paramFlags: [],
            bodyValue:[],
        });
        try {
            setLoading(true);
            const res = await getCampaignSMSTemplateList("retrieveid", value);
            if (res.data.success === true) {
                setPriorityData({
                    smsTemplateDetails: res.data.data,
                    SMSmessage: res.data.data[0].content,
                    templateName: res.data.data[0].template_name,
                    templatePreView: res.data.data[0],
                });
                extractData(res.data.data[0].content);
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.error("Error fetching SMS template details:", error);
        }
        finally {
            setLoading(false);
        }


    };

    const extractData = (data) => {
        const pattern = /\{#(.*?)#\}/g;
        const matches = [];
        const fullMatches = [];
        let match;
        while ((match = pattern.exec(data)) !== null) {
            matches.push(match[1]);
            fullMatches.push(match[0]);
        }

        setPriorityData({
            paramData: matches,
            bodyVariables: fullMatches
        });


    }

    const preViewSMSHandler = async (e) => {
        const inputValue = e.target.value;
        let APIResponse = "";
        let lastWord = e.target.value.split(" ");
        if (is_unicode) {
            if (e.key === " " && inputValue.trim() !== "") {
                try {
                    const result = await translateAPI(lastWord[lastWord.length - 1], language);
                    APIResponse = result.data + " ";
                } catch (error) {
                    console.error("Error translating:", error);
                }
            }
        }
        if (APIResponse === "") {
            setSMSmessage(e.target.value);
        } else {
            if (inputValue !== null) {
                var lastIndex = inputValue.lastIndexOf(lastWord[lastWord.length - 1]);
                let originalMessage = inputValue.substring(0, lastIndex);
                setSMSmessage(`${originalMessage}${APIResponse}`);
            }
        }
    };

    const replaceBodyVal = (index, value) => {


        const updatedValues = [...priorityData.bodyValue];
        updatedValues[index] = value;
        const bodydata = formatMessage(
            priorityData.SMSmessage,
            priorityData.bodyVariables,
            updatedValues
        );
        const mergedMessage = bodydata.join(" ");


        setPriorityData({ previewMessage: mergedMessage, bodyValue: updatedValues });


    };

    const formatMessage = (msg, placeholders, inputValues) => {
        const parts = msg.split(/(\{#.*?#\})|\s+/).filter(Boolean);
        return parts.map((part, index) => {
            const placeholderIndex = placeholders.indexOf(part);
            if (placeholderIndex !== -1) {
                return (

                    inputValues[placeholderIndex] || part

                );
            }
            return part;
        });
    };


    const handleParamChange = (e, index) => {
        const selectedHeader = e.target.value; // The selected header from the dropdown

        const matchingKey = Object.keys(variableMapping).find(
            (key) => variableMapping[key] === selectedHeader
        );

        if (matchingKey) {
            // Create a copy of the bodyValue
            const updatedValues = [...priorityData.bodyValue];

            // Update only the value at the specific index
            updatedValues[index] = matchingKey;

            // Set the updated values to the state
            setPriorityData({
                bodyValue: updatedValues,
            });
        }
    };



    return (
        <div className="card p-3">
            <DeleteModal
                show={deleteMode}
                onClose={() => setDeleteMode(false)}
                onDelete={handleDeleteConfirm}
            />
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                <h3 className="mb-0">Fallback {fallback}</h3>
                {!isEditMode && <div className="d-flex justify-content-between align-items-center" style={{ width: "10%" }}>
                    <MdEdit style={{ cursor: "pointer" }} size={20} onClick={() => setIsEditMode(true)} />
                    {!altFormStatus && <MdDelete style={{ cursor: "pointer" }} size={20} onClick={handleDeleteClick} />}
                </div>}

            </div>
            {isEditMode ? <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="channel" className="form-label">Channel</label>
                    <select
                        onChange={(event) => setPriorityData({ channel: event.target.value })}
                        className="form-control"
                        value={priorityData.channel}
                        required
                    >
                        <option value="">Select Channel</option>
                        {/* {altPriorityData.channel === "whatsapp" || props.platform === "whatsapp" ? null : <option value={"whatsapp"}>Whatsapp</option>} */}
                        {altPriorityData.channel === "sms" || props.platform === "sms" ? null : <option value={"sms"}>Sms</option>}

                    </select>
                </div>
                {priorityData.channel && <><div className="mb-3">
                    <label htmlFor="condition" className="form-label">Condition</label>
                    <Select
                        options={[
                            // { value: "delivered", label: "Delivered" },
                            { value: "failed", label: "Failed" },
                            { value: "sent", label: "Sent" },
                            // { value: "read", label: "Read" },
                        ]}
                        closeMenuOnSelect={false}
                        value={mapConditionToOptions(priorityData.condition)}
                        hideSelectedOptions={false}
                        isMulti
                        styles={customStyles}
                        classNamePrefix="select"
                        placeholder="Select Condition"
                        id="condition"
                        onChange={handleSelect}
                    />
                </div>
                    <div className="mb-3">
                        <div className="input-group drop-down-icons mb-2 mr-sm-2">
                            <div className="input-group-text w-25">
                                <FontAwesomeIcon icon={faAddressBook} className="mr-1" /> Template
                            </div>
                            <select
                                id="template"
                                onChange={(e) => selectSMSTemplate(e.target.value)}
                                className="form-control"
                                required
                                value={priorityData.templateId}
                            >
                                <option value="">Select Template</option>
                                {priorityData.smsTemplateList.map((item, index) => (
                                    <option key={index} value={item.id} data-template-name={item.template_name}>
                                        {item.template_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div></>}
                {priorityData.smsTemplateDetails &&
                    <>
                        <div className="mb-3">

                            <div className="input-group">
                                <span className="input-group-text w-25">
                                    <FontAwesomeIcon icon={faAddressBook} className="me-2" /> Sender ID
                                </span>
                                <input
                                    type="text"
                                    id="senderId"
                                    placeholder="Sender ID"
                                    className="form-control"
                                    disabled
                                    value={priorityData?.smsTemplateDetails[0]?.senderid}
                                />
                            </div>
                        </div>
                        {priorityData.paramData.map((x, index) => (
                            <div className="input-group mb-2 mr-sm-2" key={index}>
                                <div className="input-group-text w-25">
                                    {x}
                                </div>

                                {campaignType === "1" && (
                                    <input
                                        type="text"
                                        placeholder="Enter variable value"
                                        onChange={(e) => replaceBodyVal(index, e.target.value)}
                                        className="form-control"
                                        required
                                    />
                                )}
                                {campaignType === "3" && (
                                    <>
                                        <select
                                            className="form-control"
                                            value={paramFlags?.[index]?.[x] ? "Dynamic" : "Static"}
                                            onChange={(e) => {
                                                const isDynamic = e.target.value === "Dynamic";

                                                const updatedFlags = paramFlags.map((flag, idx) =>
                                                    idx === index ? { [x]: isDynamic } : flag
                                                )

                                                setParamFlags(updatedFlags)
                                                setPriorityData({ paramFlags: updatedFlags })
                                            }}
                                        >
                                            <option value="Static">Static</option>
                                            <option value="Dynamic">Dynamic</option>

                                        </select>
                                        {paramFlags[index]?.[x] ? <select
                                            name={x}
                                            onChange={(e) => handleParamChange(e, index)}
                                            className="form-control"
                                            
                                        >
                                            <option value="">Select Column</option>
                                            {filteredHeaders.map((item, idx) => (
                                                <option key={idx} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select> :
                                            <input
                                                type="text"
                                                placeholder="Enter variable value"
                                                onChange={(e) => replaceBodyVal(index, e.target.value)}
                                                className="form-control"
                                                required
                                                value={priorityData.bodyValue[index]}
                                            />}
                                    </>
                                )}

                            </div>
                        ))}

                        <textarea
                            onChange={preViewSMSHandler}
                            rows={5}
                            disabled={is_unicode && language === "" ? true : false}
                            value={priorityData.previewMessage || priorityData.SMSmessage}
                            className="form-control mt-3"
                            onKeyDown={preViewSMSHandler}
                        ></textarea>
                    </>
                }

                <div className="d-flex justify-content-center align-items-center w-100 mt-2">
                    <button className="btn btn-sm w-50" style={{ backgroundColor: "gray", color: "white" }}
                        onClick={handleCancel} disabled={altFormStatus}>Cancel</button>
                    <button type="submit" className="btn btn-sm w-50" style={{ backgroundColor: "blue", color: "white" }}>Save</button>
                </div>

            </form >
                :
                <div>
                    <div className="mb-1 d-flex">
                        <label className="col-4 fw-bold text-dark">Condition:</label>
                        <div className="col-8">
                            {priorityData.condition.length > 0 ? (
                                <div className={style.conditionPills}>
                                    {priorityData.condition.map((cond, index) => (
                                        <span key={index} className={style.pill}>
                                            {cond}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <span>No conditions selected</span>
                            )}
                        </div>
                    </div>


                    <div className="mb-1 d-flex">
                        <label className="col-4 fw-bold text-dark">Channel:</label>
                        <div className="col-8" style={{ fontSize: ".9rem" }}>{priorityData.channel}</div>
                    </div>

                    <div className="mb-1 d-flex">
                        <label className="col-4 fw-bold text-dark">Template Name:</label>
                        <div className="col-8" style={{ fontSize: ".9rem" }}>{priorityData.templateName}</div>
                    </div>

                    <div className="mb-1 d-flex">
                        <label className="col-4 fw-bold text-dark">Message:</label>
                        <div className="col-8" style={{ fontSize: ".9rem" }}>{priorityData.previewMessage || priorityData.SMSmessage}</div>
                    </div>
                </div>
            }
        </div >
    )
}

export default RcsFallback;