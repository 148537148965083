import React, { useState } from "react";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DashboardLeftMenu from "../DashboardLeftMenu";
import DashboardHeader from "../../Header/DashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { NODE_HOST_URL } from "../api/Api";
import Axios from "../../../axios/Axios";
import Spining from "../../UI/Spining/Spining";

const QuickReply = () => {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const date = new Date();

    const [error, setError] = useState({
        errorStatus: false,
        errorMessage: "",
        bgColor: "",
    });
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);
    const [channel, setChannel] = useState("");
    const [urlDataReport, setUrlDataReport] = useState([]);
    const [submitVia, setSubmitVia] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const addDays = (date, days) => {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + days);
        return newDate;
    };

    const onChange = (e) => {
        setChannel(e.target.value);
        setError({ errorStatus: false });
    };

    const isValid = () => {
        if (channel === undefined || channel === "") {
            setError({
                errorStatus: true,
                errorMessage: "Please Select Channel",
                bgColor: "alert alert-danger",
            });
            return false;
        }
        if (submitVia === undefined || submitVia === "") {
            setError({
                errorStatus: true,
                errorMessage: "Please Select Submit Via",
                bgColor: "alert alert-danger",
            });
            return false;
        } else {
            return true;
        }
    };


    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const urlDetails = async () => {
        if (isValid()) {
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);

            const paramData = {
                user_id: userId,
                token: userToken,
                fromdate: formattedStartDate,
                todate: formattedEndDate,
                submit_via: submitVia === "api" ? "API" : "PANNEL",
                method: submitVia === "api" ? "api_quickreply_summary" : "camp_quickreply_summary"
            };

            try {
                let { data } = await Axios.post(`${NODE_HOST_URL}/click_reply`, paramData);
                if (data.success) {
                    console.log(data);
                    setUrlDataReport(data.data);
                }
            } catch (error) {
                console.log(error);
            }
            // Code to fetch URL data report (commented out)
        }
    };


    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const sendData = (short_url) => {
        const url = `/dashboard/fixed-url-report/${channel}/${currentDate(startDate)}/${currentDate(endDate)}/${encodeURIComponent(short_url)}`;
        window.open(url, "_blank");
    };

    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="card">
                        {isLoading && <Spining />}
                        <div className="card-body">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        {error.errorStatus && (
                                            <div className={error.bgColor} role="alert">
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div>{error.errorMessage}</div>
                                            </div>
                                        )}
                                        <h5 className="card-title">Quick Reply Report</h5>
                                        <form className="row mb-4">
                                            <div className="col-md-3">
                                                <label>Select Channel</label>
                                                <select
                                                    className="form-control"
                                                    name="channel"
                                                    value={channel}
                                                    onChange={onChange}
                                                >
                                                    <option value="">Select Channel</option>
                                                    <option value="whatsapp">Whatsapp</option>
                                                    {/* <option value="sms">Sms</option>
                            <option value="rcs">Rcs</option> */}
                                                </select>
                                            </div>

                                            <div className="col-md-3">
                                                <label>From Date</label>
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={startDate}
                                                    className="form-control"
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>To Date</label>
                                                <ReactDatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                // startDate={startDate}
                                                // minDate={startDate}
                                                // maxDate={addDays(startDate, 30)}
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <label>Submit Via</label>
                                                <select
                                                    className="form-control"
                                                    name="submitVia"
                                                    value={submitVia}
                                                    onChange={(e) => setSubmitVia(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="pannel">Pannel</option>
                                                    <option value="api">API</option>
                                                </select>
                                            </div>
                                        </form>

                                        <div className="d-flex justify-content-center mb-3">
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={urlDetails}
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {urlDataReport.length > 0 ? (
                                        <>
                                            {/* <div className="row">
                                                <div className="col-6"></div>
                                                <div className="col-6">
                                                    <a
                                                        type="button"
                                                        className="btn btn-warning float-right mb-2"
                                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "45px", color: "white" }}
                                                        href={submitVia === 'api' ? `${NODE_HOST_URL}/clickreport_download_camp_csv?user_id=${userId}&token=${userToken}&fromdate=${startDate}&todate=${endDate}&submit_via=api&method=clickreport_download_api_csv` : `${NODE_HOST_URL}/clickreport_download_camp_csv?user_id=${userId}&token=${userToken}&fromdate=${startDate}&todate=${endDate}&submit_via=pannel&method=clickreport_download_camp_csv`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Download
                                                    </a>

                                                </div>
                                            </div> */}
                                            <div className="table-responsive">
                                                <table className="table table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th><span>Total Count</span></th>
                                                            <th><span>Button Name</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {urlDataReport.map((item, i) => (
                                                            <tr key={i}>
                                                                <td>{item.total_count}</td>
                                                                <td>{item.btn_name}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>  </>
                                    ) :
                                        //   urlDataReport.length > 0 && submitVia === "api" ? (
                                        //     <div className="table-responsive">
                                        //       <table className="table table-striped table-sm">
                                        //         <thead>
                                        //           <tr>
                                        //             <th>Date</th>
                                        //             <th>Total Count</th>
                                        //           </tr>
                                        //         </thead>
                                        //         <tbody>
                                        //           {urlDataReport.map((item, i) => (
                                        //             <tr key={i}>
                                        //               <td>{item.date}</td>
                                        //               <td>{item.total_count}</td>
                                        //             </tr>
                                        //           ))}
                                        //         </tbody>
                                        //       </table>
                                        //     </div>) : 
                                        (
                                            <div className="text-center">
                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                <p>No Entity ID Yet</p>
                                            </div>
                                        )
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default QuickReply;
