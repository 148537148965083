import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsFillChatDotsFill } from "react-icons/bs";
import {
  faAlignJustify,
  faUser,
  faEnvelope,
  faCode,
  faAtom,
  faDownload,
  faIcons,
  faCommentDots,
  faBriefcase,
  faSortNumericUpAlt,
  faPaperPlane,
  faAddressBook,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import logoUrl from "../Dashboard/assets/img/logo.png";
import DashboardLogo from "../Dashboard/assets/img/DashboardLogo.png";
import smsIcon from "../Dashboard/assets/img/chat-bubbles-with-ellipsis.png";
import fallbackIcon from "../Dashboard/assets/img/awesome-scroll.png";
import analyticsIcon from "../Dashboard/assets/img/analytics.png";
import phoneIcon from "../Dashboard/assets/img/phone1.png";
import microphoneIcon from "../Dashboard/assets/img/microphone.png";
import whatsapp from "../Dashboard/assets/img/whatsapp.png";
import feedbackImg from "../Dashboard/assets/img/side-bar.png";
import { WhstappAccountList } from "../Dashboard/api/Api";
import { checkUserUrl } from "../Utils/Utils";
import ChooseCampaign from "./campaign/ChooseCampaign";
import { MdMms } from "react-icons/md";
 import  RcsBlackImage  from "../Dashboard/assets/img/rcsBlackImage-removebg-preview.png";

const DashboardLeftMenu = () => {
  const [menuId, setMenuId] = useState("sidebar");
  const [pName, setPName] = useState("");
  const [menuActive, setMenuActive] = useState(false);

  const lStorage = JSON.parse(localStorage.getItem("login"));
  
  const sms_menu = lStorage?.user?.sms_menu === 0 ? false : true;
  const voice_menu = lStorage?.user?.voice_menu === 0 ? false : true;
  const email_menu = lStorage?.user?.email_menu === 0 ? false : true;
  const whatsapp_menu = lStorage?.user?.whatsapp_menu === 0 ? false : true;
  const chat_menu = lStorage?.user?.chat_menu === 0 ? false : true;
  const camp_menu = lStorage?.user?.camp_menu === 0 ? false : true;
  const fb_menu = lStorage?.user?.fb_menu === 0 ? false : true;
  const rich_menu = lStorage?.user?.rich_menu === 0 ? false : true;
  const url = lStorage?.url;
  const send_wp_single = lStorage?.user?.send_wp_single

  useEffect(() => {
    WhstappAccountList().then((res) => {
      // localStorage.setItem("wp-cmpy-name", res.data.data[0].comp_name);
      // localStorage.setItem("wp-cmpy-img", res.data.data[0].image_url);
      // localStorage.setItem(
      //   "wp-cmpy-brand_number",
      //   res.data.data[0].brand_number
      // );

      if (res.data.data === undefined) {
        setMenuActive(false);
      } else {
        setMenuActive(true);
      }
    });
  }, []);

  const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;
    //console.log('location',location);
    setPName(pathname);
  };

  const menuHideShow = () => {
    if (menuId === "sidebar") {
      setMenuId("sidebar collapsed");
    } else {
      setMenuId("sidebar");
    }
  };

  return (
    <>
      <nav id="sidebar" className={menuId}>
        <div className="sidebar-content js-simplebar"
         style={{
          background: "#f8f8f8",
          // Full height of the viewport
          position: "sticky", // Fix sidebar in place
          top: 0, // Stick the sidebar to the top
          left: 0,
          height: "100vh",     // Full height of the viewport
          margin:"0px",
          
         

          // Stick the sidebar to the left
           // Prevent the sidebar itself from scrolling
          zIndex: 1000,
          // Ensure it's on top of other elements
        }}>
          <div
           style={{
            position: "sticky", // Keep the logo and dashboard fixed at the top of the sidebar
            top: 0,
            zIndex: 1000,
            
            background: "#f8f8f8", // Same background as the sidebar
          // Optional: Add space below the fixed section
          }}>
          <Link className="sidebar-brand" to="/dashboard">
            {checkUserUrl(url) === true ? (
              <img className="Logo" alt="logo" src={logoUrl} />
            ) : (
              <img className="Logo" alt="logo" src={DashboardLogo} />
            )}
          </Link>

          <span onClick={menuHideShow} className="sidebar-toggle d-flex">
            <i className="hamburger align-self-center"></i>
          </span>

          <ul className="sidebar-nav">
            <li className="sidebar-header"></li>

            <li
              className={
                pName === "/dashboard" ? "sidebar-item active" : "sidebar-item"
              }
            >
              <NavLink
                className="sidebar-link"
                isActive={checkActive}
                to="/dashboard"
              >
                <FontAwesomeIcon icon={faAlignJustify} />{" "}
                <span className="align-middle">Dashboard</span>
              </NavLink>
            </li>
            </ul>
            </div>
            <div
             style={{
              overflowY: "auto", // Make this section scrollable
              flexGrow: 1, // Allow the user management section to take the remaining height
              height: "100vh",
               // Ensure the scrollable content doesn't exceed the sidebar height (adjust padding and logo height)
            }}>
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {checkUserUrl(url) && (
              <li style={{ background: "#10222dba" }} className="sidebar-link">
                <NavLink
                  style={{ color: "#61dafb", padding: "0px 0px" }}
                  to="/dashboard/api-docs"
                >
                  <FontAwesomeIcon style={{ color: "#61dafb" }} icon={faCode} />{" "}
                  <span className="align-middle">API Docs</span>
                </NavLink>
              </li>
            )}

            {sms_menu && (
              <li
                className={
                  pName === "/dashboard/send-sms" ||
                  pName === "/dashboard/dlt-template-list" ||
                  pName === "/dashboard/add-entity-id" ||
                  pName === "/dashboard/sms-template-list" ||
                  pName === "/dashboard/sms-report" ||
                  pName === "/dashboard/sms-api-docs" ||
                  pName === "/dashboard/sms-price-list" ||
                  pName === "/dashboard/optout" ||
                  pName.includes(
                    "/sms-template/edit/" || "/dashboard/template-management"
                  ) ||
                  pName === "/dashboard/sms-template"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#sms"
                  data-target="#sms"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <img className="dashboardIcons" alt="icons" src={smsIcon} />{" "}
                  <span className="align-middle"> SMS </span>
                </a>
                <ul
                  id="sms"
                  className={
                    pName === "/dashboard/send-sms" ||
                    pName.includes("/sms-template/edit/") ||
                    pName === "/dashboard/dlt-template-list" ||
                    pName === "/dashboard/add-entity-id" ||
                    pName === "/dashboard/sms-template-list" ||
                    pName === "/dashboard/sms-api-docs" ||
                    pName === "/dashboard/sms-report" ||
                    pName === "/dashboard/sms-price-list" ||
                    pName === "/dashboard/optout" ||
                    pName === "/dashboard/sms-template"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/send-sms"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/send-sms">
                      Send SMS{" "}
                    </Link>
                  </li>

                  <li
                    className={
                      pName === "/dashboard/sms-template-list" ||
                      pName.includes("/sms-template/edit/") ||
                      pName === "/dashboard/sms-template"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <a
                      className="sidebar-link"
                      href="/dashboard/sms-template-list"
                    >
                      {" "}
                      Template
                    </a>
                  </li>

                  <li
                    className={
                      pName === "/dashboard/sms-api-docs"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/sms-api-docs">
                      API Docs
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/sms-price-list"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/sms-price-list"
                    >
                      Pricing{" "}
                    </Link>
                  </li>

                  <li>
                    <a
                      href="#dltsetup"
                      data-target="#dltsetup"
                      data-toggle="collapse"
                      className="sidebar-link collapsed"
                    >
                      <span className="align-middle"> DLT Setup </span>
                    </a>
                    <ul
                      id="dltsetup"
                      className={
                        pName === "/dashboard/add-entity-id" ||
                        pName === "/dashboard/dlt-template-list"
                          ? "sidebar-dropdown list-unstyled collapse show"
                          : "sidebar-dropdown list-unstyled collapse"
                      }
                    >
                      <li
                        className={
                          pName === "/dashboard/add-entity-id"
                            ? "sidebar-item active"
                            : "sidebar-item"
                        }
                      >
                        <Link
                          className="sidebar-link"
                          to="/dashboard/add-entity-id"
                        >
                          Entity Management
                        </Link>
                      </li>

                      <li
                        className={
                          pName === "/dashboard/dlt-template-list" ||
                          pName === "/dashboard/sms-template"
                            ? "sidebar-item active"
                            : "sidebar-item"
                        }
                      >
                        <Link
                          className="sidebar-link"
                          to="/dashboard/dlt-template-list"
                        >
                          {" "}
                          Template Management{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/sms-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/sms-report">
                      Report
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/optout"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/optout">
                      Optout
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {voice_menu && (
              <li
                className={
                  pName === "/dashboard/send-voice" ||
                  pName === "/dashboard/voice-template-list" ||
                  pName === "/dashboard/voice-template-list" ||
                  pName === "/dashboard/voice-report" ||
                  pName === "/dashboard/flash-call" ||
                  pName === "/dashboard/voice-template" ||
                  pName === "/dashboard/caller-id" ||
                  pName === "/dashboard/voice-api-docs"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#Voice"
                  data-target="#Voice"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <img
                    className="dashboardIcons"
                    alt="icons"
                    src={microphoneIcon}
                  />{" "}
                  <span className="align-middle"> Voice </span>
                </a>
                <ul
                  id="Voice"
                  className={
                    pName === "/dashboard/send-voice" ||
                    pName === "/dashboard/voice-price-list" ||
                    pName === "/dashboard/caller-id" ||
                    pName === "/dashboard/voice-template-list" ||
                    pName === "/dashboard/voice-report" ||
                    pName === "/dashboard/flash-call" ||
                    pName === "/dashboard/voice-template" ||
                    pName === "/dashboard/voice-api-docs"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/send-voice"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/send-voice">
                      Send Voice{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/voice-template-list" ||
                      pName === "/dashboard/voice-template"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/voice-template-list"
                    >
                      {" "}
                      Template
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/voice-api-docs"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/voice-api-docs"
                    >
                      {" "}
                      Voice API{" "}
                    </Link>
                  </li>

                  {/* <li className={pName === '/dashboard/caller-id' ? 'sidebar-item active' : 'sidebar-item'}><Link className="sidebar-link" to="/dashboard/caller-id"> Caller ID </Link></li> */}

                  <li
                    className={
                      pName === "/dashboard/voice-price-list"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/voice-price-list"
                    >
                      {" "}
                      Pricing{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/voice-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/voice-report">
                      Report
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/flash-call"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/flash-call">
                      Flash Call
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {email_menu && (
              <li
                className={
                  pName === "/dashboard/send-email" ||
                  pName === "/dashboard/email-template-list" ||
                  pName === "/dashboard/email-price" ||
                  pName === "/dashboard/email-api-docs" ||
                  pName === "/dashboard/aws-email-verify" ||
                  pName === "/dashboard/aws-setup" ||
                  pName === "/dashboard/email-template" ||
                  pName === "/dashboard/email-report"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#Email"
                  data-target="#Email"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  <span className="align-middle"> Email </span>
                </a>
                <ul
                  id="Email"
                  className={
                    pName === "/dashboard/send-email" ||
                    pName === "/dashboard/email-validator" ||
                    pName === "/dashboard/email-template-list" ||
                    pName === "/dashboard/email-validator" ||
                    pName === "/dashboard/email-api-docs" ||
                    pName === "/dashboard/email-report" ||
                    pName === "/dashboard/aws-email-verify" ||
                    pName === "/dashboard/aws-setup" ||
                    pName === "/dashboard/email-price" ||
                    pName === "/dashboard/email-campaning-list" ||
                    pName === "/dashboard/email-campaning" ||
                    pName === "/dashboard/email-template"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/send-email"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/send-email">
                      Send Email{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/email-template-list" ||
                      pName === "/dashboard/email-template"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/email-template-list"
                    >
                      {" "}
                      Template
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/email-api-docs"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/email-api-docs"
                    >
                      {" "}
                      API Docs{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/email-validator"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/email-validator"
                    >
                      {" "}
                      Email Validator{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/email-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/email-report">
                      Report
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/aws-setup"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/aws-setup">
                      AWS Setting
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/aws-email-verify"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/aws-email-verify"
                    >
                      Sender Email
                    </Link>
                  </li>

                  <li>
                    <a
                      href="#emailcampaign"
                      data-target="#emailcampaign"
                      data-toggle="collapse"
                      className="sidebar-link collapsed"
                    >
                      <span className="align-middle"> Email Campaign </span>
                    </a>
                  </li>
                </ul>
              </li>
            )}

            {whatsapp_menu && (
              <li
                className={
                  pName === "/dashboard/whatsapp" ||

                  pName === "/dashboard/create-whatsapp" ||
                  pName === "/dashboard/whatsapp-onboarding" ||
                  pName === "/dashboard/whatsapp-report" ||
                  pName === "/dashboard/create-whatsapp-template" ||
                  pName === "/dashboard/whatsapp-api-docs" ||
                  pName === "/dashboard/whatsapp-template-list" ||
                  pName === "/dashboard/whatsapp-gallery" ||
                  pName === "/dashboard/whatsapp-optin-otpout" ||
                  pName === "/dashboard/send-whatsapp" ||
                  pName === "/dashboard/whatsapp-conversation" ||
                  pName === "/dashboard/whatsapp-widget"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#whatsapp"
                  data-target="#whatsapp"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <img className="dashboardIcons" alt="icons" src={whatsapp} />{" "}
                  <span className="align-middle"> WhatsApp </span>
                </a>
                <ul
                  id="whatsapp"
                  className={
                   
                     pName === "/dashboard/send-whatsapp-single"||
                    pName === "/dashboard/whatsapp-onboarding" ||
                    pName === "/dashboard/whatsapp-template-list" ||
                    pName === "/dashboard/whatsapp-report" ||
                    pName === "/dashboard/whatsapp-api-docs" ||
                    pName === "/dashboard/whatsapp-gallery" ||
                    pName === "/dashboard/send-whatsapp" ||
                    pName === "/dashboard/whatsapp-conversation" ||
                    pName === "/dashboard/create-whatsapp" ||
                    pName === "/dashboard/whatsapp-optin-otpout" ||
                    pName === "/dashboard/agent-list" ||
                    pName === "/dashboard/create-whatsapp-template" ||
                    pName === "/dashboard/whatsapp-widget"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/send-whatsapp-single" ||
                      "/dashboard/whatsapp" ||
                      pName === "/dashboard/whatsapp-onboarding"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/send-whatsapp-single"
                    >
                      Send WhatsAPP{" "}
                    </Link>
                  </li>
                  {/* {send_wp_single === 1 && (
                    <li
                      className={
                        pName === "/dashboard/send-whatsapp-single"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/dashboard/send-whatsapp-single"
                      >
                        Send WhatsAPP Single{" "}
                      </Link>
                    </li>
                  )} */}
                  <li
                    className={
                      pName === "/dashboard/whatsapp" ||
                      pName === "/dashboard/whatsapp-onboarding"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/whatsapp">
                      {" "}
                      WhatsApp onboarding{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-template-list" ||
                      pName === "/dashboard/create-whatsapp-template"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-template-list"
                    >
                      {" "}
                      WhatsApp Template{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-report"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-report"
                    >
                      {" "}
                      WhatsApp Report{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-gallery"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-gallery"
                    >
                      Media Gallery
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-optin-otpout"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-optin-otpout"
                    >
                      Optin / Optout
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-conversation"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-conversation"
                    >
                      Whatsapp Conversation
                    </Link>
                  </li>

                  <li
                    className={
                      pName === "/dashboard/whatsapp-api-docs"
                        ? "sidebar-item active"
                        : menuActive === true
                        ? "sidebar-item"
                        : "sidebar-item link-disabled"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/whatsapp-api-docs"
                    >
                      Whatsapp API Docs
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/whatsapp-widget"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link collapsed"
                      to="/dashboard/whatsapp-widget"
                    >
                      whatsapp widget
                    </Link>
                  </li>
                  {/* <li>
									<a href="#agent" data-target="#agent" data-toggle="collapse" className="sidebar-link collapsed">
										<span className="align-middle "> Agent Settings  </span>
									</a>
									<ul id="agent" className={pName === '/dashboard/add-wp-agent'  || pName === '/dashboard/wp-agent-list' || pName === '/dashboard/agent-setting' ? 'sidebar-dropdown list-unstyled collapse show' : 'sidebar-dropdown list-unstyled collapse'} >

										<li className={pName === '/dashboard/add-wp-agent' ? 'sidebar-item active' : 'sidebar-item'}><Link className="sidebar-link" to="/dashboard/add-wp-agent">Create Agent</Link></li>

										<li className={pName === '/dashboard/wp-agent-list' ? 'sidebar-item active' : 'sidebar-item'}><Link className="sidebar-link" to="/dashboard/wp-agent-list">Agent List</Link></li>

										<li className={pName === '/dashboard/agent-setting' || pName === '/dashboard/sms-template' ? 'sidebar-item active' : 'sidebar-item'}><Link className="sidebar-link" to="/dashboard/agent-setting">  Hunt Strategy </Link></li>

									</ul>
								</li> */}
                </ul>
              </li>
            )}

            {/* {rich_menu && (
              <li
                className={
                  pName === "/dashboard/test-rich-media" ||
                    pName === "/dashboard/rich-media-template" ||
                    pName === "/dashboard/rich-media-report" ||
                    pName === "/dashboard/create-rich-media-template"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#rcs"
                  data-target="#richMedia"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <FontAwesomeIcon icon={faIcons} />{" "}
                  <span className="align-middle"> Rich Media </span>
                </a>
                <ul
                  id="richMedia"
                  className={
                    pName === "/dashboard/test-rich-media" ||
                      pName === "/dashboard/rich-media-template" ||
                      pName === "/dashboard/rich-media-report" ||
                      pName === "/dashboard/create-rich-media-template"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/test-rich-media"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/test-rich-media"
                    >
                      {" "}
                      Test Rich Media{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/rich-media-template" ||
                        pName === "/dashboard/create-rich-media-template"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/rich-media-template"
                    >
                      Rich Media Template
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/rich-media-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/rich-media-report"
                    >
                      Rich Media Report
                    </Link>
                  </li>
                </ul>
              </li>
            )} */}

            <li
              className={
                pName === "/dashboard/create-rcs-template" ||
                pName === "/dashboard/webhook-report" ||
                pName === "/dashboard/rcs-template-list" ||
                pName === "/dashboard/rcs-setting" ||
                pName === "/dashboard/apply-rcs" ||
                pName === "/dashboard/rcs-pricing" ||
                pName === "/dashboard/rcs-agent-list" ||
                pName === "/dashboard/rcs-reports"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#rcs"
                data-target="#rcs"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <img alt="RcsImage" className="text-primary mr-2 rcs-logo-image" style={{ fontSize: '2em', color:"black" }} src={RcsBlackImage} />{" "}
                <span className="align-middle"> RCS </span>
              </a>
              <ul
                id="rcs"
                className={
                  pName === "/dashboard/rcs-list" ||
                  pName === "/dashboard/create-rcs-template" ||
                  pName === "/dashboard/rcs-template-list" ||
                  pName === "/dashboard/apply-rcs" ||
                  pName === "/dashboard//rcs-setting" ||
                  pName === "/dashboard/rcs-pricing" ||
                  pName === "/dashboard/rcs-agent-list" ||
                  pName === "/dashboard/rcs-onboarding-list" ||
                  pName === "/dashboard/rcs-reports"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                 <li
                  className={
                    pName === "/dashboard/create-rcs-template" ||
                    pName === "/dashboard/rcs-onboarding-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/rcs-onboarding-list"
                  >
                    RCS Onboarding
                  </Link>
                </li>
                {/* <li className={pName === '/dashboard/rcs-list' || pName === '/dashboard/apply-rcs' ? 'sidebar-item active' : 'sidebar-item'}><a className="sidebar-link" href="/dashboard/rcs-list">Apply For RCS </a></li> */}
                <li
                  className={
                    pName === "/dashboard/create-rcs-template" ||
                    pName === "/dashboard/rcs-template-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/rcs-template-list"
                  >
                    RCS Template
                  </Link>
                </li>
                {/* <li className={pName === '/dashboard/rcs-pricing' ? 'sidebar-item active' : 'sidebar-item'}><a className="sidebar-link" href="/dashboard/rcs-pricing">Pricing</a></li>
                <li className={pName === '/dashboard/rcs-reports' ? 'sidebar-item active' : 'sidebar-item'}><a className="sidebar-link" href="/dashboard/rcs-reports">Report</a></li>
                <li className={pName === '/dashboard/rcs-agent-list' ? 'sidebar-item active' : 'sidebar-item'}><a className="sidebar-link" href="/dashboard/rcs-agent-list">Add RCS Agent</a></li>
                <li className={pName === '/dashboard/pagename' ? 'sidebar-item active' : 'sidebar-item'}><a className="sidebar-link" href="/dashboard/rcs-setting">Setting</a></li> */}
              </ul>
            </li>

            {chat_menu && (
              <li
                className={
                  pName === "/dashboard/chat-report" ||
                  pName === "/dashboard/chat-setting" ||
                  pName === "/dashboard/user-management/agent" ||
                  pName === "/dashboard/user-management/manager" ||
                  pName === "/dashboard/user-management/team" ||
                  pName === "/dashboard/chat-setting/tag"||
                  pName === "/dashboard/agent-login-history"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#ChatMgt"
                  data-target="#ChatMgt"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <span className="dashboardIcons rmove">
                    <BsFillChatDotsFill />
                  </span>
                  <span className="align-middle"> Chat Management </span>
                </a>
                <ul
                  id="ChatMgt"
                  className={
                    pName === "/dashboard/chat-report" ||
                    pName === "/dashboard/chat-setting" ||
                    pName === "/dashboard/user-management/agent" ||
                    pName === "/dashboard/user-management/manager" ||
                    pName === "/dashboard/user-management/team" ||
                    pName === "/dashboard/chat-setting/tag"||
                    pName === "/dashboard/agent-login-history"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/chat-report"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/chat-report">
                      Chat Report
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/chat-setting" ||
                      pName === "/dashboard/chat-setting/tag"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/chat-setting">
                      Chat Settings
                    </Link>
                  </li>{" "}
                  <li
                    className={
                      pName === "/dashboard/user-management/agent" ||
                      pName === "/dashboard/user-management/manager" ||
                      pName === "/dashboard/user-management/team"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/user-management/agent"
                    >
                      Agent Management
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/agent-login-history"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/agent-login-history">
                      Agent login history
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {camp_menu && (
              <li
                className={
                  pName === "/dashboard/campaign" ||
                  pName === "/dashboard/campaign-with-filter" ||
                  pName === "/dashboard/campaign-start" ||
                  pName === "/dashboard/sms-campaign"||
                  pName === "/dashboard/rcs-campaign"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#Campaign"
                  data-target="#Campaign"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />{" "}
                  <span className="align-middle"> Campaign </span>
                </a>
                <ul
                  id="Campaign"
                  className={
                    pName === "/dashboard/campaign" ||
                    pName === "/dashboard/sms-campaign-start" ||
                    pName === "/dashboard/campaign-start" ||
                    pName === "/dashboard/sms-campaign" ||
                    pName === "/dashboard/rcs-campaign"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/campaign-start" ||
                      pName === "/dashboard/sms-campaign-start" ||
                      pName === "/dashboard/sms-campaign"||
                      pName === "/dashboard/rcs-campaign"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      // data-toggle="modal"
                      // data-target="#CampaignModal"
                      className="sidebar-link"
                      to="/dashboard/campaign-start"
                    >
                      Create Campaign{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/campaign"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link className="sidebar-link" to="/dashboard/campaign">
                      {" "}
                      Campaign Report{" "}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li
              className={
                pName === "/dashboard/create-contact-list" ||
                pName === "/dashboard/contact-list"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#contactData"
                data-target="#contactData"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faAddressBook} />{" "}
                <span className="align-middle"> Contact List </span>
              </a>
              <ul
                id="contactData"
                className={
                  pName === "/dashboard/create-contact-list" ||
                  pName === "/dashboard/contact-list"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/dashboard/contact-list" ||
                    pName === "/dashboard/create-contact-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/contact-list">
                    Contact List
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                // pName === "/dashboard/create-contact-list" ||
                pName === "/dashboard/fixed-url"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#urlshortner"
                data-target="#urlshortner"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faAddressBook} />{" "}
                <span className="align-middle"> Url Shortner </span>
              </a>
              <ul
                id="urlshortner"
                className={
                  // pName === "/dashboard/create-contact-list" ||
                  pName === "/dashboard/fixed-url"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    // pName === "/dashboard/contact-list" ||
                    pName === "/dashboard/fixed-url"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/fixed-url">
                    Fixed Url
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                // pName === "/dashboard/create-contact-list" ||
                pName === "/dashboard/fixed-url-report"||
                pName === "/dashboard/cta" ||
                pName === "/dashboard/quick-reply"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#clickreport"
                data-target="#clickreport"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faFileAlt} />{" "}
                <span className="align-middle"> Click Report </span>
              </a>
              <ul
                id="clickreport"
                className={
                  // pName === "/dashboard/create-contact-list" ||
                  pName === "/dashboard/fixed-url-report"||
                   pName === "/dashboard/cta"||
                   pName === "/dashboard/quick-reply"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    // pName === "/dashboard/contact-list" ||
                    pName === "/dashboard/fixed-url-report"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/fixed-url-report"
                  >
                    Fixed Url Report
                  </Link>
                </li>
                <li
                  className={
                    // pName === "/dashboard/contact-list" ||
                    pName === "/dashboard/cta"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/cta"
                  >
                    CTA
                  </Link>
                </li>
                <li
                  className={
                    pName === "/dashboard/quick-reply"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/quick-reply"
                  >
                    Quick Reply
                  </Link>
                </li>
              </ul>
            </li>

            {fb_menu && (
              <li
                className={
                  pName === "/dashboard/create-new-fallback" ||
                  pName === "/dashboard/fallback-list"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  href="#Event"
                  data-target="#Event"
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                >
                  <img
                    className="dashboardIcons"
                    alt="icons"
                    src={fallbackIcon}
                  />{" "}
                  <span className="align-middle"> Fallback </span>
                </a>
                <ul
                  id="Event"
                  className={
                    pName === "/dashboard/create-new-fallback" ||
                    pName === "/dashboard/fallback-list"
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className={
                      pName === "/dashboard/create-new-fallback"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/create-new-fallback"
                    >
                      Create Fallback{" "}
                    </Link>
                  </li>
                  <li
                    className={
                      pName === "/dashboard/fallback-list"
                        ? "sidebar-item active"
                        : "sidebar-item"
                    }
                  >
                    <Link
                      className="sidebar-link"
                      to="/dashboard/fallback-list"
                    >
                      View Fallback
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* <li
              className={
                pName === "/dashboard/create-agent" ||
                pName === "/dashboard/agent-list"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#agent"
                data-target="#agent"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faUser} />{" "}
                <span className="align-middle"> Agent </span>
              </a>
              <ul
                id="agent"
                className={
                  pName === "/dashboard/create-agent" ||
                  pName === "/dashboard/agent-list"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/dashboard/create-agent"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/create-agent"
                  >
                    Create Agent{" "}
                  </Link>
                </li>
                <li
                  className={
                    pName === "/dashboard/agent-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/agent-list">
                    Agent List
                  </Link>
                </li>
              </ul>
            </li> */}

            <li
              className={
                pName === "/dashboard/reports"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <Link className="sidebar-link" to="/dashboard/reports">
                <img
                  className="dashboardIcons"
                  alt="icons"
                  src={analyticsIcon}
                />{" "}
                <span className="align-middle">Analytics</span>
              </Link>
            </li>

            <li
              className={
                pName === "/dashboard/configure-webhook" ||
                pName === "/dashboard/webhook-report"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#hook"
                data-target="#hook"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faAtom} />{" "}
                <span className="align-middle"> Webhook </span>
              </a>
              <ul
                id="hook"
                className={
                  pName === "/dashboard/configure-webhook" ||
                  pName === "/dashboard/webhook-report"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/dashboard/configure-webhook"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/configure-webhook"
                  >
                    Create Webhook{" "}
                  </Link>
                </li>
                <li
                  className={
                    pName === "/dashboard/webhook-report"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/webhook-report">
                    Webhook Report
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                pName === "/dashboard/my-profile" ||
                pName === "/dashboard/change-password"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                href="#ui"
                data-target="#ui"
                data-toggle="collapse"
                className="sidebar-link collapsed"
              >
                <FontAwesomeIcon icon={faUser} />{" "}
                <span className="align-middle">My Account</span>
              </a>
              <ul
                id="ui"
                className={
                  pName === "/dashboard/my-profile" ||
                  pName === "/dashboard/change-password" ||
                  pName === "/dashboard/transaction-payment" ||
                  pName === "/dashboard/notification" ||
                  pName === "/dashboard/transaction-history" ||
                  pName === "/dashboard/billing"
                    ? "sidebar-dropdown list-unstyled collapse show"
                    : "sidebar-dropdown list-unstyled collapse"
                }
                data-parent="#sidebar"
              >
                <li
                  className={
                    pName === "/dashboard/my-profile"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/my-profile">
                    Profile
                  </Link>
                </li>

                <li
                  className={
                    pName === "/dashboard/transaction-history"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/transaction-history"
                  >
                    {" "}
                    Transaction History{" "}
                  </Link>
                </li>

                <li
                  className={
                    pName === "/dashboard/price-list"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link className="sidebar-link" to="/dashboard/price-list">
                    {" "}
                    My Price List{" "}
                  </Link>
                </li>

                <li
                  className={
                    pName === "/dashboard/change-password"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <Link
                    className="sidebar-link"
                    to="/dashboard/change-password"
                  >
                    Change Password
                  </Link>
                </li>

                {checkUserUrl(url) && (
                  <>
                    <li
                      className={
                        pName === "/dashboard/notification"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/dashboard/notification"
                      >
                        {" "}
                        Notification Setup{" "}
                      </Link>
                    </li>
                    <li
                      className={
                        pName === "/dashboard/transaction-payment"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <Link
                        className="sidebar-link"
                        to="/dashboard/transaction-payment"
                      >
                        Recharge
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>
            {checkUserUrl(url) && (
              <>
                <li
                  className={
                    pName === "/dashboard/support" ||
                    pName === "/dashboard/create-new-ticket" ||
                    pName === "/dashboard/ticket"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <a className="sidebar-link" href="/dashboard/support">
                    <img
                      className="dashboardIcons"
                      alt="icons"
                      src={phoneIcon}
                    />{" "}
                    <span className="align-middle">Support</span>
                  </a>
                </li>
              </>
            )}
            <li
              className={
                pName === "/dashboard/download-centre"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a className="sidebar-link" href="/dashboard/download-centre">
                <FontAwesomeIcon icon={faDownload} />{" "}
                <span className="align-middle">Download Centre</span>
              </a>
            </li>

            {/* <li
              className={
                pName === "/dashboard/opt-out-list"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a className="sidebar-link" href="/dashboard/opt-out-list">
                <FontAwesomeIcon icon={faSortNumericUpAlt} />{" "}
                <span className="align-middle">OPT OUT List</span>
              </a>
            </li> */}

            {checkUserUrl(url) && (
              <li
                className={
                  pName === "/dashboard/market-place"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a className="sidebar-link" href="/dashboard/market-place">
                  <FontAwesomeIcon icon={faBriefcase} />{" "}
                  <span className="align-middle">Market Place</span>
                </a>
              </li>
            )}

            <hr className="m-0" />
            <br />
            <br />
            <br />
            <div className="sidebar-link">
              <div
                data-toggle="modal"
                data-target="#exampleModalCenter"
                className="feedback-bg"
              >
                <img src={feedbackImg} />
                <p>Help Us Make better</p>
                <button
                  type="button"
                  className="btn btn-primary border-0 btn-sm"
                >
                  {" "}
                  Share Feedback{" "}
                </button>
              </div>
            </div>
          </ul>
          </div>
        </div>
      </nav>
      <ChooseCampaign />
    </>
  );
};

export default DashboardLeftMenu;