import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../UI/Table/AdvanceDataTable";
import { ADMIN_CAMPAIGN_DATA } from "../UI/Table/ColumnConst";
import ReactDatePicker from "react-datepicker";
import AdminLayout from "../UI/Layout/AdminLayout";
import Title from "../UI/Text/Title";
import Spining from "../UI/Spining/Spining";
import api from "../../axios/Axios";
import { userloginDetails } from "../Utils/AdminAuth";
import Select from "react-select";
import { HOST_URL } from "../Dashboard/api/Api";

const AdminCampaignReport = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const adminType = lStorage.user.isAdmin;
  const [alert, setAlert] = useState({
    status: false,
    bgColor: "",
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState([]);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [allusers, setAllusers] = useState([{ value: "", label: "All Users" }]);
  const [seleteduser, setSeletedusers] = useState("");
  const [totalCost, setTotalCost] = useState(null);
  const [totalFailed, setTotalFailed] = useState(null);
  const [totalDelivered, setTotalDeliverd] = useState(null);
  const [totalSent, setTotalSent] = useState(null);
  const [totalRead, setTotalRead] = useState(null);
  const [totalData, setTotalData] = useState(null);
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (style) => ({
      ...style,
      fontSize: "12px",
    }),
  };

  useEffect(() => {
    usersList();
    campaigndata();
  }, []);
  const usersList = () => {
    setIsLoading(true);
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_whatsapp_user&type=${adminType}&token=${userToken}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          let data = result.data.filter((items) => {
            if (items.status === 1) {
              return items;
            }
          });

          data.forEach((item) => {
            let newuserData = { value: item.user_id, label: item.user_name };
            setAllusers((prevState) => [...prevState, newuserData]);
          });
        } else {
          setAlert({
            status: true,
            bgColor: "danger",
            errorMessage: result.message,
          });
        }
      });
    });
    setIsLoading(false);
  };
  const currentDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const campaigndata = async () => {
    setIsLoading(true);
    let sDate = currentDate(fromdate);
    let eDate = currentDate(todate);
    try {
      const { data } = await api.get(
        `api/bulk_campaign_sms.php?user_id=1&method=retrieve_admin&token=d83f8067106535063fb7a6aa&channel=whatsapp&from_date=${sDate}&to_date=${eDate}&seacrh_user_id=${seleteduser}`
      );
      if (data.success === true) {
       
        let totalCost = 0;
        let totaldelivered = 0;
        let totalsent = 0;
        let totalread = 0;
        let totalfailed = 0;
        let contactCount = 0;
        data.data.forEach((item) => {
          let intValue = parseFloat(item.campaign_cost);
          totalCost += intValue;
          totaldelivered += item.delivered;
          totalsent += item.sent;
          totalread += item.read;
          totalfailed += item.failed;
          contactCount += item.contact_count;
        });
        setTotalData(contactCount);
        setTotalCost(totalCost);
        setTotalDeliverd(totaldelivered);
        setTotalFailed(totalfailed);
        setTotalRead(totalread);
        setTotalSent(totalsent);
        setCampaign(data.data);
        setIsLoading(false);
      } else {
        setCampaign([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    
  };
  const userHandler = (e) => {
    setSeletedusers(e.value);
  };

  const fromdateHandler = (data) => {
    setFromdate(data);
  };
  const todateHandler = (data) => {
    setTodate(data);
  };

  return (
    <div>
      <AdminLayout>
        <Title isButton={false}> Campaign Report </Title>

        <div className="row">
          <div className="col-xl-12 col-xxl-5 d-flex">
            <div className="w-100">
              <div className="row">
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-0">Total Data</h5>
                      <h6 className="mt-0 mb-0">{totalData}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-0">Total Delivered</h5>
                      <h6 className="mt-0 mb-0">{totalDelivered}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-0">Total Failed</h5>
                      <h6 className="mt-0 mb-0">{totalFailed}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-0">Total Read</h5>
                      <h6 className="mt-0 mb-0">{totalRead}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2">
                      <h5 className="card-title mb-0">Total Sent</h5>
                      <h6 className="mt-0 mb-0">{totalSent}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="card">
                    <div className="card-body p-2 ">
                      <h5 className="card-title mb-0">Total Cost</h5>
                      <h6 className="mt-0 mb-0">
                        {parseFloat(totalCost).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="">
            <div className="card">
              {alert.status === true ? (
                <div className={alert.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{alert.errorMessage}</div>
                </div>
              ) : null}

              {isLoading && <Spining />}

              <div className="card-body">
                <div className="filter-option" >
                  <h6 className="card-subtitle text-muted">
                    Select User / Data Range{" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="input-group drop-down-icons mb-2 mr-sm-2 table1">
                        <div className="input-group mb-2 mr-sm-2 ">
                          {/* <div className="input-group-text">User </div> */}
                          <Select
                            onChange={userHandler}
                            options={allusers}
                            isSearchable={true}
                            placeholder="All Users"
                            styles={customStyles}

                            // isClearable
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2 table2">
                        {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div> */}

                        <ReactDatePicker
                          className="form-control btn-block"
                          dateFormat="dd/MM/yyyy"
                          value={fromdate}
                          onChange={fromdateHandler}
                          selected={fromdate}
                          placeholderText="From Date"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2 table3">
                        {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div> */}
                        <ReactDatePicker
                          className="form-control btn-block"
                          dateFormat="dd/MM/yyyy"
                          value={todate}
                          onChange={todateHandler}
                          selected={todate}
                          placeholderText="Date To"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group btn-block mb-2 mr-sm-2 table4">
                        <button
                          onClick={campaigndata}
                          className="btn mt-1 btn-blue btn-sm  btn-block"
                        >
                          Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <AdvanceDataTable
                    tableName={""}
                    tableCell={ADMIN_CAMPAIGN_DATA}
                    tableData={campaign}
                    isLoading={isLoading}
                    isError={alert.errorMessage}
                    filterstatus={false}
                    pagination={true}
                  />
                  <div className="d-flex justify-content-end mr-2">
                    <small className="mb-2">
                      Total cost: {parseFloat(totalCost).toFixed(2)}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default AdminCampaignReport;
