import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL, NODE_HOST_URL } from "../Dashboard/api/Api";
import { userId } from "../Utils/userAuth";
import Axios from "../../axios/Axios";
import dayjs from "dayjs";
class UserPriceDetails extends Component {
  constructor() {
    const lStorage =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("emp")) ||
      JSON.parse(localStorage.getItem("reseller"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType = lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      emp_type: empType,
      id: "",
      price_update: true,
      emailCost: "",
      countryCode: "",
      addEmailCost: "",
      smsCost: "",
      voiceCost: "",
      voivePulse: "",
      wp_sending_cost: "",
      wp_conv_cost: "",
      successMessage: false,
      selectUserPriceDetails: "",
      seletedUserId: "",
      selectedUserType: '',
      errorMessage: "",
      bgColor: "",
      openOtherPrice: true,
      rcsPrice: false,
      rcscountryCode: "",
      botid: "",
      basicrcscost: "",
      a2p_single_rcs_cost: "",
      a2p_conversation_cost: "",
      p2a_conversation_cost: "",
      margin: "",
      isChecked: false,
      rcsPriceList: [],
      _id: "",
      csvData: "",
      status: 1,
      frozen: 0,
      showModal: false,
      confirmCode: '',
      popupStatus: false,
      popupColor: "",
      popupMessage: "",
      utility: '',
      authentication: '',
      userBotId: [],
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.getRcsData();
    this.getBotid();
    this.setState({ seletedUserId: this.props.match.params.id, selectedUserType: this.props.match.params.userType });

    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=pricelist&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        console.log("pricelist", result);
        if (result.success === true) {
          console.log(result.data)
          this.setState({ selectUserPriceDetails: result.data });
          this.setState({ emailCost: result.email_cost });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  getUserDetails() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('user',result);
        if (result.success === true) {
          this.setState({
            email: result.data[0].email,
          });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  addPriceTo() {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=create&country_code=${this.state.countryCode}&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&ret_user_id=${this.props.match.params.id}&token=${this.state.token}&wp_util_cost=${this.state.utility}&wp_auth_cost=${this.state.authentication}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  updatePrice(
    id,
    countrycode,
    smscost,
    voiceCost,
    voicepulse,
    wp_conv_cost,
    wp_sending_cost,
    wp_auth_cost,
    wp_util_cost
  ) {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({
      seletedUserId: this.props.match.params.id,
      countryCode: countrycode,
      smsCost: smscost,
      voiceCost: voiceCost,
      voivePulse: voicepulse,
      wp_conv_cost: wp_conv_cost,
      wp_sending_cost: wp_sending_cost,
      price_update: false,
      successMessage: true,
      errorMessage: "Add Price Details",
      bgColor: "alert alert-info alert-dismissible",
      id: id,
      utility: wp_util_cost,
      authentication: wp_auth_cost
    });
  }

  updateUserPrice() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=update&id=${this.state.id}&type=admin&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&token=${this.state.token}&wp_util_cost=${this.state.utility}&wp_auth_cost=${this.state.authentication}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  buttonHnadlar = (event) => {
    event.target.checked ? this.setState({ isChecked: true }) : this.setState({ isChecked: false });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.trim()).filter(row => row.length > 0);  // Trim and remove empty rows

        // Optional: Process rows to extract headers and values
        const headers = rows[0].split(","); // Assuming the first row is the header
        const data = rows.slice(1).map((row) => {
          const values = row.split(",");
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = values[index];
          });
          return obj;
        });

        this.setState({ csvData: file })
      };

      reader.readAsText(file);
    }
  };

  isValid = () => {
    if (this.state.rcscountryCode === undefined || this.state.rcscountryCode === '') {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Country Code",
      });
      return false
    }
    if (this.state.botid === undefined || this.state.botid === '') {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Bot Id",
      })
      return false
    }
    if (this.state.margin === undefined || this.state.margin === '') {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please Enter Margin",
      });
      return false
    }
    // if(this.state.id){
    //   if(this.state.status === undefined || this.state.status === ''){
    //     this.setState({
    //       successMessage: true,
    //       bgColor: "alert alert-danger alert-dismissible",
    //       errorMessage: "Please Select Status",
    //     });
    //     return false
    //   }
    //   if(this.state.frozen === undefined || this.state.frozen === ''){
    //     this.setState({
    //       successMessage: true,
    //       bgColor: "alert alert-danger alert-dismissible",
    //       errorMessage: "Please Select Frozen",
    //     });
    //     return false
    //   }
    //   return fasle;
    // }
    return true;
  }

  addRcsPrice = async () => {
    // if (this.isValid()) {
      const paramData = {
        user_id: this.state.user_id,
        retr_user_id: this.props.match.params.id,
        token: this.state.token,
        method: "add_single_country_pricercs",
        country_code: this.state.rcscountryCode,
        bot_id: this.state.botid || '67890',
        // bot_id: "4467895",
        margin: parseFloat(this.state.margin),
        parent_type: this.state.emp_type,
        client_type: this.props.match.params.userType,
        is_frozen: this.state.frozen,
        is_active: this.state.status
      }
      console.log(paramData)
      try {
        let { data } = await Axios.post(`${NODE_HOST_URL}/rcs_setting`, paramData)
        if (data) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: data.message,
          });
          setTimeout(() => {
            this.setState({ successMessage: false })
          }, 4000)
          this.getRcsData();
          this.setState({
            rcscountryCode: "",
            botid: "",
            margin: "",
            _id: ""
          });
        }
      } catch (error) {
        console.log(error);
      }
    // }
  }

  getRcsData = async () => {
    const retrieveParam = {
      user_id: this.state.user_id,
      userId: this.props.match.params.id,
      token: this.state.token,
      method: "retrieve_price"
    }
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs_setting`, retrieveParam);
      if (data.success) {
        console.log(data)
        this.setState({ rcsPriceList: data.data })
      }
    } catch (error) {
      console.log(error);
    }
  }

  getRcsPrice = (id, countrycode, botid, rcscost, a2pcost, a2pconvcost, p2acost) => {
    this.setState({
      _id: id,
      rcscountryCode: countrycode,
      botid: botid,
      basicrcscost: rcscost,
      a2p_single_rcs_cost: a2pcost,
      a2p_conversation_cost: a2pconvcost,
      p2a_conversation_cost: p2acost,
    });
  }

  updateRcsPrice =async () =>{
    const paramupdateData = {
      user_id: this.state.user_id,
      token: this.state.token,
      retr_user_id: this.props.match.params.id,
      bot_id: this.state.botid,
      // bot_id: "4467895",
      country_code: this.state.rcscountryCode,
      basic_sms_cost: parseFloat(this.state.basicrcscost),
      p2a_conv_cost: parseFloat(this.state.p2a_conversation_cost),
      a2p_conv_cost: parseFloat(this.state.a2p_conversation_cost),
      a2p_single_sms_cost: parseFloat(this.state.a2p_single_rcs_cost),
      method: "update_rcsprice",
      parent_type: this.state.emp_type,
      client_type: this.props.match.params.userType,
    }
    try {
      let {data} = await Axios.post(`${NODE_HOST_URL}/rcs_setting`,paramupdateData);
      if(data.success){
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        setTimeout(() => {
          this.setState({ successMessage: false })
        }, 4000)
        this.getRcsData();
      }
    } catch (error) {
      console.log(error)
    }
  }

  

  UploadCsv = async () => {
    console.log("Hello World")
    let formData = new FormData();

    formData.append('file', this.state.csvData);
    formData.append('user_id', this.state.user_id);
    formData.append('method', 'add_price');
    formData.append('token', this.state.token);
    formData.append('userId', this.props.match.params.id);

    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs_setting`, formData);
      if (data.success) {
        this.setState({
          successMessage: true,
          bgColor: "alert alert-success alert-dismissible",
          errorMessage: data.message,
        });
        this.getRcsData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  confirmationCode = () => {
    if (this.state.confirmCode === '123456') {
      this.setState({ showModal: false, confirmCode: '' })
    }
    else {
      this.setState({
        popupStatus: true,
        popupColor: "alert alert-danger",
        popupMessage: "Confirmation Code is not Correct"
      })
    }
  }

  getBotid = async () => {
    const senderParamData = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "userwise_bot",
      client_id: this.props.match.params.id
    }
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/admin/rcs`, senderParamData);
      if (data.success) {
        console.log(data);
        this.setState({
          userBotId: data.data,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    console.log(this.state.emp_type);
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    User Details{" "}
                    <strong className="text-info"> {this.state.email} </strong>{" "}
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={
                                "/admin/user-details/" +
                                this.state.seletedUserId + `/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item ">
                                User Details{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/admin/user-transaction-details/" +
                                this.state.seletedUserId + `/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>
                            <Link
                              to={
                                "/admin/user-price-details/" +
                                this.state.seletedUserId + `/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item active">
                                {" "}
                                Price Details{" "}
                              </li>
                            </Link>
                            {this.state.emp_type === "reseller" ? null : <>
                              <Link
                                to={
                                  "/admin/user-add-price/" +
                                  this.state.seletedUserId + `/` + this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item"> Add Credit </li>
                              </Link>
                              <Link
                                to={
                                  "/admin/user-entity-pass/" +
                                  this.state.seletedUserId + `/` + this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item">
                                  {" "}
                                  Entity By Pass{" "}
                                </li>
                              </Link>
                              <Link
                                to={
                                  "/admin/change-currency/" +
                                  this.state.seletedUserId + `/` + this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item ">
                                  {" "}
                                  Change Currency{" "}
                                </li>
                              </Link>

                              <Link
                                to={
                                  "/admin/bulk-sms-route/" +
                                  this.state.seletedUserId + `/` + this.state.selectedUserType
                                }
                              >
                                {" "}
                                <li className="breadcrumb-item ">
                                  {" "}
                                  Bulk Sms Route Setting{" "}
                                </li>
                              </Link>
                              <Link to={`/admin/agent-setting/${this.state.seletedUserId}/${this.state.selectedUSerType}`}><li className="breadcrumb-item">Agent Setting</li></Link>
                            </>}
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  {/* <div className="card-body">
                    <div className="filter-option">
                      <div className="card">
                        <div className="card-body">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0 ml-3">
                            <span className="breadcrumb bg-transparent p-0 mt-1 mb-0" onClick={`this.setState({openOtherPrice : true})`}> <Link to={`/admin/user-price-details/ + ${this.state.seletedUserId}`}><li className={this.state.openOtherPrice ? "breadcrumb-item active" : "breadcrumb-item"}>Other Price</li></Link></span>
                            <span className="breadcrumb bg-transparent p-0 mt-1 mb-0"> <Link to={`/admin/user-price-details/ + ${this.state.seletedUserId}`}><li className="breadcrumb-item">Rcs Price</li></Link></span>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                      </div>
                    ) : null}


                   {this.state.emp_type === "reseller" ? null: <div className="filter-option">
                      {/* <h6 className="card-subtitle text-muted">Add New Price</h6> */}
                      <div className="card">
                        <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0 ml-3">
                          <span
                            className="breadcrumb bg-transparent p-0 mt-1 mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setState({ openOtherPrice: !this.state.openOtherPrice, rcsPrice: false })}
                          >
                            <li className={this.state.openOtherPrice ? "breadcrumb-item active" : "breadcrumb-item"}>
                              WP/Sms/Voice Price
                            </li>
                          </span>
                          <span className="breadcrumb bg-transparent p-0 mt-1 mb-0" style={{ cursor: "pointer" }} onClick={() => this.setState({ rcsPrice: true, openOtherPrice: false })}>
                            <li className={this.state.rcsPrice ? "breadcrumb-item active" : "breadcrumb-item"}>Rcs Price</li>
                          </span>
                        </ol>

                        {this.state.emp_type === "reseller" ? null : (
                          <>

                            {this.state.openOtherPrice && (
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-3">
                                    <label className="form-label">Voice Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.voiceCost}
                                      onChange={(event) =>
                                        this.setState({ voiceCost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">Voice Pulse</label>
                                    <select
                                      value={this.state.voivePulse}
                                      onChange={(event) =>
                                        this.setState({ voivePulse: event.target.value })
                                      }
                                      className="form-control"
                                    >
                                      <option value=""> Select </option>
                                      <option value="15">15</option>
                                      <option value="30">30</option>
                                      <option value="60">60</option>
                                    </select>
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">County Code</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.countryCode}
                                      onChange={(event) =>
                                        this.setState({ countryCode: event.target.value })
                                      }
                                      placeholder="91"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">SMS Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.smsCost}
                                      onChange={(event) =>
                                        this.setState({ smsCost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP conv cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.wp_conv_cost}
                                      onChange={(event) =>
                                        this.setState({ wp_conv_cost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Marketing Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.wp_sending_cost}
                                      onChange={(event) =>
                                        this.setState({ wp_sending_cost: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Utility Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.utility}
                                      onChange={(event) =>
                                        this.setState({ utility: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label">WP Authentication Cost</label>
                                    <input
                                      type="text"
                                      defaultValue={this.state.authentication}
                                      onChange={(event) =>
                                        this.setState({ authentication: event.target.value })
                                      }
                                      placeholder="Rs."
                                      className="form-control"
                                    />
                                  </div>

                                  <div className="col-md-12 text-right">
                                    {this.state.price_update ? (
                                      <div style={{ marginTop: 40 }} className="input-group text-right">
                                        <input
                                          type="button"
                                          onClick={() => this.addPriceTo()}
                                          value="Add"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    ) : (
                                      <div style={{ marginTop: 40 }} className="input-group">
                                        <input
                                          type="button"
                                          onClick={() => this.updateUserPrice()}
                                          value="Update"
                                          className="btn btn-sm btn-primary text-center"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                       {this.state.emp_type === "reseller" ? null : (
                        <>
                                               {this.state.rcsPrice && (
                          <div className="card-body">
                            <div className="whatsapp-options">
                              <div className="row">
                                <div className="col-6"></div>
                                <div className="col-6">
                                  <label className="switch float-right mb-3">
                                    <input
                                      type="checkbox"
                                      defaultChecked={this.state.isChecked === true ? "checked" : null}
                                      onChange={this.buttonHnadlar}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="float-right mr-2"><b>All Country Price</b></span>
                                </div>
                              </div>
                            </div>
                            {this.state.isChecked ?
                              <>
                                <div className="row">
                                  <div className="col-4">
                                    <button
                                      onClick={() => this.setState({ showModal: true })}
                                      className="btn btn-secondary mt-3"
                                      style={{ fontSize: "12px", padding: "10px 10px 10px 10px" }}
                                    >
                                      <span className="mr-2"><FontAwesomeIcon icon={faPlus} /></span>
                                      Add Price
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="input-group mb-2 mt-4 mr-sm-2">
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">AMD CSV File</div>
                                      </div>
                                      <input
                                        type="file"
                                        name="file"
                                        accept=".csv"
                                        onChange={this.handleFileChange}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                      <button type="button" onClick={()=>this.UploadCsv()} className="btn btn-info">Upload</button>
                                      </div>
                                    </div> */}
                              </> :
                              <div className="row">
                                <div className="col-md-4">
                                  <label className="form-label">County Code</label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.rcscountryCode}
                                    onChange={(event) =>
                                      this.setState({ rcscountryCode: event.target.value, successMessage: false })
                                    }
                                    placeholder="91"
                                    className="form-control"
                                  />
                                </div>

                                <div className="col-md-4">
                                  <label className="form-label">Bot Id</label>
                                  <select value={this.state.botid} className="form-control" onChange={(e) => this.setState({ botid: e.target.value, successMessage: false })}>
                                    <option value={""}>select Bot ID</option>
                                    {this.state.userBotId?.map((x) => (
                                      <option value={x.bot_id}>{x.bot_id}</option>
                                    ))}
                                  </select>
                                  {/* <input
                                        type="text"
                                        defaultValue={this.state.botid}
                                        onChange={(event) =>
                                          this.setState({ botid: event.target.value, successMessage: false })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      /> */}
                                </div>

                                {!this.state._id && <div className="col-md-4">
                                  <label className="form-label">Margin</label>
                                  <input
                                    type="text"
                                    defaultValue={this.state.margin}
                                    onChange={(event) =>
                                      this.setState({ margin: event.target.value, successMessage: false })
                                    }
                                    placeholder="Rs."
                                    className="form-control"
                                  />
                                </div>}

                                {this.state._id ?
                                  <>
                                    <div className="col-md-4">
                                      <label className="form-label">Basic Rcs Cost</label>
                                      <input
                                        type="text"
                                        defaultValue={this.state.basicrcscost}
                                        onChange={(event) =>
                                          this.setState({ basicrcscost: event.target.value, successMessage: false })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">A2P Single Rcs Cost</label>
                                      <input
                                        type="text"
                                        defaultValue={this.state.a2p_single_rcs_cost}
                                        onChange={(event) =>
                                          this.setState({ a2p_single_rcs_cost: event.target.value, successMessage: false })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">A2P Conversation Cost</label>
                                      <input
                                        type="text"
                                        defaultValue={this.state.a2p_conversation_cost}
                                        onChange={(event) =>
                                          this.setState({ a2p_conversation_cost: event.target.value, successMessage: false })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label className="form-label">P2A Conversation Cost</label>
                                      <input
                                        type="text"
                                        defaultValue={this.state.p2a_conversation_cost}
                                        onChange={(event) =>
                                          this.setState({ p2a_conversation_cost: event.target.value, successMessage: false })
                                        }
                                        placeholder="Rs."
                                        className="form-control"
                                      />
                                    </div>
                                  </> : null}
                                {this.state._id &&
                                  <div className="col-md-4">
                                    <label className="form-label">Status</label>
                                    <select className="form-control" value={this.state.status} onChange={(e) => this.setState({ status: e.target.value, successMessage: false })}>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                  </div>}

                                {this.state._id &&
                                  <div className="col-md-4">
                                    <label className="form-label">Frozen</label>
                                    <select className="form-control" value={this.state.frozen} onChange={(e) => this.setState({ frozen: e.target.value, successMessage: false })}>
                                      <option value="1">Frozen</option>
                                      <option value="0">non-Forzen</option>
                                    </select>
                                  </div>}

                                <div className="col-md-12 text-right">
                                  {this.state._id ? (
                                    <div style={{ marginTop: 40 }} className="input-group">
                                      <input
                                        type="button"
                                        onClick={() => this.updateRcsPrice()}
                                        value="Update"
                                        className="btn btn-sm btn-primary text-center"
                                      />
                                    </div>
                                  ) : (
                                    <div style={{ marginTop: 40 }} className="input-group text-right">
                                      <input
                                        type="button"
                                        onClick={() => this.addRcsPrice()}
                                        value="Add"
                                        className="btn btn-sm btn-primary text-center"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>}

                          </div>
                        )} 
                        </>
                       )} 
                      </div>
                    </div>}



                    {this.state.openOtherPrice &&
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                          <div className="card table-responsive flex-fill">
                            {this.state.selectUserPriceDetails ? (
                              <table className="table table-hover my-0">
                                <thead>
                                  <tr>
                                    <th>Country Code</th>
                                    <th className="">Currency</th>
                                    <th className="">SMS Cost</th>
                                    <th className="">Voice / Pulse Cost</th>
                                    <th className=""> Email </th>
                                    <th className=""> WP conv cost </th>
                                    <th className=""> WP Sending Cost </th>
                                    <th className=""> Utility </th>
                                    <th className=""> Authentication </th>
                                    <th className=""> Date / Time</th>
                                    {this.state.emp_type === "reseller" ? null : (
                                      <>
                                        <th className=""> Edit </th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.selectUserPriceDetails.map((item, i) => (
                                    <tr key={i}>
                                      <td>
                                        {item.country_name} ({item.country_code})
                                      </td>
                                      <td>{item.currency_name}</td>
                                      <td>{item.sms_cost}</td>
                                      <td>
                                        {item.voice_cost} / {item.voice_pulse}
                                      </td>
                                      <td>{this.state.emailCost}</td>
                                      <td>{item.wp_conv_cost}</td>
                                      <td>{item.wp_sending_cost}</td>
                                      <td>{item.wp_util_cost}</td>
                                      <td>{item.wp_auth_cost}</td>
                                      <td>{item.created}</td>
                                      {this.state.emp_type === "reseller" ? null : (
                                        <>
                                          <td>
                                            <span
                                              onClick={() =>
                                                this.updatePrice(
                                                  item.id,
                                                  item.country_code,
                                                  item.sms_cost,
                                                  item.voice_cost,
                                                  item.voice_pulse,
                                                  item.wp_conv_cost,
                                                  item.wp_sending_cost,
                                                  item.wp_auth_cost,
                                                  item.wp_util_cost
                                                )
                                              }
                                              className="badge bg-success"
                                            >
                                              <FontAwesomeIcon icon={faEdit} /> Edit
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                  <p>No Any User Price Found</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>}

                    {this.state.emp_type === "reseller" ? null : 
                    <>
                    ({this.state.rcsPrice &&
                      <div className="row">
                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                          <div className="card table-responsive flex-fill">
                            {this.state.rcsPriceList ? (
                              <table className="table table-hover my-0">
                                <thead>
                                  <tr>
                                    <th>Country Code</th>
                                    <th className="">Bot ID</th>
                                    <th className="">Rcs Cost</th>
                                    <th className="">A2P Sinagle Rcs Cost</th>
                                    <th className=""> A2P Conversation Cost </th>
                                    <th className=""> P2A Conversation Cost </th>
                                    <th className=""> Date/Time </th>
                                    {this.state.emp_type === "reseller" ? null : (
                                      <>
                                        <th className=""> Edit </th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.rcsPriceList.map((item, i) => (
                                    <tr key={i}>
                                      <td>
                                        {item.country_code}
                                      </td>
                                      <td>{item.bot_id}</td>
                                      <td>{item.basic_sms_cost}</td>
                                      <td>{item.a2p_single_sms_cost}</td>
                                      <td>{item.a2p_conv_cost}</td>
                                      <td>{item.p2a_conv_cost}</td>
                                      <td>{dayjs(item.created_date).format("DD/MM/YYYY h:mm A")}</td>
                                      {this.state.emp_type === "reseller" ? null : (
                                        <>
                                          <td>
                                            <span
                                              onClick={() =>
                                                this.getRcsPrice(
                                                  item._id,
                                                  item.country_code,
                                                  item.bot_id,
                                                  item.basic_sms_cost,
                                                  item.a2p_single_sms_cost,
                                                  item.a2p_conv_cost,
                                                  item.p2a_conv_cost
                                                )
                                              }
                                              className="badge bg-success"
                                            >
                                              <FontAwesomeIcon icon={faEdit} /> Edit
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <div className="text-center">
                                <div className="card-body">
                                  <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                  <p>No Any User Price Found</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>})</>}
                  </div>
                  {this.state.showModal && (
                    <div className="modal fade show d-block" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation Code</h5>
                          </div>
                          <div className="modal-body">
                            {this.state.popupStatus ? (
                              <div className={this.state.popupColor} role="alert">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="alert"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div className="">{this.state.popupMessage}</div>
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-12">
                                <label>Enter the Code</label>
                                <input type="number" value={this.state.confirmCode} onChange={(e) => this.setState({ confirmCode: e.target.value, popupStatus: false })} placeholder="Please Enter the Code" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showModal: false })}>
                              Close
                            </button>
                            <button type="button" onClick={this.confirmationCode} className="btn btn-info">
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default UserPriceDetails;
