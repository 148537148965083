import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "react-bootstrap-tabs";

import {
  faBroadcastTower,
  faArrowCircleLeft,
  faAddressBook,
  faCheckCircle,
  faFlag,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../UI/Alert/Alert";
import Spining from "../../UI/Spining/Spining";
import {
  CAMPAIGN_CONTACT_LIST_API,
  WHATSAPP_TEMPLATE_LIST,
} from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { Link } from "react-router-dom";
import WhatsAppPreview from "../whatsapp/components/WhatsAppPreview";
import { no_data } from "../../../images/WhatsAppImages";
import {
  getWhatsaAppTemplateList,
  campaignContactList,
  WhstappAccountList,
  createWhatsAppCampaign,
  contactListAPI,
  HOST_URL,
} from "../api/Api";
import { Helmet } from "react-helmet";
import BillableValue from "./component/BillableValue";
import SetMediaDynamic from "./component/SetMediaDynamic";
import Select from "react-select";
import { withRouter } from 'react-router-dom';

class Campaign extends Component {
  constructor(props) {
  
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
    super(props);
    this.state = {
      user_id: userId,
      token: userToken,
      contactList: "",
      step: 0, // TODO 0
      templatePreView: "",
      previewData: "",
      sampleData: "",
      WhatsappTemplateList: "",
      smsTemplateList: "",
      schedule_datetime: date,
      is_schedule: 0,
      media_url: "",
      brandNumberList: [],
      parameter: [],
      mobile_colum: "",
      tableColume: [],
      varibaleInTemplate: "",
      seleted_brand_number: null,
      CampaignName: "",
      channel: "whatsapp",
      templteId: "",
      listId: "",
      SMSmessage: "",
      country_code: 91,
      campaignType: "1",
      textnumbers: "",
      smsTemplateDetails: null,
      selectedListName: "",
      totalCount: "",
      isLoading: false,
      errorMessage: "",
      isError: false,
      messageType: "",
      billable: "",
      not_billable: "",
      enableCampaign: true,
      option: [],
      contactOption:[],
      urlPath:window.location,
      route : ""
    };
    this.nextStep = this.nextStep.bind(this);
    this.findAndReplace = this.findAndReplace.bind(this);
    this.getCsvData = this.getCsvData.bind(this);
    this.scheduleHandler = this.scheduleHandler.bind(this);
    this.setMediaURL = this.setMediaURL.bind(this);
    this.handleChannel = this.handleChannel.bind(this);
  }
 
  componentDidMount() {
    this.getContactList();
    this.getBrandNumber();
    this.getWhatsaAppTemplateList();
    this.getRoute();
  }

  getRoute =async () =>{
    try {
      let response = await fetch (`${HOST_URL}/get_user_route.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`)
      let result = await response.json()
      if(result.success){
        this.setState({route : result.data})
      }
    } catch (error) {
      
    }

  }

  getWhatsaAppTemplateList = () => {
    getWhatsaAppTemplateList().then((res) => {
      this.setState({ isLoading: true });
      try {
        if (res.data.success === true) {
          let withOutDynamicTemplate = [];
          res.data.data.find((item) => {
            if (
              // item.temp_button.includes(`"urlType":"Dynemic"`) != true &&
              item.temp_status === 1
            )
              withOutDynamicTemplate.push(item);
          });
          
          let filterData = withOutDynamicTemplate.filter(x => x.temp_type !== "Carousel");
          
          filterData.forEach((item) => {
            let newData = { value: item.id, label: item.temp_name };
            this.setState((prevState) => ({
              option: [...prevState.option, newData],
            }));
          });
          this.setState({
            WhatsappTemplateList: filterData,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  nextStep = async () => {
    if (this.state.step == 0) {
      if (this.state.CampaignName === "" || this.state.channel === "") {
        this.setState({
          isError: true,
          messageType: "alert-danger",
          errorMessage: "Please enter all details",
          isLoading: false,
        });
      } else {
        this.setState({
          step: this.state.step + 1,
          successMessage: false,
          isError: false,
        });
      }
    }

    if (this.state.step == 1) {
      if (this.state.listId === "" && this.state.textnumbers === "") {
        this.setState({
          isError: true,
          messageType: "alert-danger",
          errorMessage: "Please select at list one option",
          isLoading: false,
        });
      } else {
        this.setState({
          step: this.state.step + 1,
          successMessage: false,
          isError: false,
        });
      }
    }
    this.setState({
      step: this.state.step + 1,
      successMessage: false,
      isError: false,
    });
  };

  prevStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  getContactList = () => {
    contactListAPI("retrieve").then((res) => {
      if (res.data.success === true) {
        let ctList=res.data.data;
        ctList.forEach((item) => {
          let newCtData = { value: item.id, label: `${item.name}(${item.number_count})` };
          this.setState((prevState) => ({
            contactOption: [...prevState.contactOption, newCtData],
          }));
        });
        this.setState({
          contactList: res.data.data,
          isLoading: false,
        });
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  createCampaign = async () => {
    this.setState({ isLoading: true });

    let createWhatsAppCampaignData = {
      token: this.state.token,
      user_id: this.state.user_id,
      channel: this.state.channel,
      template_id: this.state.templteId,
      list_id: this.state.listId,
      parameter: this.state.parameter,
      mobile_colum: this.state.mobile_colum,
      camp_name: this.state.CampaignName,
      media_url: this.state.media_url,
      total_count: this.state.totalCount,
      brand_number: this.state.seleted_brand_number,
      schedule_datetime: this.state.schedule_datetime,
      is_schedule: this.state.is_schedule,
      method: "create",
    };

    createWhatsAppCampaign(createWhatsAppCampaignData).then((res) => {
      if (res.data.success === true) {
        this.setState({
          isError: true,
          messageType: "alert-success",
          errorMessage: res.data.message,
        });
        setTimeout(function () {
          window.location = "/dashboard/campaign";
        }, 1000);
      } else {
        this.setState({
          isError: true,
          messageType: "alert-danger",
          errorMessage: res.data.message,
          isLoading: false,
        });
      }
    });
  };

  enableCampaingButton = (value) => {
    this.setState({
      enableCampaign: value,
    });
  };

  getBrandNumber = async () => {
    WhstappAccountList().then((res) => {
      if (res.data.success === true) {
        this.setState({ brandNumberList: res.data.data });
      } else {
        this.setState({ ErrorResponse: res.data.data });
      }
    });
  };

  selectedWhatsAppTemplate = async (e) => {
    // console.log(e.value)
    this.setState({ templteId: e.value });
    try {
      const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
        token: this.state.token,
        user_id: this.state.user_id,
        id: e.value,
        method: "retrieveid",
      });
      let urlbtn = [];
      let urlbtn2 = [];
      let callbtn = [];
      let button1 =[];
      let button2 = [];
      let button3 = [];
      if (data.success === true) {
        if (data.data[0].temp_button) {
          let buttonData = data.data[0].temp_button;
          buttonData.map((item, index) => {
            let key = Object.keys(item);
            if (key.includes("urltext2")) {
              urlbtn2[0] = item;
            }
            if (key.includes("urltext")) {
              urlbtn[0] = item;
            }
            if (key.includes("phone")) {
              callbtn[0] = item;
            }
            if (key.includes("button1")) {
              button1[0] = item;
            }
            if (key.includes("button2")) {
              button2[0] = item;
            }
            if (key.includes("button3")) {
              button3[0] = item;
            }
          });
        }

        let PreviewTemplateData = {
          tampleName: data.data[0].temp_name,
          language: data.data[0].temp_language,
          accountType: data.data[0].temp_category,
          templateType: data.data[0].temp_type,
          headerText: data.data[0].temp_header,
          headerOptions: data.data[0].temp_header === "" ? false : true,
          footerText: data.data[0].temp_footer,
          footerOptions: data.data[0].temp_header === "" ? false : true,
          temp_button: data.data[0].temp_button,
          bodyMessage: data.data[0].temp_body,
          button: data.data[0].temp_button,
          temp_status: data.data[0].temp_status,
          Urlbutton: urlbtn,
          Urlbutton2: urlbtn2,
          Callbutton: callbtn,
          button1: button1,
          button2 : button2,
          button3: button3,
          quickButton:
            data.data[0].temp_button.length > 0 &&
            data.data[0].temp_button[0].actionType === "quickReply"
              ? [data.data[0].temp_button[0]]
              : "",
          temp_attribute: [],
          isLoading: false,
        };
        this.getVariable(data.data[0].temp_body);
        this.setState({ templatePreView: PreviewTemplateData });
        this.mediaTemplate(PreviewTemplateData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  getVariable = (data) => {
    var regex = /{{([^}]+)}}/g;
    let m;
    var tempArr = ["mobile"];
    var parameter = { mobile: "" };
    while ((m = regex.exec(data)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      parameter[`${m[1]}`] = "";
      tempArr.push(m[1]);
    }
    this.setState({
      varibaleInTemplate: tempArr,
      parameter: [...this.state.parameter, parameter],
    });
  };

  setParameter = (e) => {
    this.state.parameter.map((item, i) => {
      Object.keys(item).map((key) => {
        if (key === e.target.name) {
          let obj = (this.state.parameter[i][key] = e.target.value);
          this.findAndReplace(e.target.name, obj);
        } else {
          console.log("something went wrong");
        }
      });
    });
  };

  getColumedata = (id) => {
    campaignContactList(id).then((res) => {
      this.setState({ isLoading: true });
      try {
        if (res.data.success === true) {
          this.setState({
            tableColume: Object.keys(res.data.data[0]),
            previewData: res.data.data,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      } catch (error) {
        console.log("error", error);
      }
    });
  };


  CopyPasteHandler=(e)=>{
    let numbersString= e.target.value
    let numbersArray = numbersString.split(',');
    let mobileObjectsArray = numbersArray.map((number, index) => ({
      id:index+1,
      mobile: number,
    }));
    
    this.setState({
      tableColume: Object.keys(mobileObjectsArray[0]),
      previewData: mobileObjectsArray,
      sampleData: mobileObjectsArray,
      isLoading: false,
    });
  }

  contactListhandler = (e) => {
    this.setState({ listId: e.value });
    var item = this.state.contactList.filter(
      (item) => item.id === parseInt(e.value)
    );
    this.setState({
      selectedListName: item[0].name,
      totalCount: item[0].number_count,
    });
    this.getColumedata(e.value);
    this.getSmapleData(e.value);
  };

  getSmapleData = async (id) => {
    try {
      const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
        token: this.state.token,
        user_id: this.state.user_id,
        list_id: id,
        method: "retrieve_data_sample",
      });
      if (data.success === true) {
        this.setState({ sampleData: data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderSampleData = () => {
    let data = this.state.sampleData;
    if (data.length > 0) {
      const column = Object.keys(data[0]);
      return (
        <>
          <p>Your Table Data </p>
          <table className="table table-hover  my-0">
            <thead>
              {column.map((data) => (
                <th>{data}</th>
              ))}
            </thead>
            {data.map((data) => (
              <tr>
                {column.map((v) => {
                  return <td>{data[v]}</td>;
                })}
              </tr>
            ))}
          </table>
        </>
      );
    }
  };

  findAndReplace = (name, column) => {
    let text = this.state.templatePreView.bodyMessage;
    let replaceKeyword = `{{${name}}}`;
    let value = this.state.sampleData[0][column];
    let newtext = text.replace(replaceKeyword, value);
    let obj = (this.state.templatePreView["temp_body"] = newtext);
    this.setState({
      templatePreView: {
        ...this.state.templatePreView,
        ["bodyMessage"]: newtext,
      },
    });
  };

  getCsvData(data) {
    this.setState({
      sampleData: data.rowData,
      tableColume: data.tableColume,
    });
  }

  scheduleHandler(e) {
    if (e.target.checked === true) {
      this.setState({ is_schedule: 1 });
    } else {
      this.setState({ is_schedule: 0 });
    }
  }

  mediaTemplate() {
    let data = this.state.templatePreView;
    if (data != "" && data != undefined) {
      const hasKey = "image" in data;
      if (hasKey) {
        console.log("The key exists.");
      } else {
        console.log("The key does not exist.");
      }
    }
  }

  setMediaURL(data) {
    let newTemplate = { ...this.state.templatePreView };
    let objectkey = Object.keys(this.state.templatePreView.headerText)[0];
    if (data) {
      newTemplate.headerText[objectkey] = data;
    }

    this.setState({
      media_url: data,
    });
  }
 
  handleChannel(e) {
    const { pathname } = this.state.urlPath;
    const segments = pathname.split("/");
    const endPath = segments[segments.length - 1];
    const selectedChannel = e.target.value;

    if (selectedChannel === "whatsapp") {
      if (endPath === "campaign-start") {
        this.setState({ channel: selectedChannel });
      } else {
         this.props.history.push("/dashboard/campaign-start");
      }
    }

    if (selectedChannel === "sms") {
      if (endPath === "sms-campaign") {
        this.setState({ channel: selectedChannel });
      } else {
         this.props.history.push("/dashboard/sms-campaign");
      }
    }

    if (selectedChannel === "rcs") {
      if (endPath === "rcs-campaign") {
        this.setState({ channel: selectedChannel });
      } else {
         this.props.history.push("/dashboard/rcs-campaign");
      }
    }
  }
 
  formRender = () => {
    switch (this.state.step) {
      case 1:
        return (
          <>
            <div className="filter-option">
              <div className="row">
                <div className="col-md-12">
                  <p className="d-block">
                    Choose Audiance to send the campaign on
                    <span className="text-primary"> {this.state.channel} </span>
                  </p>
                  <div className="">
                    <div>
                      <Tabs id="fall-back-tab">
                        <Tab label="Select From Contact List">
                          {this.state.contactList ? (
                            <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                              {/* <div className="input-group-text">
                                <FontAwesomeIcon icon={faAddressBook} />
                              </div> */}
                              {/* <select
                                onChange={this.contactListhandler}
                                className="form-control"
                                value={this.state.listId}
                              >
                                <option value={``}>Select Contact List</option>
                                {this.state.contactList.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.name} ({item.number_count})
                                  </option>
                                ))}
                              </select> */}

                              <Select
                          // value={selectedOption}
                          onChange={this.contactListhandler}
                          options={this.state.contactOption}
                          isSearchable={true}
                          placeholder="Select Contact List"
                          // isClearable
                        />

                            </div>
                          ) : (
                            <p className="alert alert-danger d-block">
                              You don't have any contacts please create{" "}
                              <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                              and then come back.
                            </p>
                          )}
                        </Tab>
                       {/* <Tab label="Copy Paste Data">
                          <div className="input-group mb-2 mt-4 mr-sm-2">
                            <textarea
                              placeholder="Copy and paste number data with commas, Example : 93XXXXXXX,9283XXXXX,7388XXXXX"
                              onChange={
                                this.CopyPasteHandler

                              }
                              defaultValue={this.state.textnumbers}
                              className="form-control"
                              rows={15}
                            ></textarea>
                          </div>
                        </Tab>*/}
                        {/* <Tab label="Upload .csv File">
                          <UploadCSVFile sendCsvData={this.getCsvData} />
                        </Tab> */}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="filter-option">
              <div className="row">
                <div className="col-md-7">
                  <p className="d-block mt-4">
                    Select{" "}
                    <span className="text-primary">{this.state.channel}</span>{" "}
                    template to campaign
                  </p>

                  {this.state.WhatsappTemplateList ? (
                    <>
                      <div className="input-group drop-down-icons mb-2 mr-sm-2">
                        {/* <div className="input-group-text">
                          <FontAwesomeIcon icon={faAddressBook} />
                        </div> */}

                        {/* <select
                          onChange={this.selectedWhatsAppTemplate}
                          className="form-control"
                        >
                          <option value={``}>Select Template</option>
                          {this.state.WhatsappTemplateList.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                {item.temp_name}
                              </option>
                            )
                          )}
                        </select> */}
                        <Select
                          // value={selectedOption}
                          onChange={this.selectedWhatsAppTemplate}
                          options={this.state.option}
                          isSearchable={true}
                          placeholder="Select Template"
                          // isClearable
                        />
                      </div>
                      <p></p>
                    </>
                  ) : (
                    <p className="alert alert-danger d-block">
                      You don't have any template please create{" "}
                      <Link to="/dashboard/whatsapp-template-list">
                        Template
                      </Link>{" "}
                      and then come back.
                    </p>
                  )}

                  {this.state.varibaleInTemplate && (
                    <>
                      <p>Please select column </p>
                      {this.state.varibaleInTemplate.map((item, index) => (
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-text">{item}</div>
                          <select
                            name={item}
                            onChange={this.setParameter}
                            className="form-control"
                          >
                            {this.state.tableColume.map((item, index) => (
                              <option name={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      ))}
                      {this.state.templatePreView && (
                        <SetMediaDynamic
                          setMediaURL={this.setMediaURL}
                          headertext={this.state.templatePreView.headerText}
                        />
                      )}
                    </>
                  )}

                  <hr />
                  {this.state.channel === "whatsapp" && (
                    <div className="card p-2">
                      <div className="table-responsive">
                        {this.renderSampleData()}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0"> Template Preview </h5>
                    </div>
                    <hr className="m-0" />
                    <div className="card-body">
                      {this.state.templatePreView ? (
                        <>
                          <WhatsAppPreview
                            previewData={this.state.templatePreView}
                            route = {this.state.route}
                          />
                        </>
                      ) : (
                        <div className="text-center">
                          <img className="img-fluid" src={no_data} />
                          <p className="text-right">
                            Please select {this.state.channel} Templte to
                            preview
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      case 3:
        return (
          <>
            <div className="pb-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="campaign-preview">
                    <p className="m-0">
                      <strong> Campaign Name: </strong>
                      <span className="">{this.state.CampaignName}</span>
                    </p>
                  </div>
                  <div className="Campaign-status mt-2">
                    <p className="m-0">
                      <strong> Channel Selected : </strong>
                      <span className="">{this.state.channel}</span>
                    </p>
                  </div>
                  <div className="campaign-preview">
                    <p className="m-0">
                      <strong> Campaign Brand Number: </strong>
                      <span className="">
                        {this.state.seleted_brand_number}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Campaign-status mt-2">
                    <p className="m-0">
                      <strong> Audiance : </strong>
                      <span className="">{this.state.selectedListName}</span>
                    </p>
                  </div>
                  <div className="Campaign-status mt-2">
                    <p className="m-0">
                      <strong> Campaign Template : </strong>
                      <span className="">
                        {this.state.templatePreView.tampleName}
                      </span>
                    </p>
                  </div>
                  <div className="Campaign-status mt-2">
                    <p className="m-0">
                      <strong> Campaign Date : </strong>
                      <span className="">{this.state.schedule_datetime}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <BillableValue
                  templteId={this.state.templteId}
                  listId={this.state.listId}
                  enableCampaingButton={this.enableCampaingButton}
                />
              </div>
              <hr />
              <div className="form-group">
                <div className="form-check ">
                  <p className="m-0 d-flex items-align-center">
                    <input
                      className="form-check-input mb-1"
                      defaultValue={this.state.is_schedule}
                      onChange={this.scheduleHandler}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <strong> Campaign Schedule </strong>
                  </p>
                </div>
                <small>
                  {" "}
                  Campaign scheduling is the process of specifying the start and
                  end dates and times for a campaign.
                </small>
              </div>
              {this.state.is_schedule === 1 && (
                <>
                  <p className="mt-3 d-block">
                    Select Date and Time of Campaign
                  </p>
                  <div className="input-group w-25 mb-2 mr-sm-2">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faAddressBook} />
                    </div>
                    <input
                      type="datetime-local"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ schedule_datetime: e.target.value })
                      }
                      placeholder="Example: campaign for digital marketing"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        );

      default:
        return (
          <div className="filter-option">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <p className="mt-3 d-block">Campaign Channel </p>
                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faBroadcastTower} />
                  </div>
                  <select
                    onChange={this.handleChannel}
                    className="form-control"
                  
                    value={this.state.channel}
                   
                  >
                    <option value={``}>Select Channel</option>
                    <option value={`whatsapp`}>WhatsApp</option>
                    <option value={`sms`}>SMS</option>
                    <option value={`rcs`}>RCS</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <p className="mt-3 d-block">Select Country </p>
                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faFlag} />
                  </div>
                  <select
                    onChange={(e) =>
                      this.setState({ country_code: e.target.value })
                    }
                    className="form-control"
                    value={this.state.country_code}
                  >
                    <option value="91"> India </option>
                    <option value="1"> United State</option>
                    <option value="1"> US / Canada</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <p className="mt-3 d-block">Select Brand Number </p>
                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faBroadcastTower} />
                  </div>
                  <select
                    onChange={(e) =>
                      this.setState({ seleted_brand_number: e.target.value })
                    }
                    className="form-control"
                    value={this.state.seleted_brand_number}
                  >
                    <option value={``}>Select Brand Number</option>
                    {this.state.brandNumberList.map((item, i) => (
                      <option key={i} value={item.brand_number}>
                        {item.brand_number}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <p className="mt-3 d-block">Enter Custom Campaign Name</p>
                <div className="input-group mb-2 mr-sm-2">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faAddressBook} />
                  </div>
                  <input
                    type="text"
                    defaultValue={this.state.CampaignName}
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ CampaignName: e.target.value })
                    }
                    placeholder="Example: campaign for digitl marketing"
                  />
                </div>
                <small className="text-primary">
                  Example : Campaign for remarketing
                </small>
              </div>
            </div>
          </div>
        );
    }
  };

  render() {
   
    
    
    return (
      <div className="wrapper">
        <Helmet>
          <style type="text/css">{`
         .whatsapp ul {
            display:none!important;
          }
        `}</style>
        </Helmet>

        {this.state.isLoading && <Spining />}
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className=" mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>WhatsApp Campaign </strong>
                  </h3>
                </div>
              </div>
              <div className="">
                <div className="card">
                  <div className="card-body">
                    <div id="msform">
                      <ul id="progressbar">
                        {this.state.step === 0 ? (
                          <>
                            <li className="active">Campaign Details</li>
                            <li>Choose Audience </li>
                            <li>Campaign Template </li>
                            <li> Preview Campaign </li>
                          </>
                        ) : this.state.step === 1 ? (
                          <>
                            <li className="active">Campaign Details</li>
                            <li className="active">Choose Audience</li>
                            <li>Campaign Template </li>
                            <li> Preview Campaign </li>
                          </>
                        ) : this.state.step === 2 ? (
                          <>
                            <li className="active">Campaign Details</li>
                            <li className="active">Choose Audience </li>
                            <li className="active">Campaign Template </li>
                            <li> Preview Campaign </li>
                          </>
                        ) : this.state.step === 3 ? (
                          <>
                            <li className="active">Campaign Details</li>
                            <li className="active">Choose Audience </li>
                            <li className="active">Campaign Template </li>
                            <li className="active"> Preview Campaign </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>

                {this.state.isError && (
                  <Alert type={this.state.messageType}>
                    {this.state.errorMessage}
                  </Alert>
                )}

                <div className="card">
                  <div className="card-body campaign-box">
                    {this.formRender()}

                    <hr />

                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <button
                          disabled={this.state.step === 0}
                          onClick={() => this.prevStep()}
                          className={
                            this.state.step === 0
                              ? "btn btn-block btn-secondary"
                              : "btn btn-block btn-primary"
                          }
                        >
                          <FontAwesomeIcon icon={faArrowCircleLeft} /> Previous
                        </button>
                      </div>
                      <div className="col-md-3">
                        {this.state.step === 3 ? (
                          <button
                            onClick={this.createCampaign}
                            className="btn btn-info"
                            disabled={this.state.enableCampaign}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} /> Start
                            Campaign
                          </button>
                        ) : (
                          <button
                            onClick={() => this.nextStep()}
                            className="btn btn-block btn-primary"
                          >
                            Next <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
      
    );
  }
}

export default withRouter(Campaign);