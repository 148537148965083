import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye,
  faArrowDown,
  faArrowUp,
  faDownload,
  faTrashAlt,
  faAddressBook,
  faInfoCircle,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL, HOST_URL_DB2, NODE_HOST_URL } from "../api/Api";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Spining from "../../UI/Spining/Spining";
import { IoLogoWhatsapp } from "react-icons/io";
import { BiBorderRadius } from "react-icons/bi";
import { FaSms, FcSms } from "react-icons/fa";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import WhatsAppCampaignListPreview from "../whatsapp/components/WhatsappCampaignListPreview";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import Axios from "../../../axios/Axios";
import { FaMessage } from "react-icons/fa6";
import RcsImage from "../../Dashboard/assets/img/rcs.svg-removebg-preview.png";


class CampaignList extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;


    super();
    this.state = {
      campaignList: [],
      user_id: userId,
      token: userToken,
      channel: "all",
      successShow: false,
      successMessage: "",
      bgColor: "",
      startDate: new Date(),
      endDate: new Date(),
      maxDate: null,
      loading: false,
      loadingBtn: false,
      totalData: 0,
      totalCost: 0,
      totalDelivered: 0,
      totalFailed: 0,
      totalRead: 0,
      totalSent: 0,
      totalDND: 0,
      totalOptOut: 0,
      openTemplatePopup: false,
      templateDetails: [],
      route: "",
      openReschedulePopup: false,
      selectedDate: null,
      rescheduleid: '',
      rescheduleName: '',
      deletePopup: false,
      deleteId: '',
      deleteCampaignName: '',
      openAccordion: null,
      fallbackData: '',
      totalWhatsapp: '',
      totalSms: '',
      totalRcs: '',
      search: '',
      numberFilterSearch: false,
      campaignReport: true
    };
    this.channelHandler = this.channelHandler.bind(this);
  }

  componentDidMount() {
    this.getCampaignList("all");
    this.getRoute();
  }

  toggleAccordion = async (id, index) => {
    this.setState({
      openAccordion: this.state.openAccordion === index ? null : index,
    });

    let paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      channel: "sms",
      method: "retrieve_new_refid",
      ref_id: id
    }

    try {
      let { data } = await Axios.post(`https://db2.authkey.io/api/bulk_campaign_sms.php`, paramData);
      if (data.success) {
        // console.log(data);
        this.setState({ fallbackData: data.data[0] })
      }
    } catch (error) {
      console.log(error);
    }
  };

  getRoute = async () => {
    try {
      let response = await fetch(`${HOST_URL}/get_user_route.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`)
      let result = await response.json()
      if (result.success) {
        this.setState({ route: result.data })
      }
    } catch (error) {

    }

  }

  currentDate(currentDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  addDays(date) {
    const days = 30;
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate) {
      let maxDatee = this.addDays(this.state.startDate)
      this.setState({
        maxDate: maxDatee
      })
    }
  }
  getCampaignList(channel) {
    this.setState({ loading: true });
    this.setState({ loadingBtn: true });
    let sDate = this.currentDate(this.state.startDate);
    let eDate = this.currentDate(this.state.endDate);

    let urls = [];
    if (channel === "sms" || channel === "all") {
      urls.push(`${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.user_id}&method=retrieve_new&token=${this.state.token}&channel=sms&from_date=${sDate}&to_date=${eDate}`);
    }
    if (channel === "whatsapp" || channel === "all") {
      urls.push(`${HOST_URL}/bulk_campaign_sms.php?user_id=${this.state.user_id}&method=retrieve_new&token=${this.state.token}&channel=whatsapp&from_date=${sDate}&to_date=${eDate}`);
    }
    if (channel === "rcs" || channel === "all") {
      urls.push(`${NODE_HOST_URL}/rcs?user_id=${this.state.user_id}&method=campaign_report&token=${this.state.token}&from_date=${sDate}&to_date=${eDate}`);
    }

    // Making all API calls
    Promise.all(urls.map(url => fetch(url).then(response => response.json())))
      .then((results) => {
        let totalCost = 0;
        let totaldelivered = 0;
        let totalsent = 0;
        let totalread = 0;
        let totalfailed = 0;
        let contactCount = 0;
        let allData = [];

        results.forEach((result) => {
          if (result.success === true) {
            let data = result?.data?.map((item) => {
              let intValue = parseFloat(item.campaign_cost ? item.campaign_cost : item.block_cr);
              totalCost += intValue;
              totaldelivered += item.delivered ? item.delivered : item.deliver_count;
              totalsent += item.sent ? item.sent : item.sent_count;
              totalread += item.read ? item.read : item.read_count;
              totalfailed += item.failed ? item.failed : item.fail_count;
              contactCount += item.contact_count ? item.contact_count : item.contact_count;
              return { ...item, channel: result.channel || channel, user_token: this.state.token };
            });
            allData = allData.concat(data); // Merging all campaign data
          }
        });

        let totalWhatsapp = allData.filter(x => x.campaign_type === "whatsapp").length;
        let totalSms = allData.filter(x => x.campaign_type === "sms").length;
        let totalRcs = allData.filter(x => x._id).length;

        this.setState({
          campaignList: allData,
          totalData: contactCount,
          totalCost: totalCost,
          totalDelivered: totaldelivered,
          totalFailed: totalfailed,
          totalRead: totalread,
          totalSent: totalsent,
          totalWhatsapp: totalWhatsapp,
          totalSms: totalSms,
          totalRcs: totalRcs,
        });
        this.setState({ loadingBtn: false });
        this.CampaignData(allData);
      })
      .catch((error) => {
        this.setState({
          campaignList: null,
          loadingBtn: false,
        });
        console.error("Error fetching campaign data: ", error);
      });

    this.setState({ loading: false });
  }


  deletecampaignList(id) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result.success);
        if (result.success === true) {
          this.setState({ successShow: true });
          this.setState({ errorMessage: result.message });
          this.setState({ bgColor: "alert alert-success alert-dismissible" });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          this.setState({ successMessage: true });
          this.setState({ errorMessage: result.message });
          this.setState({ bgColor: "alert alert-danger alert-dismissible" });
        }
      });
    });
  }

  channelHandler(e) {
    // console.log(e.target.value);
    this.setState({
      channel: e.target.value,
    });
    // this.getCampaignList(e.target.value);
  }

  // channelHandlerFilterSearch(e) {
  //   // console.log(e.target.value);
  //   this.setState({
  //     channel: e.target.value,
  //   });
  //   // this.searchByNumber();
  // }

  startDateHandler = (date) => {
    this.setState({
      startDate: date,
      // endDate: date,
    });
  };
  endDateHandler = (date) => {
    this.setState({
      endDate: date,
    });
  };

  CampaignData = (data) => {
    let totalOptOut = 0;
    let totalDND = 0;
    let totalCost = 0;


    data.forEach((x) => {
      // console.log(x.template_id.substr(-4, 4));

      totalOptOut += x.optout || 0;
      totalDND += x.dnd || 0;
      totalCost += parseFloat(x.campaign_cost) || 0;
    });

    totalCost = totalCost.toFixed(2);

    this.setState({
      totalCost: totalCost,
      totalDND: totalDND,
      totalOptOut: totalOptOut
    })
  }

  TemplateListDetails = async (templateid) => {
    // Open the popup when starting to fetch template details
    this.setState({ openTemplatePopup: true });

    const data = {
      token: this.state.token,
      user_id: this.state.user_id,
      id: templateid,
      method: "retrieveid",
    };

    try {
      let response = await fetch(`${HOST_URL}/whatsapp_template.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      if (result.success) {
        // Successfully fetched template details
        this.setState({
          templateDetails: result.data, // Update the template details state
          // openTemplatePopup: true, // Ensure the popup is still open
        });
      } else {
        // Handle failure case (optional: you could reset or handle the error)
        this.setState({
          templateDetails: null, // Optional: reset template details on failure
          // openTemplatePopup: false, // Close the popup if fetching failed
        });
      }
    } catch (error) {
      // Handle any network or unexpected errors
      console.log(error);
      this.setState({
        templateDetails: null, // Optional: reset template details on error
        // openTemplatePopup: false, // Close the popup in case of an error
      });
    }
  }

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  DeletecampaignList = async (camp_id) => {
    let data = {
      token: this.state.token,
      user_id: this.state.user_id,
      campaign_id: camp_id,
      method: "schedule_delete",
    }
    try {
      let response = await fetch(`https://napi.authkey.io/api/whatsapp_campaign`, {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      })
      let result = await response.json();
      if (result.success) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  showDeletePopup = (id, name) => {
    this.setState({ deletePopup: true, deleteId: id, deleteCampaignName: name })
  }

  Deletecampaign = async () => {
    const paramDeletedata = {
      token: this.state.token,
      user_id: this.state.user_id,
      campaign_id: this.state.deleteId,
      method: "delete",
    }
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/rcs_campaign`, paramDeletedata);
      if (data.success) {
        this.setState({ deletePopup: false });
        const deletedList = this.state.campaignList.filter(x => x._id !== this.state.deleteId);
        this.setState({ campaignList: deletedList });
      }
    } catch (error) {
      console.log(error)
    }
  }

  showPopup = (id, name, date) => {
    this.setState({ openReschedulePopup: true, rescheduleid: id, rescheduleName: name, selectedDate: date });
  }

  rescheduleCampaign = async () => {
    let paramData = {
      user_id: this.state.user_id,
      token: this.state.token,
      method: "reschedule",
      campaign_id: this.state.rescheduleid,
      schedule_datetime: this.state.selectedDate
    }
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/rcs_campaign`, paramData);
      if (data.success) {
        this.setState({ openReschedulePopup: false });
        this.getCampaignList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  //  filterDate = (date) => {
  //   const currentDate = new Date();
  //   const previousMonth = startOfMonth(addMonths(currentDate, -1)); // Start of last month
  //   const twoMonthsLater = endOfMonth(addMonths(currentDate, 2));
  //   // Disable dates that are in the previous month or outside the two months after the current date
  //   return isAfter(date, previousMonth) && isBefore(date, twoMonthsLater);
  // };

  getMaxDate() {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setMonth(selectedDate.getMonth() + 2); // Add 2 months
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = selectedDate.getDate().toString().padStart(2, '0');
    const hours = selectedDate.getHours().toString().padStart(2, '0');
    const minutes = selectedDate.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  handleChange = (e) => {
    this.setState({ selectedDate: e.target.value });
  }

  searchByNumber = async () => {
    let paramData = {
      method: "number_search_whatsapp",
      fromdate: this.state.startDate,
      todate: this.state.endDate,
      user_id: this.state.user_id,
      token: this.state.token,
      mobile_number: this.state.search
    }
    try {
      let { data } = await Axios.post(`${NODE_HOST_URL}/whatsapp_report`, paramData);
      if (data.success) {
        console.log(data)
      }
    } catch (error) {

    }
  }

  closePopup = () => {
    this.setState({ openTemplatePopup: false })
  }

  render() {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
  

    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          {this.state.loading && <Spining />}
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Campaign </strong> List{" "}
                  </h3>
                </div>
                <div className="col-auto ml-auto text-right mt-n1">
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/campaign-start"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlus} color="" /> Create New
                    Campaign{" "}
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-xxl-5 d-flex">
                  <div className="w-100">
                    {this.state.channel === "all" ?
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Whatsapp</h5>
                              <h6 className="mt-0 mb-0">
                                {this.state.totalWhatsapp || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total SMS</h5>
                              <h6 className="mt-0 mb-0">
                                {this.state.totalSms || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total RCS</h5>
                              <h6 className="mt-0 mb-0">
                                {this.state.totalRcs || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div> :
                      <div className="row">
                        <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Data</h5>
                              <h6 className="mt-0 mb-0">
                                {this?.state?.campaignList ? this.state.totalData : 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Delivered</h5>
                              <h6 className="mt-0 mb-0">
                                {this?.state?.campaignList ? this.state.totalDelivered : 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Failed</h5>
                              <h6 className="mt-0 mb-0">
                                {this?.state?.campaignList ? this.state.totalFailed : 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                        {(this.state.channel === "whatsapp" || this.state.channel === "all") ?
                          <div className="col-sm-2">
                            <div className="card">
                              <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total Read</h5>
                                <h6 className="mt-0 mb-0">
                                  {this.state.totalRead}
                                </h6>
                              </div>
                            </div>
                          </div> : null}
                        {this.state.channel === "sms" || this.state.channel === "all" ? <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Opt Out</h5>
                              <h6 className="mt-0 mb-0">
                                {this.state.totalOptOut}
                              </h6>
                            </div>
                          </div>
                        </div> : null}
                        {this.state.channel === "whatsapp" || this.state.channel === "all" ? <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2">
                              <h5 className="card-title mb-0">Total Sent</h5>
                              <h6 className="mt-0 mb-0">
                                {this.state.totalSent}
                              </h6>
                            </div>
                          </div>
                        </div> : null}
                        {this.state.channel === "sms" || this.state.channel === "all" ?
                          <div className="col-sm-2">
                            <div className="card">
                              <div className="card-body p-2">
                                <h5 className="card-title mb-0">Total DND</h5>
                                <h6 className="mt-0 mb-0">
                                  {this.state.totalDND}
                                </h6>
                              </div>
                            </div>
                          </div> : null}
                        <div className="col-sm-2">
                          <div className="card">
                            <div className="card-body p-2 ">
                              <h5 className="card-title mb-0">Total Cost</h5>
                              <h6 className="mt-0 mb-0">
                                {this?.state?.campaignList ? parseFloat(this.state.totalCost).toFixed(2) : 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>

              {this.state.successShow ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="row justify-content-center">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill ">
                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0 ml-3">
                      <span
                        className="breadcrumb bg-transparent p-0 mt-1 mb-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ campaignReport: !this.state.campaignReport, numberFilterSearch: false, campaignList:[], startDate: new Date(),endDate: new Date() })}
                      >
                        <li className={this.state.campaignReport ? "breadcrumb-item active" : "breadcrumb-item"}>
                          Campaign Report
                        </li>
                      </span>
                      <span className="breadcrumb bg-transparent p-0 mt-1 mb-0" style={{ cursor: "pointer" }} onClick={() => this.setState({ numberFilterSearch: true, campaignReport: false, campaignList: [], startDate: new Date(),endDate: new Date()})}>
                        <li className={this.state.numberFilterSearch ? "breadcrumb-item active" : "breadcrumb-item"}>Number Filter Search</li>
                      </span>
                    </ol>
                    {this.state.campaignReport &&
                      <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <h3 className="mt-2 ml-3">Campaign Report</h3>
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                            <label className="mr-2" style={{ whiteSpace: 'nowrap' }}>Select channel</label>
                            {/* <div className="input-group drop-down-icons mt-2"> */}
                            <select onChange={this.channelHandler}  className="form-control mt-2 ">
                              <option value="all">All</option>
                              <option value="whatsapp">Whatsapp</option>
                              <option value="sms">SMS</option>
                              <option value="rcs">RCS</option>
                            </select>
                            {/* </div> */}
                          </div>
                        </div>

                        <div className="card-header">
                          <div className="row">
                            <div className="col-5">
                              <label>From:</label>
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.startDate}
                                className="form-control ml-2"
                                onChange={(date) => this.startDateHandler(date)}
                                selectsStart
                                startDate={this.state.startDate}
                              />
                            </div>
                            <div className="col-5">
                              <label>To:</label>
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                className="form-control ml-2"
                                selected={this.state.endDate}
                                onChange={(date) => this.endDateHandler(date)}
                                selectsEnd
                                startDate={this.state.startDate}
                                minDate={this.state.startDate}
                                maxDate={this.state.maxDate}
                              />
                            </div>
                            <div className="col-2">
                              {/* <label></label><br/> */}
                              {this.state.loadingBtn ? (
                                <button className="btn btn-success float-right p-2 mr-3" type="button" disabled>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success float-right p-2 mr-3"
                                  onClick={() => this.getCampaignList(this.state.channel)}
                                >
                                  <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                                </button>
                              )}

                            </div>
                          </div>
                          {/* <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <label className="mr-2 mb-0">From:</label>
                        <label className="mr-2 mb-0">To:</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.startDate}
                          className="form-control mr-2"
                          onChange={(date) => this.startDateHandler(date)}
                          selectsStart
                          startDate={this.state.startDate}
                        />
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control mr-2"
                          selected={this.state.endDate}
                          onChange={(date) => this.endDateHandler(date)}
                          selectsEnd
                          startDate={this.state.startDate}
                          minDate={this.state.startDate}
                          maxDate={this.state.maxDate}
                        />
                        <div>
                          {this.state.loadingBtn ? (
                            <button className="btn btn-success p-2" type="button" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success p-2"
                              onClick={() => this.getCampaignList(this.state.channel)}
                            >
                              <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div> */}
                        </div>
                      </>}

                    {this.state.numberFilterSearch &&
                      <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          {/* <h3 className="mt-2 ml-3">Campaign Report</h3> */}
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                            {/* <div className="input-group drop-down-icons mt-2"> */}

                            {/* </div> */}
                          </div>
                        </div>

                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              <label>Select Channel</label>
                              <select onChange={this.channelHandler} className="form-control">
                                <option value="all">All</option>
                                <option value="whatsapp">Whatsapp</option>
                                <option value="sms">SMS</option>
                                <option value="rcs">RCS</option>
                              </select>
                            </div>
                            <div className="col-6">
                              <label>Search Number</label>
                              <input type="text" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} placeholder="Search Number" className="form-control" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            <label>From:</label>
                              <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>

                                <ReactDatePicker
                                  className="form-control btn-block"
                                  dateFormat="dd/MM/yyyy"
                                  // value={fromdate}
                                  onChange={(date) => this.startDateHandler(date)}
                                  selectsStart
                                  startDate={this.state.startDate}
                                  selected={this.state.startDate}
                                  // placeholderText="From Date"
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <label>To:</label>
                              <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>

                                <ReactDatePicker
                                  className="form-control btn-block"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.endDate}
                                  onChange={(date) => this.endDateHandler(date)}
                                  selectsEnd
                                  startDate={this.state.startDate}
                                  minDate={this.state.startDate}
                                  maxDate={this.state.maxDate}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                {/* <button type="button" onClick={this.searchByNumber} className="btn btn-info float-right mt-3">Filter Search</button> */}
                              </div>
                              <div className="col-6">
                                {/* <label></label><br/> */}
                                {this.state.loadingBtn ? (
                                  <button className="btn btn-success float-right p-2 mr-3" type="button" disabled>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-success float-right p-2 mr-3"
                                    // onClick={() => this.getCampaignList(this.state.channel)}
                                    onClick={this.searchByNumber}
                                  >
                                    <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                                  </button>
                                )}

                              </div>
                            </div>
                          </div>
                          {/* <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <label className="mr-2 mb-0">From:</label>
                        <label className="mr-2 mb-0">To:</label>
                      </div>
                      <div className="d-flex align-items-center">
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.startDate}
                          className="form-control mr-2"
                          onChange={(date) => this.startDateHandler(date)}
                          selectsStart
                          startDate={this.state.startDate}
                        />
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control mr-2"
                          selected={this.state.endDate}
                          onChange={(date) => this.endDateHandler(date)}
                          selectsEnd
                          startDate={this.state.startDate}
                          minDate={this.state.startDate}
                          maxDate={this.state.maxDate}
                        />
                        <div>
                          {this.state.loadingBtn ? (
                            <button className="btn btn-success p-2" type="button" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-success p-2"
                              onClick={() => this.getCampaignList(this.state.channel)}
                            >
                              <FontAwesomeIcon className="fa-lg" icon={faSearch} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div> */}
                        </div>
                      </>}



                    {this.state.loading === true ? (
                      <Spining />
                    ) : (
                      <div className="col-md-12">
                        {this.state.campaignList < 1 && (
                          <div className="card d-flex justify-content-center align-items-center ">
                            <h6>No Record Found</h6>
                          </div>
                        )}
                        {this.state.campaignList?.map((item, i) => (
                          <div className="card" key={i}>
                            <div className="card-body">
                              <div className="d-flex  justify-content-between">
                                <p>
                                  {" "}
                                  {
                                    (this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" ?
                                      <IoLogoWhatsapp className="text-success mr-2" style={{ fontSize: '2em' }} /> : null
                                  }

                                  {
                                    (this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ?
                                      <FaSms className="text-primary mr-2" style={{ fontSize: '2em' }} /> : null
                                  }

                                  {
                                    (this.state.channel === "rcs" || this.state.channel === "all") && item._id ?
                                      // <FaMessage className="text-primary mr-2" style={{ fontSize: '2em' }} />
                                      <img alt="Rcs Image" className="text-primary mr-2 rcs-logo-image" style={{ fontSize: '2em' }} src={RcsImage} />

                                      : null
                                  }

                                  Campaign Name:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {item.campaign_name}{" "}
                                  </span>{" "}
                                </p>
                                <p>
                                  {(this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ? (
                                    <>
                                      {
                                        item.status === 2 ? (
                                          <span className="badge bg-warning">
                                            Schedule
                                          </span>
                                        ) : item.status === 4 ? (
                                          <span className="badge bg-danger">
                                            Schedule Deleted
                                          </span>
                                        ) : null
                                      }
                                    </>
                                  ) : (this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" ? (
                                    <>
                                      {item.status === 1 ? (
                                        <span className="badge bg-info">
                                          Submitted
                                        </span>
                                      ) : item.status === 2 ? (
                                        <span className="badge bg-info">
                                          Schedule
                                        </span>
                                      ) : item.status === 3 ? (
                                        <span className="badge bg-success">
                                          Processing
                                        </span>
                                      ) : item.status === 5 ? (
                                        <span className="badge bg-warning">
                                          Schedule
                                        </span>
                                      ) : item.status === 4 ? (
                                        <span className="badge bg-primary ">
                                          Completed
                                        </span>
                                      ) : null}
                                    </>
                                  ) : (this.state.channel === "rcs" || this.state.channel === "all") && item._id ? (
                                    <>
                                      {item.status === 1 ? (
                                        <span className="badge bg-info">
                                          Processing
                                        </span>
                                      ) : item.status === 2 ? (
                                        <span className="badge bg-info">
                                          Schedule
                                        </span>
                                      ) : item.status === 3 ? (
                                        <span className="badge bg-success">
                                          Completed
                                        </span>
                                      ) : item.status === 4 ? (
                                        <span className="badge bg-primary ">
                                          Schedule Deleted
                                        </span>
                                      ) : null}
                                    </>
                                  ) : null}

                                </p>
                                {(this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" && formattedDate < item.camp_start_datetime && (item.status === 5 || item.status === 2) ?
                                  <p>
                                    <span onClick={() => this.DeletecampaignList(item.id)} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                  </p> : null}
                                {(this.state.channel === "rcs" || this.state.channel === "all") && item._id &&
                                  dayjs(item.camp_start_datetime).isAfter(dayjs(formattedDate).add(10, 'minute')) ?
                                  <p>
                                    <span onClick={() => this.showPopup(item._id, item.campaign_name, item.camp_start_datetime)} className="badge bg-warning">
                                      Reschedule
                                    </span>
                                  </p> : null}

                                {(this.state.channel === "rcs" || this.state.channel === "all") && item._id &&
                                  dayjs(item.camp_start_datetime).isAfter(dayjs(formattedDate).add(10, 'minute')) ?
                                  <p>
                                    <span onClick={() => this.showDeletePopup(item._id, item.campaign_name)} className="badge bg-danger">
                                      Delete
                                    </span>
                                  </p> : null}

                                <p>Campaign Date: {dayjs(item.camp_start_datetime).format("DD/MM/YYYY h:mm A")}</p>
                              </div>
                              <hr className="m-0 mb-1"></hr>
                              <div className="d-flex summary-data text-center">
                                <div className="mb-1">
                                  <span className="text-muted"> total </span>{" "}
                                  <br />
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowUp}
                                    color="green"
                                    size="1x"
                                  />{" "} */}
                                    {item.contact_count}{" "}
                                  </span>{" "}
                                </div>

                                <div className="mb-1">
                                  <span className="text-muted">
                                    {" "}
                                    Delivered{" "}
                                  </span>{" "}
                                  <br />
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowUp}
                                    color="green"
                                    size="1x"
                                  />{" "} */}
                                    {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item.deliver_count : item.delivered}{" "}
                                  </span>{" "}
                                </div>

                                <div className="mb-1">
                                  <span className="text-muted"> Failed </span>
                                  <br />{" "}
                                  <span className="textvalue">
                                    {" "}
                                    {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                    {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item.fail_count : item.failed}{" "}
                                  </span>{" "}
                                </div>

                                {(this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ?
                                  (
                                    <>
                                      <div className="mb-1">
                                        <span className="text-muted"> Opt Out </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          {/* <FontAwesomeIcon
                                 icon={faArrowDown}
                                 color="red"
                                 size="1x"
                               />{" "} */}
                                          {item.optout}{" "}
                                        </span>{" "}
                                      </div>

                                      <div className="mb-1">
                                        <span className="text-muted"> DND </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                          {item.dnd}{" "}
                                        </span>{" "}
                                      </div>

                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-1">
                                        <span className="text-muted"> Sent </span>{" "}
                                        <br />
                                        <span className="textvalue">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faArrowUp}
                                            color="green"
                                            size="1x"
                                          />{" "}
                                          {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item.sent_count : item.sent}{" "}
                                        </span>{" "}
                                      </div>

                                      <div className="mb-1">
                                        <span className="text-muted"> Read </span>
                                        <br />{" "}
                                        <span className="textvalue">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faArrowUp}
                                            color="green"
                                            size="1x"
                                          />{" "}
                                          {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item.read_count : item.read}{" "}
                                        </span>{" "}
                                      </div>
                                    </>
                                  )}

                                {this.state.route === 36 && (this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" ?
                                  <div className="mb-1">
                                    <span className="text-muted"> CTA </span>
                                    <br />{" "}
                                    {item.cta_tracking === 0 ?
                                      <span className="textvalue">{item.cta_tracking}</span>
                                      :
                                      <Link
                                        to={`/dashboard/campaign/cta-report/${item.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className=" text-success">
                                          <FontAwesomeIcon icon={faEye} />
                                        </span>
                                      </Link>
                                    }
                                  </div> : null}

                                <>
                                  {item.archive_status === 1 ? null : <div className="mb-1">
                                    <span className="text-muted"> Report </span>
                                    <br />{" "}
                                    <Link
                                      // to={`/dashboard/campaign/detailed-report/${item.channel}/${(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item._id : item.id}/${item.campaign_name}/${item.contact_count}`}
                                      to={`/dashboard/campaign/detailed-report/${item.channel === "all" && item._id ? "rcs" : item.channel === "all" && item.campaign_type === "sms" ? "sms" : item.channel === "all" && !item._id && item.campaign_type !== "sms" ? "whatsapp" : item.channel}/${(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item._id : item.id}/${item.campaign_name}/${item.contact_count}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className=" text-success">
                                        <FontAwesomeIcon icon={faEye} />
                                      </span>
                                    </Link>
                                  </div>}
                                  <div className="mb-1">
                                    <span className="text-muted"> Download </span>
                                    <br />{" "}
                                    <a
                                      href={(this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ? `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${item.user_id}&method=download&token=${item.user_token}&channel=sms&campaign_id=${item.id}&archive_status=${item.archive_status}&camp_start_datetime=${item.camp_start_datetime}` : (this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" ? `${HOST_URL}/bulk_campaign.php?user_id=${item.user_id}&method=download&campaign_id=${item.id}&channel=whatsapp&token=${item.user_token}` : (this.state.channel === "rcs" || this.state.channel === "all") && item._id ? `${NODE_HOST_URL}/rcs?user_id=${item.user_id}&token=${item.user_token}&method=campaign_report_download&campaign_id=${item._id}` : null}
                                      className="text-success"
                                    >
                                      <FontAwesomeIcon icon={faDownload} />{" "}
                                    </a>
                                  </div>
                                </>
                              </div>
                              <div className="d-flex justify-content-between mt-1">
                                <div className="d-flex">
                                  <small className="m-0, p-0">
                                    Campaign ID: {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item._id : item.id}/
                                  </small>
                                  {/* {this.state.channel === "sms" ? <small>Template ID:  xxxxxxxx{item?.template_id?.substr(-4,4)}/</small> :
                                  <small>Template ID: {item?.template_id}/</small>} */}
                                  {(this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ? <small>Template ID: {item?.template_id}/</small> :
                                    (this.state.channel === "rcs" || this.state.channel === "all") && item._id ? <small>Template ID: {item?.template_id}</small> :
                                      <small>
                                        Template ID: {item?.template_id}
                                      </small>}

                                  {(this.state.channel === "whatsapp" || this.state.channel === "all") && item.campaign_type === "whatsapp" ?
                                    <small
                                      style={{
                                        cursor: 'pointer',
                                        backgroundColor: this.state.openTemplatePopup ? '#f0f0f0' : 'transparent',
                                      }}
                                      className="ml-2"
                                      onClick={() => this.TemplateListDetails(item?.template_id)}>
                                      <FontAwesomeIcon icon={faInfoCircle} /></small> : null}
                                  {(this.state.channel === "sms" || this.state.channel === "all") && item.campaign_type === "sms" ? <small>Sender ID: {item.sender_id}</small> : null}
                                </div>
                                <small>
                                  Campaign Cost:{" "}
                                  {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? parseFloat(item.block_cr).toFixed(2) : parseFloat(item.campaign_cost).toFixed(2)}
                                </small>
                              </div>
                              {(this.state.channel === "rcs" || this.state.channel === "all") && item._id && item.is_fallback === 1 ?
                                <>
                                  <div className="row my-3">
                                    <div className="col-6">
                                      <span onClick={() => this.toggleAccordion(item._id, i)} className="badge bg-info">
                                        Fallback 1
                                      </span>
                                      <span className="ml-3">Channel : <b>SMS</b></span>
                                    </div>
                                    <div className="container">

                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* <td colSpan="12"> */}
                                    <div
                                      id={`collapseRow${i}`}
                                      className={`accordion-collapse collapse ${this.state.openAccordion === i ? 'show' : ''}`}
                                      aria-labelledby={`headingRow${i}`}
                                    >
                                      <div className="accordion-body px-3 w-100">
                                        <hr className="m-0 mb-1"></hr>
                                        <div className="d-flex summary-data text-center">
                                          <div className="mb-1">
                                            <span className="text-muted"> total </span>{" "}
                                            <br />
                                            <span className="textvalue">
                                              {" "}
                                              {/* <FontAwesomeIcon
                                    icon={faArrowUp}
                                    color="green"
                                    size="1x"
                                  />{" "} */}
                                              {this.state.fallbackData.contact_count || 0}{" "}
                                            </span>{" "}
                                          </div>

                                          <div className="mb-1">
                                            <span className="text-muted">
                                              {" "}
                                              Delivered{" "}
                                            </span>{" "}
                                            <br />
                                            <span className="textvalue">
                                              {" "}
                                              {/* {(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item.deliver_count : item.delivered}{" "} */}
                                              {this.state.fallbackData.delivered || 0}
                                            </span>{" "}
                                          </div>

                                          <div className="mb-1">
                                            <span className="text-muted"> Failed </span>
                                            <br />{" "}
                                            <span className="textvalue">
                                              {" "}
                                              {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                              {this.state.fallbackData.failed || 0}
                                            </span>{" "}
                                          </div>

                                          <div className="mb-1">
                                            <span className="text-muted"> Opt Out </span>
                                            <br />{" "}
                                            <span className="textvalue">
                                              {" "}
                                              {/* <FontAwesomeIcon
                                 icon={faArrowDown}
                                 color="red"
                                 size="1x"
                               />{" "} */}
                                              {/* {item.optout}{" "} */}
                                              {this.state.fallbackData.optout || 0}
                                            </span>{" "}
                                          </div>

                                          <div className="mb-1">
                                            <span className="text-muted"> DND </span>
                                            <br />{" "}
                                            <span className="textvalue">
                                              {" "}
                                              {/* <FontAwesomeIcon
                                    icon={faArrowDown}
                                    color="red"
                                    size="1x"
                                  />{" "} */}
                                              {this.state.fallbackData.dnd || 0}
                                            </span>{" "}
                                          </div>

                                          <>
                                            <div className="mb-1">
                                              <span className="text-muted"> Report </span>
                                              <br />{" "}
                                              <Link
                                                to={`/dashboard/campaign/detailed-report/${this.state.fallbackData.campaign_type}/${this.state.fallbackData.id}/${this.state.fallbackData.campaign_name}/${this.state.fallbackData.contact_count}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <span className=" text-success">
                                                  <FontAwesomeIcon icon={faEye} />
                                                </span>
                                              </Link>
                                            </div>
                                            <div className="mb-1">
                                              <span className="text-muted"> Download </span>
                                              <br />{" "}
                                              <a
                                                href={`${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.fallbackData.user_id}&method=download&token=${this.state.token}&channel=sms&campaign_id=${this.state.fallbackData.id}&archive_status=${this.state.fallbackData.archive_status}&camp_start_datetime=${this.state.fallbackData.camp_start_datetime}`}
                                                className="text-success"
                                              >
                                                <FontAwesomeIcon icon={faDownload} />{" "}
                                              </a>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </td> */}
                                  </div></> : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <WhatsAppCampaignListPreview templateDetails={this.state.templateDetails} openTemplatePopup={this.state.openTemplatePopup} closePopup={this.closePopup} />

                    {/* <AdvanceDataTable
                      tableName={""}
                      tableCell={WHATSAPP_CAMPAIGN_LIST_CONST}
                      tableData={this.state.campaignList}
                      isLoading={this.state.loading}
                      isError={this.state.ErrorResponse}
                    /> */}

                    {/* {this.state.campaignList ? (
                      <table className="table table-hover text-left  project  my-0">
                        <thead>
                          <tr>
                            <th>Campaign ID</th>
                            <th>Campaign Name</th>
                            {this.state.channel === "whatsapp" && (
                              <th> Template ID </th>
                            )}
                            <th>Total Campaign Data</th>
                            <th>Campaign Start On</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filterCampaignList.map((itam, i) => (
                            <tr key={i}>
                              <td>{itam.id}</td>
                              <td>{itam.campaign_name}</td>
                              {this.state.channel === "whatsapp" && (
                                <td>{itam.template_id}</td>
                              )}

                              <td>
                                <span className="badge bg-primary">
                                  {itam.contact_count}
                                </span>
                              </td>
                              <td> {itam.camp_start_datetime} </td>
                              <td>
                                {" "}
                                {itam.status === 1 ? (
                                  <span className="badge bg-info">
                                    Submitted
                                  </span>
                                ) : itam.status === 2 ? (
                                  <span className="badge bg-info">
                                    Schedule
                                  </span>
                                ) : itam.status === 3 ? (
                                  <span className="badge bg-success">
                                    Processing
                                  </span>
                                ) : itam.status === 5 ? (
                                  <span className="badge bg-warning">
                                    Schedule{" "}
                                  </span>
                                ) : (
                                  <span className="badge bg-primary ">
                                    Completed
                                  </span>
                                )}{" "}
                              </td>
                              <td>
                                {itam.status === 5 ? null : (
                                  <a
                                    href={`/dashboard/campaign/summary-report/${
                                      this.state.channel
                                    }/${itam.id}/${encodeURIComponent(
                                      itam.campaign_name
                                    )}`}
                                  >
                                    <span className="badge bg-success">
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faEye}
                                      /> Report{" "}
                                    </span>
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center pb-5">
                        <div className="card-body">
                          <img
                            alt="Empty Data"
                            className="project-empty-img"
                            src={EmptyImg}
                          />
                          <p>No Campaign Yet</p>
                          <Link
                            className="btn  btn-success btn-sm"
                            to="/dashboard/campaign-start"
                          >
                            Create New Campaign
                          </Link>
                        </div>
                      </div>
                    )} */}
                  </div>

                  {this.state.openReschedulePopup && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reschedule Campaign</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ openReschedulePopup: false })}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="textField1">Campaign ID</label>
                                    <input type="text" value={this.state.rescheduleid} className="form-control" id="textField1" disabled={true} placeholder="Enter text" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="textField2">Campaign Name</label>
                                    <input type="text" value={this.state.rescheduleName} disabled={true} className="form-control" id="textField2" placeholder="Enter text" />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label htmlFor="datePicker">Select Date</label>
                                    <div className="input-group">
                                      <div className="input-group-text">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                      </div>
                                      <input
                                        type="datetime-local"
                                        className="form-control"
                                        value={this.state.selectedDate}
                                        onChange={this.handleChange}
                                        min={this.state.selectedDate}
                                        max={this.state.selectedDate ? this.getMaxDate() : ''}
                                        placeholder="Example: Date For Reschedule Campaign"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.setState({ openReschedulePopup: false })}>
                              Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={this.rescheduleCampaign}>Save Changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.deletePopup && (
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.state.deleteCampaignName}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ deletePopup: false })}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h6 style={{ color: "red" }}>Are You Sure you Want to Delete <b style={{ color: "black" }}>{this.state.deleteCampaignName}</b> Campaign .</h6>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-info" data-dismiss="modal" onClick={() => this.setState({ deletePopup: false })}>
                              No
                            </button>
                            <button type="button" className="btn btn-danger" onClick={() => this.Deletecampaign()}>Yes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                </div>
              </div>
            </div>
          </main>

          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default CampaignList;
