import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Spining from "../../UI/Spining/Spining";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_RCS_SETTING_LIST } from "../../UI/Table/ColumnConst";
import Axios from "../../../axios/Axios";
import { HOST_URL, NODE_HOST_URL } from "../../Dashboard/api/Api";
import { userloginDetails } from "../../Utils/AdminAuth";
import ReactDatePicker from "react-datepicker";
import { faCalendarAlt, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";

const RcsAdminCampaignReport = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    const [loading, setLoading] = useState(false);
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [listData, setListData] = useState([]);
    const [allusers, setAllusers] = useState([{ value: "", label: "All Users" }]);
    const [selecteduser, setSeletedusers] = useState("");

    const customStyles = {
        control: (provided) => ({
            ...provided,
            cursor: "pointer",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (style) => ({
            ...style,
            fontSize: "12px",
        }),
    };

    useEffect(() => {
        fetchRcsCampaignReport();
        usersList();
    }, []);

    const fromdateHandler = (data) => {
        setFromdate(data);
    };

    const todateHandler = (data) => {
        setTodate(data);
    };

    const userHandler = (e) => {
        setSeletedusers(e.value);
    };

    const currentDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const usersList = () => {
        setLoading(true);
        fetch(
            `${HOST_URL}/admin_user_list.php?user_id=${userId}&method=retrieve_whatsapp_user&type=${adminType}&token=${userToken}`
        ).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    let data = result.data.filter((items) => {
                        if (items.status === 1) {
                            return items;
                        }
                    });

                    data.forEach((item) => {
                        let newuserData = { value: item.user_id, label: item.user_name };
                        setAllusers((prevState) => [...prevState, newuserData]);
                    });
                }
                // else {
                //   setAlert({
                //     status: true,
                //     bgColor: "danger",
                //     errorMessage: result.message,
                //   });
                // }
            });
        });
        setLoading(false);
    };

    const fetchRcsCampaignReport = async () => {
        setLoading(true);
        try {
            const dataForList = {
                user_id: userId,
                token: userToken,
                fromdate: currentDate(fromdate),
                todate: currentDate(todate),
                method: "admin_rcs_camp",
                retr_user_id: selecteduser
            };
            const { data } = await Axios.post(
                `${NODE_HOST_URL}/admin/rcs`,
                dataForList
            );
            if (data.success === true) {
                console.log(data.data)
                setListData(data.data);
            }
        } catch (error) {
            console.log(error.message);
        }
        setLoading(false)
    };

    const processContent = (text) => {
        return text.split(',' || '.').map(part => `<div>${part.trim()}</div>`).join('');
    };

    // console.log(selecteduser)
    return (
        <>
            {loading && <Spining />}
            <AdminLayout>
                <div className="row">
                    <div className="col-3">
                        <h6>RCS Campaign Report</h6>
                    </div>
                    <div className="col-9 mb-2">
                        <Link
                            type="button"
                            style={{
                                float: "right",
                                fontSize: "12px",
                                padding: "10px 10px 10px 10px",
                            }}
                            to={"/admin/rcs/rcs-setting-add"}
                            className="btn btn-success"
                        >
                            Add new
                        </Link>
                    </div>
                </div>

                {/* <AdvanceDataTable
          tableCell={ADMIN_RCS_SETTING_LIST}
          tableData={listData}
          isLoading={loading}
        /> */}

                <div className="card">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group drop-down-icons mt-3 ml-4 mr-sm-2">
                                <div className="input-group mb-2 mr-sm-2">
                                    {/* <div className="input-group-text">User </div> */}
                                    <Select
                                        onChange={userHandler}
                                        options={allusers}
                                        isSearchable={true}
                                        placeholder="All Users"
                                        styles={customStyles}

                                    // isClearable
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-3">
                        <div className="col-md-5">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>

                                <ReactDatePicker
                                    className="form-control btn-block"
                                    dateFormat="dd/MM/yyyy"
                                    value={fromdate}
                                    onChange={fromdateHandler}
                                    selected={fromdate}
                                    placeholderText="From Date"
                                />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                                <ReactDatePicker
                                    className="form-control btn-block"
                                    dateFormat="dd/MM/yyyy"
                                    value={todate}
                                    onChange={todateHandler}
                                    selected={todate}
                                    placeholderText="Date To"
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button type="button" onClick={fetchRcsCampaignReport} className="btn btn-sm btn-info float-right">Search</button>
                        </div>

                    </div>
                </div>

                <div className="">
                    <div className="">
                        <div className="p-2">
                            <div className="">
                                <div className=" flex-fill">
                                    {listData.length > 0 ?
                                        <div className="table-responsive">
                                            <table className="table table-striped table-sm">
                                                <thead>
                                                    <tr>
                                                        <th className=" d-xl-table-cell"> Username </th>
                                                        <th className=" d-xl-table-cell"> Country Code </th>
                                                        <th className=" d-md-table-cell">Message</th>
                                                        <th className=" d-md-table-cell">Status</th>
                                                        {/* <th className=" d-md-table-cell">Short Url</th>
                                                    <th className=" d-md-table-cell">Main Url</th> */}
                                                        <th className=" d-xl-table-cell">Created Date / Time </th>
                                                        <th className=" d-md-table-cell">Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listData.map((item, i) =>
                                                            <tr key={i}>
                                                                {/* <td>
                                                                    <span onClick={() => EditUrlID(item._id, item.channel, item.status, item.main_url)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>
                                                                    <span onClick={() => Delete(item._id)} className="badge bg-danger">
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </span>
                                                                </td> */}
                                                                <td className=" d-xl-table-cell">{item.user_name}</td>
                                                                <td className=" d-xl-table-cell">{item.country_code}</td>
                                                                {/* <td className=" d-xl-table-cell">{item.status === 0 ? <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "green" }}>Active</span>}</td> */}
                                                                <td>
                                                                    <div
                                                                        className="break-after-comma"
                                                                        style={{
                                                                            fontSize: "12px",
                                                                            whiteSpace: "normal",
                                                                            wordWrap: "break-word",
                                                                            overflowWrap: "break-word",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{ __html: processContent(item.message) }}
                                                                    />
                                                                </td>
                                                                <td className=" d-xl-table-cell">{item.status}</td>

                                                                {/* <td className=" d-xl-table-cell">{item.main_url}</td> */}
                                                                <td className=" d-md-table-cell">{item.created_date}</td>
                                                                <td>
                                                                    <Link
                                                                        // to={`/dashboard/campaign/detailed-report/${item.channel}/${(this.state.channel === "rcs" || this.state.channel === "all") && item._id ? item._id : item.id}/${item.campaign_name}/${item.contact_count}`}
                                                                        className = 'mr-2'
                                                                        to={`/admin/campaign/detailed-report/rcs/${item._id}/${item.campaign_name}/${item.contact_count}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <span className=" text-success">
                                                                            <FontAwesomeIcon icon={faEye} />
                                                                        </span>
                                                                    </Link>
                                                                    {/* <span style={{ cursor: 'pointer' }} className="mr-2" onClick={() => this.userDisabled(item.is_active, item.id)}>
                                                                    {
                                                                        item.is_active === 1 ?
                                                                            <FontAwesomeIcon color="green" icon={faUserLock} />
                                                                            :
                                                                            <FontAwesomeIcon color="red" icon={faUserLock} />
                                                                    }
                                                                </span> */}
                                                                    <span style={{ cursor: 'pointer' }}  >

                                                                        <a
                                                                            // type="button"
                                                                            // className="btn btn-primary float-right ml-3"
                                                                            // style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "45px" }}
                                                                            href={` ${NODE_HOST_URL}/rcs?user_id=${userId}&token=${userToken}&method=campaign_report_download&campaign_id=${item._id}`}
                                                                        >
                                                                            <FontAwesomeIcon icon={faDownload} color="green" />
                                                                        </a>
                                                                    </span>

                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table> </div> :
                                        <div className="text-center">
                                            <div className="card-body">
                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                <p>No Entity ID Yet</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default RcsAdminCampaignReport;
