import React, { Component } from "react";
import DashboardHeader from "../../../Header/DashboardHeader";
import DashboardFooter from "../../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "react-bootstrap-tabs";
import Select from "react-select";
import {
    faBroadcastTower,
    faArrowCircleLeft,
    faAddressBook,
    faCheckCircle,
    faFlag,
    faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../../UI/Alert/Alert";
import Spining from "../../../UI/Spining/Spining";
import { CAMPAIGN_CONTACT_LIST_API } from "../../../../constants/ServiceConstants";
import api from "../../../../axios/Axios";
import { Link } from "react-router-dom";
import { no_data } from "../../../../images/WhatsAppImages";
import {
    campaignContactList,
    getUserPriceList,
    contactListAPI,
    NODE_HOST_URL,
} from "../../api/Api";
import { Helmet } from "react-helmet";
import UploadCSVFile from "../component/UploadCSVFile";
import { withRouter } from 'react-router-dom';
import Axios from "../../../../axios/Axios";
import style from './rcscampaign.module.css'
import RcsPreview from "./RcsPreview";
import DownloadButton from "./SampleCsv";
import axios from 'axios';
import UploadExcelFile from "../component/UploadExcelFile";
import RcsFallback from "./RcsFallback";
import { FaPlus } from "react-icons/fa";
class RcsCampaign extends Component {
    constructor(props) {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const userName = lStorage.user.username
        var today = new Date(),
            date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                ("0" + today.getDate()).slice(-2);
        super(props);
        this.state = {
            user_id: userId,
            userName: userName,
            token: userToken,
            contactList: "",
            step: 0, // TODO 0
            templatePreView: "",
            previewData: "",
            sampleData: "",
            WhatsappTemplateList: "",
            rcsTemplateList: "",
            schedule_datetime: date,
            is_schedule: 0,
            parameter: [],
            mobile_colum: "",
            tableColume: [],
            countryList: "",
            varibaleInTemplate: "",
            CampaignName: "",
            channel: "rcs",
            templteId: "",
            templateName: "",
            listId: "",
            is_flash: "",
            rcsmessage: [],
            mainMsg: '',
            country_code: 91,
            campaignType: "1",
            textnumbers: "",
            smsTemplateDetails: null,
            AllowLongMsg: false,
            selectedListName: "",
            totalCount: "",
            isLoading: false,
            uploadProgress: 0,
            errorMessage: "",
            isError: false,
            messageType: "",
            is_unicode: false,
            allow_Long_Msg: false,
            flash_message: false,
            language: "",
            numbersArray: [],
            urlPath: window.location,
            fileData: "",
            countData: "",
            contactOption: [],
            paramData: [],
            selectedValues: '',
            bodyValue: '',
            bodyVar: [],
            bodyValueObject: {},
            rcsCountryCode: [],
            selectedOption: null,
            dynamicSampleData: [],
            csvHeaders: [],
            filteredHeaders: [],
            csvRows: [],
            variableMapping: {},
            previews: [],
            cardDataArray: [],
            cardDynamicDataArray: [{
                media: null,
                card_title: '',
                card_description: '',
                temp_suggestion: []
            }],
            selectedMobileColumn: "",
            priority1Data: {
                templateId: '',
                smsTemplateList: [],
                smsTemplateDetails: null,
                SMSmessage: '',
                templateName: '',
                templatePreView: null,
                paramData: [],
                bodyVariables: [],
                bodyValue: [],
                previewMessage: '',
                channel: '',
                condition: [],
                paramMapping: null,
                status: false,
                paramFlags: []
            },
            priority2Data: {
                templateId: '',
                smsTemplateList: [],
                smsTemplateDetails: null,
                SMSmessage: '',
                templateName: '',
                templatePreView: null,
                paramData: [],
                bodyVariables: [],
                bodyValue: [],
                previewMessage: '',
                channel: '',
                condition: [],
                paramMapping: null,
                status: false,
                paramFlags: []
            },
            showForm1: false,
            showForm2: false,


        };
        this.nextStep = this.nextStep.bind(this);
        this.findAndReplace = this.findAndReplace.bind(this);
        // this.preViewSMSHandler = this.preViewSMSHandler.bind(this);
        this.getCsvData = this.getCsvData.bind(this);
        this.scheduleHandler = this.scheduleHandler.bind(this);
        this.handleChannel = this.handleChannel.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    componentDidMount() {
        this.getContactList();
        this.getRCSTemplateList();
        // this.getcountryList();
        this.countryCode();


    }

       handleAddForm = () => {
        this.setState((prevState) => {
            if (!prevState.showForm1) {
                return { showForm1: true };
            }
            // else if (!prevState.showForm2) {
            //     return { showForm2: true };
            // }
            return null; // Maximum 2 forms allowed
        });
    };

    isDynamic = (priorityType) => {
        const { priority1Data, priority2Data } = this.state;

        let isDynamicFlag = false;

        if (priorityType === 'priority1') {
            isDynamicFlag = priority1Data.paramFlags?.some(flag => Object.values(flag)[0] === true);
        } else if (priorityType === 'priority2') {
            isDynamicFlag = priority2Data.paramFlags?.some(flag => Object.values(flag)[0] === true);
        }

        return isDynamicFlag ? 1 : 0; // Return 1 if dynamic, otherwise 0
    };




    mapParamsToState = (paramData, paramValue) => {
       
        if (!Array.isArray(paramData) || !Array.isArray(paramValue)) {
            console.error("paramData and paramValue must be arrays.");
            return {};
        }
        const processedParamValue = paramValue.map(value => value.replace(/\[|\]/g, ''));
        // Map the two arrays into an object
        return paramData.reduce((acc, key, idx) => {
            acc[key] = processedParamValue[idx] !== undefined ? processedParamValue[idx] : null; // Default to null for missing values
            return acc;
        }, {});
    };

    // Handler to update priority1Data
    setPriority1Data = (updatedData) => {
        this.setState({ priority1Data: { ...this.state.priority1Data, ...updatedData } });
    };

    // Handler to update priority2Data
    setPriority2Data = (updatedData) => {
        this.setState({ priority2Data: { ...this.state.priority2Data, ...updatedData } });
    };


    getRCSTemplateList = async () => {
        const param = {
            user_id: this.state.user_id,
            token: this.state.token,
            method: "retrieve_temp",
        }
        try {
            const { data } = await Axios.post(`${NODE_HOST_URL}/rcs`, param)
            if (data.success === true) {
                let filterData = data.data.filter(x => x.temp_status === 1)
                this.setState({
                    rcsTemplateList: filterData
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    getcountryList() {
        getUserPriceList().then((result) => {
            if (result.data.success === true) {
                this.setState({ countryList: result.data });
            }
            //  else {
            //     this.setState({
            //         isError: true,
            //         messageType: "alert alert-danger alert-dismissible",
            //         errorMessage: result.data.message,
            //     });
            // }
        });
    }

    isValid = () => {
        if (
            this.state.CampaignName === undefined ||
            this.state.CampaignName === ""
        ) {
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Please Enter Campaign Name",
                isLoading: false,
            });
            return false;
        }
        return true;
    };

    nextStep = async () => {
        if (this.isValid()) {
            this.setState({
                step: this.state.step + 1,
            });
        }
    };

    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
        this.setState({
            isError: false,
            messageType: "",
            errorMessage: "",
        });
    };

    getContactList = async () => {
        contactListAPI("retrieve").then((res) => {
            if (res.data.success === true) {
                this.setState({
                    contactList: res.data.data,
                    isLoading: false,
                });
                // console.log(res.data.data);
                let ctList = res.data.data;
                ctList.forEach((item) => {
                    let newCtData = { value: item.id, label: `${item.name}(${item.number_count})`, type: item.list_type };
                    this.setState((prevState) => ({
                        contactOption: [...prevState.contactOption, newCtData],
                    }));
                });
            } else {
                this.setState({ ErrorResponse: res.data, isLoading: false });
            }
        });
    };

    createCampaign = async () => {
        this.setState({ isLoading: true, uploadProgress: 0 });
        const newArr = [];
        newArr.push({ id: this?.state?.selectedOption?.value, type: this?.state?.selectedOption?.type });
        let updatedObject = {}
        if (this.state.campaignType === '3' && this.state.variableMapping) {
            updatedObject = Object.fromEntries(
                Object.entries(this.state.variableMapping).map(([key, value]) => {
                    const cleanedKey = key.replace(/[\[\]]/g, ""); // Removes [ and ]
                    return [cleanedKey, `#${value}#`];

                }
                )
            )
        }

        let createRCSCampaignData = {
            token: this.state.token,
            user_id: this.state.user_id,
            method: "create_campaign",
            temp_id: this.state.smsTemplateDetails[0].api_tempid,
            user_name: this.state.userName,
            camp_name: this.state.CampaignName,
            message_type: this.state.campaignType,
            message: this.state.mainMsg,
            country_code: this.state.country_code,
            textnumbers: this.state.textnumbers,
            schedule_datetime: this.state.schedule_datetime,
            is_schedule: this.state.is_schedule,
            is_unicode: this.state.is_unicode === true ? 1 : 0,
            temp_id: this.state.smsTemplateDetails[0].api_tempid,
            sender_id: this.state.smsTemplateDetails[0].sender_id,
            dynamic_content: this.state.bodyValueObject ? 1 : 0,
            parameters: this.state.campaignType === '1' ? (this.state.bodyValueObject ? JSON.stringify(this.state.bodyValueObject) : "") : (JSON.stringify(updatedObject) || ''),
            mobile_column: this.state.campaignType === "1" ? "Mobile" : this.state.selectedMobileColumn,
            contact_list: this.state.selectedOption ? newArr : [],
            file: this.state.fileData,
            pannel_temp_id: this.state.templteId,

            fb_condition: [{
                condition: this.state.priority1Data.condition,
                channel: this.state.priority1Data.channel,
                templateId: this.state.priority1Data.templateId,
                paramMapping: this.mapParamsToState(this.state.priority1Data.paramData, this.state.priority1Data.bodyValue),
                is_dynamic: this.isDynamic('priority1'),
            }, {
                condition: this.state.priority2Data.condition,
                channel: this.state.priority2Data.channel,
                templateId: this.state.priority2Data.templateId,
                paramMapping: this.mapParamsToState(this.state.priority2Data.paramData, this.state.priority2Data.bodyValue),
                is_dynamic: this.isDynamic('priority2')
            }],
            is_fallback: (this.state.priority1Data.status || this.state.priority2Data.status) ? 1 : 0
            // channel: this.state.channel,           
            // list_id: this.state.listId,        
            // total_count: this.state.totalCount,           
            // entity_id: this.state.smsTemplateDetails[0].entity_id,
            // template_id: this.state.smsTemplateDetails[0].template_id,           
            // language: this.state.language,          
            // allow_Long_Msg: this.state.allow_Long_Msg === true ? 1 : 0,
            // flash_message: this.state.flash_message === true ? 1 : 0,          
            // uploadcsv: this.state.fileData,

            // file: this.state.fileData, // Uncomment if you need to include file data
            // additional keys...
        };
        // Create a FormData object
        const formData = new FormData();

        // Append each key-value pair in createRCSCampaignData to formData
        Object.keys(createRCSCampaignData).forEach(key => {
            const value = createRCSCampaignData[key];

            if (Array.isArray(value)) {
                // Handle array type (e.g., contact_list)
                formData.append(key, JSON.stringify(value)); // Convert array to JSON string
            } else if (value instanceof File) {
                // Handle file (in case of uploadcsv or any file type)
                formData.append(key, value);
            } else {
                // Append normal key-value pair
                formData.append(key, value);
            }
        });


        try {
            // Send the POST request with formData as the body

            const response = await axios.post(`${NODE_HOST_URL}/rcs_campaign`, formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({ uploadProgress: progress }); // Update progress in state
                },
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type for form data
                },
            });

            const result = response.data;

            if (result.success) {
                this.setState({
                    isError: true,
                    messageType: "alert-success",
                    errorMessage: "Campaign created successfully",
                    isLoading: false,

                });
                setTimeout(() => {
                    window.location = "/dashboard/campaign";
                }, 3000);
            } else {
                this.setState({
                    isError: true,
                    messageType: "alert-danger",
                    errorMessage: result.message,
                    isLoading: false,
                });
            }
        } catch (error) {
            console.error('Error uploading campaign:', error);
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "An error occurred while creating the campaign.",
                isLoading: false,
            });
        }
        finally {
            this.setState({
                uploadProgress: 0
            })
        }
    };



    selectRCSTemplate = async (e) => {
        const { value, label } = e;
        this.setState({ templteId: value });
        this.setState({ templateName: label });
        this.setState({ bodyValue: '' })
        this.setState({
            cardDynamicDataArray: [{
                media: null,
                card_title: '',
                card_description: '',
                temp_suggestion: []
            }]
        })

        const filterTemplate = this.state.rcsTemplateList.filter(x => x._id === value);
        if (filterTemplate[0].temp_type === "text") {
            this.setState({
                smsTemplateDetails: filterTemplate,
                rcsmessage: filterTemplate[0].temp_body,
                templatePreView: filterTemplate[0],
                mainMsg: filterTemplate[0].temp_body,
            });
        } else if (filterTemplate[0].temp_type === "richcardstandalone") {
            this.setState((prevState) => ({
                smsTemplateDetails: filterTemplate,
                templatePreView: filterTemplate[0],
                cardDataArray: filterTemplate[0].rich_card,
                cardDynamicDataArray: prevState.cardDynamicDataArray.map((item, index) =>
                    index === 0
                        ? { ...item, media: filterTemplate[0].rich_card[0]?.media, temp_suggestion: filterTemplate[0].rich_card[0]?.temp_suggestion }
                        : item
                ),
            }));


        } else {
            this.setState(() => {
                const richCards = filterTemplate[0].rich_card || []; // Ensure `rich_card` is an array
                const cardDynamicDataArray = richCards.map((card) => ({
                    media: card.media || null, // Extract the media field
                    card_title: '',           // Empty title
                    card_description: '',     // Empty description
                    temp_suggestion: []       // Empty temp_suggestion
                }));

                return {
                    smsTemplateDetails: filterTemplate,
                    templatePreView: filterTemplate[0],
                    cardDataArray: richCards, // Keep all rich_card items
                    cardDynamicDataArray,     // Dynamically generated array
                };
            });

        }

        if (this.state.previews.length !== 0) {
            this.setState({
                previews: [],
                variableMapping: {},

            })
        }
        if (filterTemplate[0].temp_type === "text") {
            const bodyVariables = filterTemplate[0].temp_body.match(/\[([a-zA-Z0-9_]+)\]/g);

            this.setState({ bodyVar: bodyVariables })
            this.extractData(filterTemplate[0].temp_body);
        } else if (filterTemplate[0].temp_type === "richcardstandalone") {
            const firstRichCard = filterTemplate[0].rich_card[0];
            const bodyVariables = firstRichCard?.card_description.match(/\[([a-zA-Z0-9_]+)\]/g);
            const titleVariables = firstRichCard?.card_title.match(/\[([a-zA-Z0-9_]+)\]/g);
            this.setState({ bodyVar: [...bodyVariables, ...titleVariables] })
            this.extractData(firstRichCard?.card_title + firstRichCard?.card_description);

        } else {
            const cards = filterTemplate[0].rich_card || [];
            let allBodyVariables = [];
            let allTitleVariables = [];

            cards.forEach((card) => {
                const bodyVariables = card.card_description?.match(/\[([a-zA-Z0-9_]+)\]/g) || [];
                const titleVariables = card.card_title?.match(/\[([a-zA-Z0-9_]+)\]/g) || [];

                allBodyVariables = [...allBodyVariables, ...bodyVariables];
                allTitleVariables = [...allTitleVariables, ...titleVariables];
            });

            this.setState({ bodyVar: [...allBodyVariables, ...allTitleVariables] });

            // Concatenate all card titles and descriptions for extraction
            const concatenatedData = cards
                .map((card) => card.card_title + card.card_description)
                .join(" ");
            this.extractData(concatenatedData);

        }
        // getCampaignSMSTemplateList("retrieveid", e.target.value).then((res) => {
        //     if (res.data.success === true) {
        //         this.setState({
        //             isError: false,
        //             smsTemplateDetails: res.data.data,
        //             SMSmessage: res.data.data[0].content,
        //             templatePreView: res.data.data[0],
        //             mainMsg: res.data.data[0].content,
        //         });
        //         console.log(res.data.data[0])
        //         const bodyVariables = res.data.data[0].content.match(/\{\#.*?\#\}/g);
        //         this.setState({bodyVar : bodyVariables})

        //         this.isUnicode(); // to check language
        //         // this.getVariable(res.data.data[0].content); // to get parameter
        //         this.extractData(res.data.data[0].content);
        //     } else {
        //         this.setState({
        //             isError: true,
        //             messageType: "alert-danger",
        //             errorMessage: res.data.message,
        //             isLoading: false,
        //         });
        //     }
        // });
    };



    extractData = (data) => {
        const pattern = /\[([a-zA-Z0-9_]+)\]/g;
        const matches = [];
        let match;
        while ((match = pattern.exec(data)) !== null) {
            matches.push(match[1]);
        }
        this.setState({ paramData: matches })
        return matches;
    };

    getVariable = (data) => {
        var regex = /{{([^}]+)}}/g;
        let m;
        var tempArr = ["mobile"];
        var parameter = { mobile: "" };
        while ((m = regex.exec(data)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            parameter[`${m[1]}`] = "";
            tempArr.push(m[1]);
        }
        this.setState({
            varibaleInTemplate: tempArr,
            parameter: [...this.state.parameter, parameter],
        });
    };

    setParameter = (e) => {
        this.state.parameter.map((item, i) => {
            Object.keys(item).map((key) => {
                if (key === e.target.name) {
                    let obj = (this.state.parameter[i][key] = e.target.value);
                    this.findAndReplace(e.target.name, obj);
                } else {
                    console.log("something went wrong");
                }
            });
        });
    };

    getColumedata = (id) => {
        campaignContactList(id).then((res) => {
            this.setState({ isLoading: true });
            try {
                if (res.data.success === true) {
                    this.setState({
                        tableColume: Object.keys(res.data.data[0]),
                        previewData: res.data.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            } catch (error) {
                console.log("error", error);
            }
        });
    };

    contactListhandler = (selectedOption) => {
        this.setState({ selectedOption });
        // this.setState({ listId: e.value });
        // var item = this.state.contactList.filter(
        //     (item) => item.id === parseInt(e.value)
        // );
        // this.setState({
        //     selectedListName: item[0].name,
        //     totalCount: item[0].number_count,
        // });
        // this.getColumedata(e.value);
        // this.getSmapleData(e.value);
    };

    getSmapleData = async (id) => {
        try {
            const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
                token: this.state.token,
                user_id: this.state.user_id,
                list_id: id,
                method: "retrieve_data_sample",
            });
            if (data.success === true) {
                this.setState({ sampleData: data.data, setIsLoading: false });
            } else {
                this.setState({ ErrorResponse: data, setIsLoading: false });
            }
        } catch (err) {
            console.log(err);
        }
    };

    renderSampleData = () => {
        let data = this.state.sampleData;
        if (data.length > 0) {
            const column = Object.keys(data[0]);
            return (
                <>
                    <p>Your Table Data </p>
                    <table className="table table-hover  my-0">
                        <thead>
                            {column.map((data) => (
                                <th>{data}</th>
                            ))}
                        </thead>
                        {data.map((data) => (
                            <tr>
                                {column.map((v) => {
                                    return <td>{data[v]}</td>;
                                })}
                            </tr>
                        ))}
                    </table>
                </>
            );
        }
    };

    findAndReplace = (name, column) => {
        let text = this.state.templatePreView.bodyMessage;
        let replaceKeyword = `{{${name}}}`;
        let value = this.state.sampleData[0][column];
        let newtext = text.replace(replaceKeyword, value);
        let obj = (this.state.templatePreView["temp_body"] = newtext);
        this.setState({
            templatePreView: {
                ...this.state.templatePreView,
                ["bodyMessage"]: newtext,
            },
        });
    };

    getCsvData(data, countData) {
        this.setState({
            sampleData: data.rowData,
            fileData: data,
            countData: countData,
            dynamicSampleData: countData.splice(0, 5),
            selectedMobileColumn: ''
        });
        this.setCsvData();
    }

    // charaterCount = (text) => {
    //     var newstr = text?.replace(/ /g, " ");
    //     var patt = new RegExp("[^\x00-\x7F]");
    //     var res = patt.test(newstr);
    //     var charLength = text.replace(/{|}|\[|\]|~|\^|\||\\/g, "  ");
    //     var outputString = parseInt(charLength.length);
    //     let obj = {
    //         total: "",
    //         credit: "",
    //     };
    //     if (res) {
    //         if (outputString < 70) {
    //             obj["total"] = outputString;
    //             obj["credit"] = 1;
    //         } else if (outputString / 70 == 1) {
    //             obj["total"] = outputString;
    //             obj["credit"] = Math.round(outputString / 70);
    //         } else {
    //             obj["total"] = outputString;
    //             obj["credit"] = Math.ceil(outputString / 67);
    //         }
    //     } else {
    //         if (outputString < 160) {
    //             obj["total"] = outputString;
    //             obj["credit"] = 1;
    //         } else if (outputString / 160 == 1) {
    //             obj["total"] = outputString;
    //             obj["credit"] = Math.round(outputString / 160);
    //         } else {
    //             obj["total"] = outputString;
    //             obj["credit"] = Math.ceil(outputString / 153);
    //         }
    //     }

    //     return (
    //         <p className="sms-count mb-0">
    //             <small>
    //                 Character Used <strong id="totalWords">{obj.total}</strong>
    //             </small>
    //             <small>
    //                 Credits
    //                 <strong> {obj.credit}</strong>
    //             </small>
    //         </p>
    //     );
    // };

    // preViewSMSHandler = async (e) => {
    //     const inputValue = e.target.value;
    //     console.log(inputValue)
    //     let APIResponce = "";
    //     let lastWord = e.target.value.split(" ");
    //     if (this.state.is_unicode) {
    //         if (e.key === " " && inputValue.trim() !== "") {
    //             await translateAPI(
    //                 lastWord[lastWord.length - 1],
    //                 this.state.language
    //             ).then((result) => {
    //                 APIResponce = result.data + " ";
    //             });
    //         }
    //     }
    //     if (APIResponce === "") {
    //         this.setState({
    //             SMSmessage: e.target.value,
    //         });
    //     } else {
    //         if (inputValue !== null) {
    //             var lastIndex = inputValue.lastIndexOf(lastWord[lastWord.length - 1]);
    //             let originalMessage = inputValue.substring(0, lastIndex);
    //             this.setState({
    //                 SMSmessage: `${originalMessage}${APIResponce}`,
    //             });
    //         }
    //     }

    //     this.charaterCount(inputValue);
    // };

    scheduleHandler(e) {
        if (e.target.checked === true) {
            this.setState({ is_schedule: 1 });
        } else {
            this.setState({ is_schedule: 0 });
        }
    }

    isUnicode() {
        var message = this.state.rcsmessage;
        var newstr = message.replace(/ /g, " ");
        var patt = new RegExp("[^\x00-\x7F]");
        var res = patt.test(newstr);
        if (res) {
            this.setState({
                is_unicode: true,
            });
        } else {
            this.setState({
                is_unicode: false,
            });
        }
        return res;
    }

    onNameChange = (e) => {
        this.setState({ CampaignName: e.target.value });
        this.setState({ isError: false });
    };

    onChange = (e) => {
        const value = e.target.value;
        // Allow only numbers and commas
        const validValue = value.replace(/[^0-9,\n]/g, '');
        if (value !== validValue) {
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Please enter a valid input containing only numbers and commas.",
                isLoading: false,
            });
        } else {
            this.setState({ isError: false, errorMessage: "", messageType: "" });
        }
        this.setState({ textnumbers: validValue });
        this.newArray(validValue);
    };


    replaceBodyVal = (index, e) => {
        // console.log(e.target.value);
        const updatedValues = { ...this.state.bodyValue };
        updatedValues[e.target.name] = e.target.value;

        this.setState({ bodyValue: updatedValues, bodyValueObject: updatedValues });

        if (this.state.templatePreView.temp_type === 'text') {
            const bodydata = this.formatMessage(
                this.state.mainMsg,
                this.state.bodyVar,
                updatedValues
            );

            this.setState({ rcsmessage: bodydata });
        } else if (this.state.templatePreView.temp_type === 'richcardstandalone') {
            const titleData = this.formatMessage(
                this.state.cardDataArray[0].card_title,
                this.state.bodyVar,
                updatedValues
            )
            const descriptionData = this.formatMessage(
                this.state.cardDataArray[0].card_description,
                this.state.bodyVar,
                updatedValues
            )
            this.setState((prevState) => ({
                cardDynamicDataArray: prevState.cardDynamicDataArray.map((item, index) =>
                    index === 0
                        ? { ...item, card_title: titleData, card_description: descriptionData }
                        : item
                ),
            }));

        } else {
            const updatedCardDynamicDataArray = this.state.cardDataArray.map((cardData, index) => {
                const titleData = this.formatMessage(
                    cardData.card_title,
                    this.state.bodyVar,
                    updatedValues
                );
                const descriptionData = this.formatMessage(
                    cardData.card_description,
                    this.state.bodyVar,
                    updatedValues
                );

                return {
                    ...this.state.cardDynamicDataArray[index], // Preserve existing properties
                    card_title: titleData,
                    card_description: descriptionData,
                };
            });

            this.setState({
                cardDynamicDataArray: updatedCardDynamicDataArray,
            });
        }

    };

    setCsvData = () => {
        const { dynamicSampleData } = this.state;
        // Extract headers and data
        const csvHeaders = dynamicSampleData[0]
            ? Object.keys(dynamicSampleData[0]).map((header) => header.trim())
            : [];

        this.setState({ csvHeaders: csvHeaders })
        const csvRows = dynamicSampleData.map((item) => Object.values(item));
        this.setState({ csvRows: csvRows })
    }

    generatePreviews(template) {
        const { csvRows, variableMapping } = this.state;
        // Generate previews for each row
        const previews = csvRows.map((row) => {
            let preview = template;
            Object.keys(variableMapping).forEach((variable) => {
                const header = variableMapping[variable];
                const columnIndex = this.state.csvHeaders.indexOf(header);
                if (columnIndex !== -1) {
                    preview = preview.replace(variable, row[columnIndex]);
                }
            });
            return preview;
        });


        return previews; // Previews can be shown in the carousel
    }


    handleMapping(variable, selectedHeader) {
        this.setState(
            (prevState) => {
                // Update the variable mapping with the new key-value pair
                const updatedVariableMapping = {
                    ...prevState.variableMapping,
                    [variable]: selectedHeader,
                };

                // Remove the previous value for this variable from filteredHeaders
                const updatedFilteredHeaders = prevState.filteredHeaders.filter(
                    (header) => header !== prevState.variableMapping[variable]
                );

                // Check if selectedHeader is already in filteredHeaders
                if (!updatedFilteredHeaders.includes(selectedHeader)) {
                    // Add the new selectedHeader only if it's not already in the array
                    updatedFilteredHeaders.push(selectedHeader);
                }

                return {
                    variableMapping: updatedVariableMapping,
                    filteredHeaders: updatedFilteredHeaders,

                };
            },
            () => {

                // Generate previews after mapping is updated
                if (this.state.templatePreView.temp_type === "text") {
                    const template = this.state.rcsmessage; // Use actual template from state
                    const previews = this.generatePreviews(template);
                    this.setState({ previews }); // Save previews in state
                } else if (this.state.templatePreView.temp_type === "richcardstandalone") {
                    const title = this.state.cardDataArray[0].card_title;
                    const description = this.state.cardDataArray[0].card_description;
                    const titlePreviews = this.generatePreviews(title);
                    const descriptionPreviews = this.generatePreviews(description);

                    this.setState((prevState) => {
                        const { csvRows } = prevState; // Retrieve CSV rows from the current state

                        // Create an array of arrays, with each array containing one or more objects
                        const richCardPreviews = csvRows.map((row, rowIndex) => {
                            // Each `row` produces an array of rich card objects (example assumes one object per row, but can be extended)
                            return [
                                {
                                    card_title: titlePreviews[rowIndex] || "Default Title", // Use the generated preview or a fallback value
                                    card_description: descriptionPreviews[rowIndex] || "Default Description", // Use the generated preview or a fallback value
                                    media: prevState.cardDataArray[0]?.media, // Keep the media consistent with the original data
                                    temp_suggestion: prevState.cardDataArray[0]?.temp_suggestion || [], // Suggestions (if any) from the original template
                                },
                            ];
                        });

                        return { previews: richCardPreviews }; // Update the `previews` state with array of arrays
                    });

                } else {
                    this.setState((prevState) => {
                        const { csvRows, cardDataArray } = prevState; // Retrieve CSV rows and card data

                        // Iterate through csvRows and create previews for each row
                        const richCardPreviews = csvRows.map((row, rowIndex) => {
                            // For each row, generate the preview for both title and description
                            return cardDataArray.map((card, cardIndex) => {
                                const titlePreviews = this.generatePreviews(card.card_title); // Generate title preview
                                const descriptionPreviews = this.generatePreviews(card.card_description); // Generate description preview

                                // Return an array containing two objects: title and description for this row
                                return {

                                    ...card,
                                    card_title: titlePreviews[rowIndex] || "Default Title", // Use generated preview or fallback
                                    card_description: descriptionPreviews[rowIndex] || "Default Description", // Use generated preview or fallback

                                };
                            });
                        });

                        // Flatten the array to match the structure: array of objects where each object contains title and description
                        return {
                            previews: richCardPreviews // Flatten the array to get a single array of objects
                        };
                    });
                }

            }
        );
    }


    formatMessage = (msg, placeholders, inputValues) => {
        const parts = msg.split(/\[([a-zA-Z0-9_]+)\]/g);
        return parts.map((part, index) => {
            const placeholderKey = part;
            if (placeholders.includes(`[${placeholderKey}]`)) {
                return (
                    <span key={index} style={{
                        color: inputValues[placeholderKey] ? "blue" : "", // Blue for input values, black for placeholders
                    }}>
                        {inputValues[placeholderKey] || `[${part}]`}
                    </span>
                );
            }
            return part;
        });
    };

    setLoading = (value) => {
        this.setState({ isLoading: value })
    }



    newArray = (data) => {
        // const numbers = data.split("\n").map((num) => num.trim());
        const numbers = data.split(/[\n,]+/).map((num) => num.trim());
        this.setState({ numbersArray: numbers });
    };
    handleChannel(e) {
        const { pathname } = this.state.urlPath;
        const segments = pathname.split("/");
        const endPath = segments[segments.length - 1];
        const selectedChannel = e.target.value;

        if (selectedChannel === "whatsapp") {
            if (endPath === "campaign-start") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/campaign-start");
            }
        }

        if (selectedChannel === "sms") {
            if (endPath === "sms-campaign") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/sms-campaign");
            }
        }

        if (selectedChannel === "rcs") {
            if (endPath === "rcs-campaign") {
                this.setState({ channel: selectedChannel });
            } else {
                this.props.history.push("/dashboard/rcs-campaign");
            }
        }
    }

    handleSelectMobileColumn = (e) => {
        this.setState({ selectedMobileColumn: e.target.value })
    }


    setUploadProgress = (value) => {
        this.setState({ uploadProgress: value })
    }



    countryCode = async () => {
        try {
            const { data } = await api.post(`${NODE_HOST_URL}/rcs_campaign`, {
                token: this.state.token,
                user_id: this.state.user_id,
                method: "rcs_country_list",
            });
            if (data.success === true) {
                this.setState({ rcsCountryCode: data.data, setIsLoading: false });
            } else {
                this.setState({ ErrorResponse: data, setIsLoading: false });
            }
        } catch (err) {
            console.log(err);
        }
    }

    formRender = () => {
        switch (this.state.step) {
            case 1:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div>
                                            Choose Audiance to send the campaign on
                                            <span className="text-primary"> {this.state.channel} </span>
                                        </div>

                                        <DownloadButton campaignType={this.state.campaignType} />
                                    </div>
                                    <div >
                                        <div>
                                            {this.state.campaignType === "1" && <Tabs id="fall-back-tab">
                                                {/* <Tab label="Select From Contact List">
                                                    {this.state.contactList ? (
                                                        <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                                                            <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faAddressBook} />
                                                            </div>
                                                            <select
                                                                onChange={this.contactListhandler}
                                                                className="form-control"
                                                                value={this.state.listId}
                                                            >
                                                                <option value={``}>Select Contact List</option>
                                                                {this.state.contactList.map((item, index) => (
                                                                    <option value={item.id} key={index}>
                                                                        {item.name} ({item.number_count})
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    ) : (
                                                        <p className="alert alert-danger d-block">
                                                            You don't have any contacts please create{" "}
                                                            <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                                                            and then come back.
                                                        </p>
                                                    )}
                                                </Tab> */}
                                                <Tab label="Copy Paste Data">
                                                    <div className="input-group flex flex-column mb-2 mt-4 mr-sm-2">
                                                        <p style={{ fontSize: '.6rem', color: 'red', wordBreak: "break-all" }}>
                                                            *Enter mobile number {`${'(with country code)'}`} in new line  or comma separated
                                                        </p>
                                                        <textarea
                                                            placeholder="Enter Mobile Number"
                                                            onChange={this.onChange}
                                                            defaultValue={this.state.textnumbers}
                                                            className="form-control w-100"
                                                            rows={5}
                                                        ></textarea>
                                                    </div>
                                                    <span style={{ float: "right" }}>
                                                        count: {this.state.numbersArray.length + this.state.countData.length}
                                                    </span>
                                                </Tab>
                                                <Tab label="Upload .csv File">
                                                    <UploadCSVFile sendCsvData={this.getCsvData} setLoading={this.setLoading} />
                                                </Tab>
                                                <Tab label="Select From Contact List">
                                                    {this.state.contactList ? (
                                                        <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                                                            {/* <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faAddressBook} />
                                                            </div>
                                                            <select
                                                                onChange={this.contactListhandler}
                                                                className="form-control"
                                                                value={this.state.listId}
                                                            >
                                                                <option value={``}>Select Contact List</option>
                                                                {this.state.contactList.map((item, index) => (
                                                                    <option value={item.id} key={index}>
                                                                        {item.name} ({item.number_count})
                                                                    </option>
                                                                ))}
                                                            </select> */}

                                                            <Select
                                                                value={this.state.selectedOption}  // Bind selected option to state
                                                                onChange={this.contactListhandler}  // Handle onChange event
                                                                options={this.state.contactOption}  // Options array
                                                                isSearchable={true}  // Allow searching in options
                                                                placeholder="Select Contact List"
                                                                isClearable={true}  // Optional: allows clearing the selection
                                                            />

                                                        </div>
                                                    ) : (
                                                        <p className="alert alert-danger d-block">
                                                            You don't have any contacts please create{" "}
                                                            <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                                                            and then come back.
                                                        </p>
                                                    )}
                                                </Tab>
                                            </Tabs>}
                                            {this.state.campaignType === "3" && <Tabs id="fall-back-tab">
                                                <Tab label="Upload .xls/.xlsx File">
                                                    <UploadExcelFile setUploadProgress={this.setUploadProgress} sendCsvData={this.getCsvData} setLoading={this.setLoading} />
                                                </Tab>


                                            </Tabs>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span className="mb-3">
                                            Select{" "}
                                            <span className="text-primary">{this.state.channel}</span>{" "}
                                            template to campaign
                                        </span>

                                    </div>


                                    {this.state.rcsTemplateList.length > 0 ? (
                                        <>
                                            <div className={style.inputGroup}>
                                                <div className={style.inputGroupText}>
                                                    <FontAwesomeIcon
                                                        icon={faAddressBook}
                                                        className="mr-1"
                                                    />{" "}
                                                    Template
                                                </div>

                                                {/* <select
                                                    onChange={this.selectRCSTemplate}
                                                    className="form-control"
                                                    value={this.state.templteId}
                                                >
                                                    <option value={``} disabled>Select Template</option>
                                                    {this.state.rcsTemplateList.map((item, index) => (
                                                        <option key={index} value={item._id} data-name={item.temp_name}>
                                                            {item.temp_name}
                                                        </option>
                                                    ))}
                                                </select> */}
                                                <div className={style.selectContainer}>
                                                    <Select
                                                        className={style.reactSelect}
                                                        classNamePrefix="reactSelect"
                                                        options={this.state.rcsTemplateList.map((item) => ({
                                                            value: item._id,
                                                            label: item.temp_name,
                                                        }))}
                                                        onChange={(selectedOption) => this.selectRCSTemplate(selectedOption)}
                                                        value={this.state.templteId ? {
                                                            value: this.state.templteId,
                                                            label: this.state.templateName,
                                                        } : null
                                                        }
                                                        placeholder="Select Template"
                                                        isDisabled={this.state.rcsTemplateList.length === 0}
                                                    />

                                                </div>


                                            </div>

                                            {this.state.smsTemplateDetails && (
                                                <>

                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text w-25">
                                                            <FontAwesomeIcon
                                                                icon={faAddressBook}
                                                                className="mr-1"
                                                            />{" "}
                                                            Sender ID
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Sender ID"
                                                            className="form-control"
                                                            disabled
                                                            value={this.state.smsTemplateDetails[0].sender_id}
                                                        />
                                                    </div>

                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text w-25">
                                                            <FontAwesomeIcon
                                                                icon={faAddressBook}
                                                                className="mr-1"
                                                            />{" "}
                                                            Template ID
                                                        </div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            placeholder="Template ID"
                                                            className="form-control"
                                                            value={
                                                                this.state.smsTemplateDetails[0]._id
                                                            }
                                                        />
                                                    </div>

                                                    {this.state.campaignType === "3" &&

                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group-text w-25">
                                                                Mobile
                                                            </div>

                                                            <select

                                                                onChange={this.handleSelectMobileColumn}
                                                                className="form-control"
                                                                value={this.state.selectedMobileColumn}
                                                            >
                                                                <option value="" disabled>Select Mobile Column</option>
                                                                {this.state.csvHeaders.map((header, index) => (
                                                                    <option key={index} value={header}>
                                                                        {header}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                    }

                                                    {this.state.paramData.map((item, x) => (
                                                        <div className="input-group mb-2 mr-sm-2" key={x}>
                                                            <div className="input-group-text w-25">
                                                                {`[${item}]`}
                                                            </div>
                                                            {this.state.campaignType === "3" ? (
                                                                <select
                                                                    name={item}
                                                                    onChange={(e) => this.handleMapping(`[${item}]`, e.target.value)}
                                                                    className="form-control"
                                                                    value={this.state.variableMapping[`[${item}]`] || ""}
                                                                >
                                                                    <option value="" disabled>Select Column</option>
                                                                    {this.state.csvHeaders.map((header, index) => (
                                                                        <option key={index} value={header}>
                                                                            {header}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <input
                                                                    type="text"
                                                                    name={item}
                                                                    placeholder={`Enter [${item}]`}
                                                                    onChange={(e) => this.replaceBodyVal(x, e)}
                                                                    className="form-control"
                                                                />
                                                            )}
                                                            {/* <input type="text" name={item} placeholder={`Enter [${item}]`} onChange={(e) => this.replaceBodyVal(x, e)} className="form-control" /> */}
                                                        </div>
                                                    ))}


                                                    < div className="input group mt-3">

                                                        <div className="d-flex jusitfy-content-end">
                                                            <button className="btn btn-sm btn-primary"
                                                                disabled={this.state.showForm1}
                                                                onClick={this.handleAddForm}>
                                                                <FaPlus /> <span>Add Fallback</span>
                                                            </button>
                                                        </div>

                                                    </div>

                                                    <hr />



                                                    {this.state.showForm1 && <RcsFallback
                                                        fallback={1}
                                                        setFormStatus={(value) => this.setState({ showForm1: value })}
                                                        altFormStatus={this.state.showForm2}
                                                        priorityData={this.state.priority1Data}
                                                        altPriorityData={this.state.priority2Data}
                                                        setPriorityData={this.setPriority1Data}
                                                        campaignType={this.state.campaignType}
                                                        filteredHeaders={this.state.filteredHeaders}
                                                        variableMapping={this.state.variableMapping}
                                                        platform={this.state.channel}


                                                    />}
                                                    {this.state.showForm2 && <RcsFallback
                                                        fallback={2}
                                                        setFormStatus={(value) => this.setState({ showForm2: value })}
                                                        priorityData={this.state.priority2Data}
                                                        altPriorityData={this.state.priority1Data}
                                                        setPriorityData={this.setPriority2Data}
                                                        campaignType={this.state.campaignType}
                                                        filteredHeaders={this.state.filteredHeaders}
                                                        variableMapping={this.state.variableMapping}
                                                        platform={this.state.channel}

                                                    />}





                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <p className="alert alert-danger d-block">
                                            You don't have any template please create{" "}
                                            <Link to="/dashboard/rcs-template-list">
                                                Template
                                            </Link>{" "}
                                            and then come back.
                                        </p>
                                    )}

                                    {this.state.varibaleInTemplate && (
                                        <>
                                            <p>Please select column </p>
                                            {this.state.varibaleInTemplate.map((item, index) => (
                                                <div className="input-group mb-2 mr-sm-2">
                                                    <div className="input-group-text">{item}</div>
                                                    <select
                                                        name={item}
                                                        onChange={this.setParameter}
                                                        className="form-control"
                                                    >
                                                        {this.state.tableColume.map((item, index) => (
                                                            <option name={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {this.state.channel === "whatsapp" && (
                                        <div className="card p-2">
                                            <div className="table-responsive">
                                                {this.renderSampleData()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-5">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> Template Preview </h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="card-body">
                                            {this.state.templatePreView ? (


                                                this.state.campaignType === "1" ? (
                                                    this.state.templatePreView.temp_type === 'text'
                                                        ? <RcsPreview temp_type={this.state.templatePreView.temp_type} rcsContent={this.state.rcsmessage} suggestionButtons={this.state.templatePreView.temp_suggestion} />
                                                        : <RcsPreview temp_type={this.state.templatePreView.temp_type} cardDataArray={this.state.cardDataArray} cardDynamicDataArray={this.state.cardDynamicDataArray} />
                                                ) :
                                                    (
                                                        this.state.templatePreView.temp_type === 'text'
                                                            ? <RcsPreview
                                                                previews={this.state.previews}
                                                                temp_type={this.state.templatePreView.temp_type}
                                                                rcsContent={this.state.rcsmessage}
                                                                suggestionButtons={this.state.templatePreView.temp_suggestion}
                                                            />
                                                            :
                                                            <RcsPreview previews={this.state.previews} temp_type={this.state.templatePreView.temp_type} rcsContent={this.state.rcsmessage}
                                                                suggestionButtons={this.state.templatePreView.temp_suggestion} cardDataArray={this.state.cardDataArray} />



                                                    )


                                            ) : (
                                                <div className="text-center">
                                                    <img className="img-fluid" src={no_data} />
                                                    <p className="text-right">
                                                        Please select RCS Template to
                                                        preview
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );

            case 3:
                return (
                    <>
                        <div className="pb-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="campaign-preview sms-campaign-review ">
                                        <div className="hr">Campaign Review </div>
                                        <hr className="mt-2" />
                                        <p className="m-0">
                                            <span className="camp-name">Campaign Name: </span>
                                            <span className="">{this.state.CampaignName}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Channel Selected : </span>
                                            <span className="">{this.state.channel}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Total Number : </span>
                                            <span className="">{this.state.numbersArray.length + this.state.countData.length}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Campaign Date : </span>
                                            <span className="">{this.state.schedule_datetime}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Campaign Template : </span>
                                            <span className="">
                                                Test Campaign
                                                {this.state.channel === "sms"
                                                    ? this.state.templatePreView.template_name
                                                    : this.state.templatePreView.tampleName}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mt-3">

                                <p className="m-0 d-flex items-align-center">
                                    <label className={style.switch} style={{ marginRight: ".5rem" }}>
                                        <input
                                            className="form-check-input mb-1"
                                            value={this.state.is_schedule}
                                            onChange={this.scheduleHandler}
                                            type="checkbox"
                                            id="flexCheckDefault"
                                        />
                                        <span className={style.slider}></span>
                                    </label>
                                    <strong> Campaign Schedule </strong>
                                </p>

                                <small>
                                    {" "}
                                    Campaign scheduling is the process of specifying the start and
                                    end dates and times for a campaign.
                                </small>
                            </div>
                            {this.state.is_schedule === 1 && (
                                <>
                                    <p className="mt-3 d-block">
                                        <strong> Select Date and Time of Campaign </strong>
                                    </p>
                                    <div className={`input-group mb-2 ${style.wMd30}`}>
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faAddressBook} />
                                        </div>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            onChange={(e) =>
                                                this.setState({ schedule_datetime: e.target.value })
                                            }
                                            placeholder="Example: campaign for digital marketing"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );

            default:
                return (
                    <div className="filter-option">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <p className="mt-3 d-block">Campaign Channel</p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        onChange={this.handleChannel}
                                        className="form-control"
                                        value={this.state.channel}
                                    >
                                        <option value={``}>Select Channel</option>
                                        <option value={`whatsapp`}>WhatsApp</option>
                                        <option value={`sms`}>SMS</option>
                                        <option value={`rcs`}>RCS</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Country </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faFlag} />
                                    </div>
                                    <select
                                        onChange={(e) => this.setState({ country_code: e.target.value })}
                                        className="form-control"
                                        value={this.state.country_code}
                                    >
                                        <option value=''>Select Country</option>
                                        {this.state.rcsCountryCode && this.state.rcsCountryCode.length > 0 ? (
                                            this.state.rcsCountryCode.map((x, index) => {

                                                return (
                                                    <option key={index} value={x.country_code}>
                                                        {/* Display the country code and country name */}
                                                        {`${x.country_name}(${x.country_code})`}
                                                    </option>
                                                );
                                            })
                                        ) : (
                                            <option disabled>No country codes available</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Campaign Type </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        value={this.state.campaignType}
                                        onChange={(e) =>
                                            this.setState({
                                                campaignType: e.target.value,
                                                templteId: '',
                                                templateName: '',
                                                cardDynamicDataArray: [{
                                                    media: null,
                                                    card_title: '',
                                                    card_description: '',
                                                    temp_suggestion: []
                                                }],
                                                cardDataArray: [],
                                                smsTemplateDetails: null,
                                                rcsmessage: [],
                                                templatePreView: "",
                                                mainMsg: '',
                                            })
                                        }
                                        className="form-control"
                                    >
                                        <option value="1"> Normal Campaign </option>
                                        <option value="3"> Custom (Dynamic) campaign</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <p className="mt-3 d-block">Enter Custom Campaign Name</p>
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </div>
                                    <input
                                        type="text"
                                        defaultValue={this.state.CampaignName}
                                        className="form-control"
                                        onChange={this.onNameChange}
                                        // onChange={(e) =>
                                        //     this.setState({ CampaignName: this.state.CampaignName.length < 100 ? e.target.value : null })
                                        // }
                                        placeholder="Example: campaign for digital marketing"
                                    />
                                </div>
                                <small className="text-primary">
                                    Example : Campaign for remarketing
                                </small>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    render() {

        return (
            <div className="wrapper">
                <Helmet>
                    <style type="text/css">{`
         .whatsapp ul {
            display:none!important;
          }
        `}</style>
                </Helmet>

                {this.state.isLoading &&
                    (this.state.uploadProgress === 0) &&
                    <Spining />
                }
                {this.state.isLoading &&
                    (this.state.uploadProgress >= 100) &&
                    <div className={`${style.spinnerContainer} ${style.animatedText}`}>
                        Processing...
                    </div>
                }
                {this.state.isLoading &&
                    this.state.uploadProgress !== 0 &&
                    this.state.uploadProgress < 100 &&
                    <div className={style.spinnerContainer}>
                        <div className={style.spinnerWrapper}>
                            <div className={style.spinner}></div>
                            <div className={style.percentage}>
                                {this.state.uploadProgress}%
                            </div>
                            <p className={style.uploadText}>Uploading file</p>
                        </div>
                    </div>

                }

                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>
                                        <strong>RCS Campaign </strong>
                                    </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="msform" style={{zIndex:"0"}}>
                                            <ul id="progressbar">
                                                {this.state.step === 0 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li>Choose Audiance </li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 1 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance</li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 2 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 3 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li className="active"> Preview Campaign </li>
                                                    </>
                                                ) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isError ? (
                                    <Alert type={this.state.messageType}>
                                        {this.state.errorMessage}
                                    </Alert>
                                ) : null}

                                <div className="card">
                                    <div className="card-body campaign-box">
                                        {this.formRender()}

                                        <hr />

                                        <div className="row justify-content-center">
                                            <div className="col-md-4 mb-3">
                                                <button
                                                    disabled={this.state.step === 0}
                                                    onClick={() => this.prevStep()}
                                                    className={
                                                        this.state.step === 0
                                                            ? "btn btn-block btn-secondary"
                                                            : "btn btn-block btn-primary"
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faArrowCircleLeft} /> Previous
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                {this.state.step === 3 ? (
                                                    <button
                                                        onClick={this.createCampaign}
                                                        className="btn btn-block btn-info"
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle} /> Start
                                                        Campaign
                                                    </button>
                                                ) : (
                                                    <button
                                                        disabled={
                                                            this.state.isError ||
                                                            (this.state.campaignType === "3"
                                                                ? this.state.step === 1 && this.state.dynamicSampleData.length === 0
                                                                : this.state.step === 1 &&
                                                                (this.state.csvHeaders.length === 0 &&
                                                                    this.state.textnumbers === "" &&
                                                                    !this.state.selectedOption
                                                                )) || (this.state.step === 2 && this.state.templteId === "")
                                                        }
                                                        onClick={() => this.nextStep()}
                                                        className="btn btn-block btn-primary"
                                                    >
                                                        Next <FontAwesomeIcon icon={faArrowCircleRight} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default withRouter(RcsCampaign);