import React, { Component } from "react";
import { CAMPAIGN_REPORT } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { Link } from "react-router-dom";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { CAMPAIGN_REPORT_SMS_TABLE, CAMPAIGN_REPORT_TABLE, CAMPAIGN_REPORT_WHATSAPP_TABLE } from "../../UI/Table/ColumnConst";
import Alert from "../../UI/Alert/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL_DB2, NODE_HOST_URL } from "../api/Api";
import Axios from "../../../axios/Axios";

class CampaignReport extends Component {
  constructor(props) {
    super(props);
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;

    this.state = {
      userId: user_id,
      token: token,
      id: this.props.match.params.id,
      channel: this.props.match.params.channel,
      campaignName: this.props.match.params.campaignName,
      CampaignReportList: [],
      filterContact: "",
      setIsLoading: true,
      ErrorResponse: "",
      campaignList: [],
      currentPage: 1,
      pagination: 10,
      rcsReportList: [],
      searchData: "",
      visibleRange: [1, 10],
      totalPages: 0, // Added a state to track the total number of pages
    };
  }

  componentDidMount() {
    this.campaignReport();
    this.RcsCampaignReport();
    this.CampaignData(this.state.currentPage);
  }

  campaignReport = async () => {
    try {
      const { data } = await api.post(CAMPAIGN_REPORT, {
        token: this.state.token,
        user_id: this.state.userId,
        campaign_id: this.state.id,
        method: "report",
        channel: this.state.channel,
      });
      if (data.success === true) {
        this.setState({ CampaignReportList: data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ setIsLoading: false });
    }
  };

  RcsCampaignReport = async () => {
    try {
      const { data } = await Axios.post(`${NODE_HOST_URL}/rcs`, {
        token: this.state.token,
        user_id: this.state.userId,
        campaign_id: this.state.id,
        method: "campaign_report_dtl",
        page: this.state.currentPage,
      });
      if (data) {
        this.setState({ rcsReportList: data.data, setIsLoading: false, totalPages: data.totalPages });
      } else {
        this.setState({ setIsLoading: false });
      }
    } catch (err) {
      console.log("Error fetching RCS report:", err);
      this.setState({ setIsLoading: false });
    }
  };

  CampaignData = async (pageNumber) => {
    try {
      const response = await fetch(
        `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.userId}&method=report&token=${this.state.token}&channel=${this.state.channel}&campaign_id=${this.state.id}&page_no=${pageNumber}`
      );
      const result = await response.json();
      if (result.success === true) {
        this.setState({ campaignList: result.data });
      } else {
        this.setState({ ErrorResponse: result.message, setIsLoading: false });
      }
    } catch (err) {
      console.log("Error fetching campaign data:", err);
      this.setState({ setIsLoading: false });
    }
  };

  filterData = async () => {
    this.setState({ setIsLoading: true });
    try {
      const { data } = await api.post(CAMPAIGN_REPORT, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "filter",
        campaign_id: this.state.id,
        mobile: this.state.filterContact,
      });
      if (data.success === true) {
        this.setState({ CampaignReportList: data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data.message, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ setIsLoading: false });
    }
  };

  resetFilter = () => {
    this.campaignReport();
    this.setState({
      filterContact: "",
    });
  };

  forwardPage = () => {
    // if (this.state.currentPage < this.state.totalPages) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => {
        this.CampaignData(this.state.currentPage);
      });
    // }
  };

  backwardPage = () => {
    if (this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 }, () => {
        this.CampaignData(this.state.currentPage);
      });
    }
  };

  updatePageRange = (newPage) => {
    const totalPages = this.state.totalPages;
    let start = Math.floor((newPage - 1) / 10) * 10 + 1;
    let end = Math.min(start + 9, totalPages);
    this.setState({ visibleRange: [start, end] });
  };

  handleSearchChange = (e) => {
    this.setState({ searchData: e.target.value });
  };

  processContent = (text) => {
    return text.split(',' || '.').map(part => `<div>${part.trim()}</div>`).join('');
  };

  render() {
    console.log(this.state.currentPage)
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={false}
              btnName={`Create New Contact List `}
              actionURL={`/dashboard/create-contact-list`}
            >
              Campaign Reports
            </Title>

            {this.state.ErrorResponse && (
              <Alert type="alert-danger">{this.state.ErrorResponse}</Alert>
            )}

            <div className="card">
              <h3 className="pl-3 pt-3">
                <span className="text-success">Campaign Name / ID : </span> {this.state.campaignName} / {this.state.id}
              </h3>
              <hr className="mt-0" />

              <div className="filter-option">
                <h6 className="card-subtitle text-muted">Filter </h6>
                <div className="row">
                  <div className="col-md-8">
                    <div className="input-group mb-2">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <input
                        type="text"
                        onChange={this.handleSearchChange}
                        value={this.state.filterContact}
                        placeholder="Enter mobile number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <input
                      type="button"
                      onClick={this.filterData}
                      value="Search"
                      className="btn mt-1 btn-primary btn-sm btn-block"
                    />
                  </div>
                  <div className="col-md-2">
                    <button onClick={this.resetFilter} className="btn mt-1 btn-danger btn-sm btn-block">
                      Reset Filter
                    </button>
                  </div>
                </div>
              </div>

              {this.state.rcsReportList.length > 0 && (
                <>
                  <div className="row mb-3">
                    <div className="col-6"></div>
                    <div className="col-6">
                      <div className="input-group float-right">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                        </div>
                        <input
                          type="text"
                          value={this.state.searchData}
                          onChange={this.handleSearchChange}
                          className="form-control"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th>Phone Number</th>
                          <th>Country Code</th>
                          <th>Campaign Message</th>
                          <th>Sent Time</th>
                          <th>Delivery Time</th>
                          <th>Status</th>
                          <th>Error Code</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rcsReportList
                          .filter((item) =>
                            item.phone_number.toLowerCase().includes(this.state.searchData.toLowerCase())
                          )
                          .map((item, i) => (
                            <tr key={i}>
                              <td>{item.phone_number}</td>
                              <td>{item.country_code}</td>
                              <td>
                                <div
                                  className="break-after-comma"
                                  style={{
                                    fontSize: "12px",
                                    whiteSpace: "normal", 
                                    wordWrap: "break-word", 
                                    overflowWrap: "break-word", 
                                  }}
                                  dangerouslySetInnerHTML={{ __html: this.processContent(item.campaign_message) }}
                                />
                              </td>
                              <td>{item.sent_time}</td>
                              <td>{item.deliver_time}</td>
                              <td>{item.status}</td>
                              <td>{item.api_status}</td>
                              <td>{item.api_response}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {this.props.match.params.channel === "sms" || this.props.match.params.channel === "whatsapp" ? (
                <AdvanceDataTable
                  tableName=""
                  tableCell={this.props.match.params.channel === "sms" ? CAMPAIGN_REPORT_SMS_TABLE : CAMPAIGN_REPORT_WHATSAPP_TABLE}
                  tableData={this.state.campaignList}
                  isLoading={this.state.setIsLoading}
                  isError={this.state.ErrorResponse}
                />
              ) : null}

              {this.state.currentPage > 0 && (
                <div className="pagination-controls">
                  <button onClick={this.backwardPage} disabled={this.state.currentPage === 1} className="btn btn-secondary btn-sm">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <button onClick={this.forwardPage} disabled={this.state.currentPage === this.state.totalPages} className="btn btn-secondary btn-sm">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default CampaignReport;
