import React, { useEffect, useState } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Spining from "../../UI/Spining/Spining";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_RCS_SETTING_LIST } from "../../UI/Table/ColumnConst";
import Axios from "../../../axios/Axios";
import { HOST_URL, NODE_HOST_URL } from "../../Dashboard/api/Api";
import { userloginDetails } from "../../Utils/AdminAuth";
import ReactDatePicker from "react-datepicker";
import { faCalendarAlt, faDownload, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";

const RcsAdminDetailReport = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    const [loading, setLoading] = useState(false);
    const [fromdate, setFromdate] = useState(new Date());
    const [todate, setTodate] = useState(new Date());
    const [listData, setListData] = useState([]);
    const [allusers, setAllusers] = useState([{ value: "", label: "All Users" }]);
    const [selecteduser, setSeletedusers] = useState("");
    const [page,setPage] = useState(1);
    const {id , campaignName, contactcount} = useParams();
    const param = useParams();
    console.log(id)
    console.log(campaignName)
    console.log(contactcount)

    useEffect(()=>{
        RcsCampaignReport();
    },[])

     const RcsCampaignReport = async () => {
        setLoading(true);
        try {
          const { data } = await Axios.post(`${NODE_HOST_URL}/rcs`, {
            token: userToken,
            user_id: userId,
            campaign_id: id,
            method: "campaign_report_dtl",
            page: page,
          });
          if (data) {
            // this.setState({ rcsReportList: data.data, setIsLoading: false, totalPages: data.totalPages });
            console.log(data);
          } else {
            this.setState({ setIsLoading: false });
          }
        } catch (err) {
          console.log("Error fetching RCS report:", err);
           setLoading(false);
        }
      };
    


    const processContent = (text) => {
        return text.split(',' || '.').map(part => `<div>${part.trim()}</div>`).join('');
    };

    // console.log(selecteduser)
    return (
        <>
            {loading && <Spining />}
            <AdminLayout>
                <div className="row">
                    <div className="col-3">
                        <h6>RCS Campaign Report</h6>
                    </div>
                    {/* <div className="col-9 mb-2">
                        <Link
                            type="button"
                            style={{
                                float: "right",
                                fontSize: "12px",
                                padding: "10px 10px 10px 10px",
                            }}
                            to={"/admin/rcs/rcs-setting-add"}
                            className="btn btn-success"
                        >
                            Add new
                        </Link>
                    </div> */}
                </div>

                {/* <AdvanceDataTable
          tableCell={ADMIN_RCS_SETTING_LIST}
          tableData={listData}
          isLoading={loading}
        /> */}

                <div className="card">
                    <div className="row mb-3">
                        <div className="col-6"></div>
                        <div className="col-6">
                            <div className="input-group float-right">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                </div>
                                <input
                                    type="text"
                                    // value={this.state.searchData}
                                    // onChange={this.handleSearchChange}
                                    className="form-control"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Phone Number</th>
                                    <th>Country Code</th>
                                    <th>Campaign Message</th>
                                    <th>Sent Time</th>
                                    <th>Delivery Time</th>
                                    <th>Status</th>
                                    <th>Error Code</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {this.state.rcsReportList
                                    .filter((item) =>
                                        item.phone_number.toLowerCase().includes(this.state.searchData.toLowerCase())
                                    )
                                    .map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.phone_number}</td>
                                            <td>{item.country_code}</td>
                                            <td>
                                                <div
                                                    className="break-after-comma"
                                                    style={{
                                                        fontSize: "12px",
                                                        whiteSpace: "normal",
                                                        wordWrap: "break-word",
                                                        overflowWrap: "break-word",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: this.processContent(item.campaign_message) }}
                                                />
                                            </td>
                                            <td>{item.sent_time}</td>
                                            <td>{item.deliver_time}</td>
                                            <td>{item.status}</td>
                                            <td>{item.api_status}</td>
                                            <td>{item.api_response}</td>
                                        </tr>
                                    ))} */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default RcsAdminDetailReport;
