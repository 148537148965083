import React, { useEffect, useState } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import { useParams } from "react-router-dom";
import Axios from "../../../axios/Axios";
import { NODE_HOST_URL } from "../api/Api";
import Spining from "../../UI/Spining/Spining";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
const ContactListDtl = () => {
  const { id, list_type, mob, ref } = useParams();

  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const [bllingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [error, serError] = useState({
    status: false,
    bgColor: "",
    message: "",
  });
  useEffect(() => {
    getContactDetails();
  }, [mob, ref]);
  const getContactDetails = async () => {
    setLoading(true);
    const paramData = {
      token,
      user_id,
      method: "list_data_details",
      //   mobile: mob,
      //   ref_id: ref,
      mobile: "9716568142",
      ref_id: "677b95d2a6c509483ba38b2d",
      from_date: "2025-01-07",
      to_date: "2025-01-07",
      posbill: ["BillId", "BillNo"],
      posbillitem: ["ItemId", "ItemName"],
      poscustomer: ["firstfame", "lastname"],
    };

    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/contact_list`,
        paramData
      );

      if (data.success) {
        setBillingData(data.data);
      } else {
        serError({
          status: true,
          bgColor: "alert alert-danger alert-dismissible",
          message: data.message,
        });
        console.log("Error: ", data.message || "No success message");
      }
    } catch (error) {
      console.log("Error:", error);
      serError({
        status: true,
        bgColor: "alert alert-danger alert-dismissible",
        message: error.message,
      });
    }
    setLoading(false);
  };

  const fromdateHandler = (data) => {
    setFromdate(data);
  };

  const todateHandler = (data) => {
    setTodate(data);
  };
  console.log(fromdate,todate);

  return (
    <>
      <DashboardLayout>
        {loading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Back`}
              actionURL={`/dashboard/contact-list`}
            >
              Billing details of mobile :{mob}
            </Title>
            {error.status && (
              <div className={error.bgColor} role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="">{error.message}</div>
              </div>
            )}
            <div className="card flex-fill  ">
              <div className="row p-1">
                <div className="mb-3 col-md-4">
                  <div className="input-group my-2 mr-sm-2 table4">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>

                    <ReactDatePicker
                      className="form-control btn-block"
                       dateFormat="yyyy-MM-dd"
                      value={fromdate}
                      onChange={fromdateHandler}
                      selected={fromdate}
                      placeholderText="From Date"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-group my-2 mr-sm-2 table5">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                    <ReactDatePicker
                      className="form-control btn-block"
                       dateFormat="yyyy-MM-dd"
                      value={todate}
                      onChange={todateHandler}
                      selected={todate}
                      placeholderText="Date To"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <button className="btn btn-success"> Search</button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default ContactListDtl;
