import React, { Component } from "react";
import { Link } from "react-router-dom";

import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { userloginDetails } from "../Utils/AdminAuth";
import { server } from "../../server";

class UserDetails extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;
    super();

    this.state = {
      user_id: userId,
      token: userToken,
      userType: isAdmin,
      city: "",
      email: "",
      mobile: "",
      fullname: "",
      email_cost: "",
      country: "",
      template_editable: "",
      enable_ip_whitelist: 0,
      whiteLableIP: "",
      spam_bypass: null,
      address_line_1: "",
      selectUserDetails: "",
      seletedUserId: "",
      selectedUserType: "",
      expiryDate: "",
      employeeList: [],
      parent: "",
      awsSetting: "",
      updateExpirydate: "",
      SelecteddateFrom: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      specialUser: null,
      eyeIconToggle: false,
      passError: 0,
      password: "",
      cnfmPassword: "",
      checkBox: {
        all: null,
        sms: null,
        voice: null,
        email: null,
        whatsapp: null,
        recharge: null,
        notification: null,
        rich_media: null,
        fallback: null,
      },
    };
    this.eyeToggle = this.eyeToggle.bind(this);
    this.handleCnfmPass = this.handleCnfmPass.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.spamHandlar = this.spamHandlar.bind(this);
    this.handleCheckboxAll = this.handleCheckboxAll.bind(this);
  }

  componentDidMount() {
    this.getEmployee();
    this.setState({ seletedUserId: this.props.match.params.id , selectedUserType: this.props.match.params.userType});
    if (this.state.user_id == 4784) {
      this.setState({ specialUser: true });
    }
    fetch(
      `${server}/api/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        console.log('user',result.data);
        if (result.success === true) {
          this.setState({
            selectUserDetails: result.data[0],
            fullname: result.data[0].fullname,
            email: result.data[0].email,
            mobile: result.data[0].mobile,
            city: result.data[0].city,
            address_line_1: result.data[0].address_line_1,
            email_cost: result.data[0].email_cost,
            country: result.data[0].country,
            spam_bypass: result.data[0].spam_bypass,
            template_editable: result.data[0].template_editable,
            expiryDate: result.data[0].expiry,
            parent: result.data[0].parent,
            whiteLableIP: result.data[0].whitelist_ip,
            enable_ip_whitelist: result.data[0].enable_ip_whitelist,
            awsSetting: result.data[0].aws_setting,
          });

          this.setState((prevState) => ({
            checkBox: {
              ...prevState.checkBox,

              ["sms"]: result.data[0].sms_menu,
              ["voice"]: result.data[0].voice_menu,
              ["email"]: result.data[0].email_menu,
              ["whatsapp"]: result.data[0].whatsapp_menu,
              ["recharge"]: result.data[0].rechrg_menu,
              ["notification"]: result.data[0].notify_menu,
              ["rich_media"]: result.data[0].rich_menu,
              ["fallback"]: result.data[0].fb_menu,
            },
          }));
          if (
            result.data[0].sms_menu === 1 &&
            result.data[0].voice_menu === 1 &&
            result.data[0].email_menu === 1 &&
            result.data[0].whatsapp_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].notify_menu === 1 &&
            result.data[0].rich_menu === 1 &&
            result.data[0].fb_menu === 1
          ) {
            this.setState((prevState) => ({
              checkBox: {
                ...prevState.checkBox,

                ["all"]: 1,
              },
            }));
          }
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  getEmployee() {
    fetch(
      `${server}/api/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //  console.log(result);
        if (result.success === true) {
          this.setState({
            employeeList: result.data,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  UpdateUserDetails() {
    this.setState({
      successMessage: false,
      bgColor: "",
      errorMessage: "",
    });

    document.querySelector("body").scrollTo(0, 0);
    window.scrollTo(0, 0);
    this.setState({ seletedUserId: this.props.match.params.id });

    // if (!this.state.password) {
    //   this.setState({
    //     successMessage: true,
    //     bgColor: "alert alert-danger alert-dismissible",
    //     errorMessage: "Please enter password",
    //   });

    //   return;
    // }

    // if (!(this.state.password === this.state.cnfmPassword)) {
    //   this.setState({
    //     successMessage: true,
    //     bgColor: "alert alert-danger alert-dismissible",
    //     errorMessage: "The passwords you entered don't match",
    //   });

    //   return;
    // }

    var bodyObject = {
      user_id: this.state.user_id,
      token: this.state.token,
      city: this.state.city,
      email: this.state.email,
      user_type: "client",
      password: this.state.password,
      mobile: this.state.mobile,
      ret_user_id: this.props.match.params.id,
      fullname: this.state.fullname,
      email_cost: this.state.email_cost,
      country: this.state.country,
      spam_bypass: this.state.spam_bypass,
      address_line_1: this.state.address_line_1,
      template_editable: this.state.template_editable,
      expiry: this.state.updateExpirydate,
      parent: this.state.parent,
      whitelist_ip: this.state.whiteLableIP,
      enable_ip_whitelist: this.state.enable_ip_whitelist,
      aws_setting: this.state.awsSetting,

      sms_menu: this.state.checkBox.sms,
      voice_menu: this.state.checkBox.voice,
      email_menu: this.state.checkBox.email,
      whatsapp_menu: this.state.checkBox.whatsapp,
      rechrg_menu: this.state.checkBox.recharge,
      notify_menu: this.state.checkBox.notification,
      rich_menu: this.state.checkBox.rich_media,
      fb_menu: this.state.checkBox.fallback,

      method: "update",
    };
    //console.log(bodyObject);
    fetch(`${server}/api/admin_user_list.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  dateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    if (this.state.user_id == 4784) {
      this.setState({
        expiryDate: dated,
        updateExpirydate: dated,
      });
    }
  };

  spamHandlar = (e) => {
    if (e.target.checked === true) {
      this.setState({ spam_bypass: 1 });
    } else {
      this.setState({ spam_bypass: 0 });
    }
  };

  awsHandler = (e) => {
    if (e.target.checked === true) {
      this.setState({ awsSetting: 1 });
    } else {
      this.setState({ awsSetting: 0 });
    }
  };

  whiteLabelHandlar(e) {
    const { checked } = e.target;
    if (checked === true) {
      this.setState({ enable_ip_whitelist: 1 });
    } else {
      this.setState({ enable_ip_whitelist: 0, whiteLableIP: "" });
    }
  }

  eyeToggle(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      eyeIconToggle: !prevState.eyeIconToggle,
    }));
  }

  handlePass(e) {
    this.setState({
      password: e.target.value,
    });
    if (e.target.value === "" && this.state.cnfmPassword === "")
      this.setState({
        passError: 0,
      });
  }

  handleCnfmPass(e) {
    if (e.target.value === "" && this.state.password === "")
      this.setState({
        passError: 0,
      });

    if (this.state.password === "") {
      return;
    } else {
      this.setState({
        passError: 1,
      });
    }
    const pass = this.state.password;
    const cnfmPass = e.target.value;
    this.setState({
      cnfmPassword: e.target.value,
    });
    if (pass === cnfmPass) {
      this.setState({
        passError: 0,
      });
    } else {
      this.setState({
        passError: 1,
      });
    }
  }

  handleCheckboxAll(e) {
    const { checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        all: value,
        sms: value,
        voice: value,
        email: value,
        whatsapp: value,
        recharge: value,
        notification: value,
        rich_media: value,
        fallback: value,
      },
    }));
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        [name]: value,
      },
    }));
  };

  render() {
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    User Details{" "}
                    <strong className="text-info">
                      {" "}
                      {this.state.selectUserDetails.email}{" "}
                    </strong>{" "}
                  </h3>
                </div>

                {this.state.successMessage ? (
                  <div className={this.state.bgColor} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{this.state.errorMessage}</div>
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={
                                "/admin/user-details/" +
                                this.state.seletedUserId +`/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item active">
                                User Details{" "}
                              </li>{" "}
                            </Link>

                            <Link
                              to={
                                "/admin/user-transaction-details/" +
                                this.state.seletedUserId +`/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>

                            <Link
                              to={
                                "/admin/user-price-details/" +
                                this.state.seletedUserId +`/` + this.state.selectedUserType
                              }
                            >
                              <li className="breadcrumb-item">
                                {" "}
                                Price Details{" "}
                              </li>
                            </Link>
                            {this.state.userType === "reseller" ? null : (
                              <>
                                <Link
                                  to={
                                    "/admin/user-add-price/" +
                                    this.state.seletedUserId +`/` + this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Add Credit{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/user-entity-pass/" +
                                    this.state.seletedUserId + `/` + this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item">
                                    {" "}
                                    Entity By Pass{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/change-currency/" +
                                    this.state.seletedUserId +`/` + this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Change Currency{" "}
                                  </li>
                                </Link>

                                <Link
                                  to={
                                    "/admin/bulk-sms-route/" +
                                    this.state.seletedUserId+`/` + this.state.selectedUserType
                                  }
                                >
                                  {" "}
                                  <li className="breadcrumb-item ">
                                    {" "}
                                    Bulk Sms Route Setting{" "}
                                  </li>
                                </Link>
                                <Link to={`/admin/agent-setting/${this.state.seletedUserId}/${this.state.selectedUserType}`}><li className="breadcrumb-item">Agent Setting</li></Link>
                              </>
                            )}
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className=" flex-fill">
                          <form>
                            <div className="row">
                              <div className="mb-3 col-md-4">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  readOnly={
                                    this.state.userType === "reseller"
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    this.state.selectUserDetails.fullname
                                  }
                                  onChange={(event) =>
                                    this.setState({
                                      fullname: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label
                                  className="form-label"
                                  htmlFor="inputEmail4"
                                >
                                  Email
                                </label>
                                <input
                                  defaultValue={
                                    this.state.selectUserDetails.email
                                  }
                                  type="email"
                                  readOnly
                                  className="form-control"
                                  placeholder="Email"
                                />
                              </div>
                              <div className="mb-3 col-md-4">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Enter Mobile Number
                                </label>
                                <input
                                  type="text"
                                  readOnly={
                                    this.state.userType === "reseller"
                                      ? true
                                      : false
                                  }
                                  defaultValue={
                                    this.state.selectUserDetails.mobile
                                  }
                                  onChange={(event) =>
                                    this.setState({
                                      mobile: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter 10 Digit Mobile Number"
                                />
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Password
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      this.state.eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={this.state.password}
                                    onChange={this.handlePass}
                                    className="form-control"
                                    placeholder="Enter new password"
                                    required
                                  />

                                  <button
                                    className="border-0 "
                                    onClick={this.eyeToggle}
                                  >
                                    {this.state.eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>
                              </div>

                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Confrim Password
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      this.state.eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={this.state.cnfmPassword}
                                    onChange={this.handleCnfmPass}
                                    className="form-control"
                                    placeholder="Enter new password"
                                    required
                                  />
                                  <button
                                    className="border-0 "
                                    onClick={this.eyeToggle}
                                  >
                                    {this.state.eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>

                                {this.state.passError === 1 && (
                                  <p
                                    className="text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    *The passwords you entered don't match
                                  </p>
                                )}
                              </div>
                            </div> */}

                            {this.state.userType === "reseller" ? null : (
                              <>
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="inputAddress"
                                  >
                                    Address
                                  </label>
                                  <textarea
                                    defaultValue={
                                      this.state.selectUserDetails
                                        .address_line_1
                                    }
                                    className="form-control"
                                    onChange={(event) =>
                                      this.setState({
                                        address_line_1: event.target.value,
                                      })
                                    }
                                    placeholder="1234 Main St"
                                  ></textarea>
                                </div>
                              </>
                            )}
                            <div className="row">
                              {this.state.userType === "reseller" ? null : (
                                <>
                                  <div className="mb-3 col-md-4">
                                    <label
                                      className="form-label"
                                      htmlFor="inputCity"
                                    >
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      defaultValue={
                                        this.state.selectUserDetails.country
                                      }
                                      onChange={(event) =>
                                        this.setState({
                                          country: event.target.value,
                                        })
                                      }
                                      placeholder="Country"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-3 col-md-4">
                                    <label
                                      className="form-label"
                                      htmlFor="inputCity"
                                    >
                                      City
                                    </label>
                                    <input
                                      defaultValue={
                                        this.state.selectUserDetails.city
                                      }
                                      onChange={(event) =>
                                        this.setState({
                                          city: event.target.value,
                                        })
                                      }
                                      type="text"
                                      placeholder="City"
                                      className="form-control"
                                    />
                                  </div>
                                </>
                              )}

                              {this.state.userType === "admin" ? (
                                <div className="mb-3 col-md-4">
                                  <label
                                    className="form-label"
                                    htmlFor="inputCity"
                                  >
                                    Select Parent
                                  </label>
                                  <select
                                    value={this.state.parent}
                                    onChange={(event) =>
                                      this.setState({
                                        parent: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                  >
                                    {this.state.employeeList.map((item, i) => (
                                      <option value={item.id}>
                                        {item.fullname}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : null}
                            </div>

                            <div className="row">
                              {this.state.userType === "reseller" ? null : (
                                <>
                                  <div className="col-md-4">
                                    <label
                                      className="form-label"
                                      htmlFor="inputCity"
                                    >
                                      Email Cost
                                    </label>
                                    <input
                                      defaultValue={
                                        this.state.selectUserDetails.email_cost
                                      }
                                      onChange={(event) =>
                                        this.setState({
                                          email_cost: event.target.value,
                                        })
                                      }
                                      type="text"
                                      placeholder="City"
                                      className="form-control"
                                    />
                                  </div>

                                  <div className="col-md-4">
                                    <label
                                      className="form-label"
                                      htmlFor="inputCity"
                                    >
                                      Template Editable{" "}
                                    </label>

                                    <select
                                      className="form-control"
                                      value={this.state.template_editable}
                                      onChange={(event) =>
                                        this.setState({
                                          template_editable: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                </>
                              )}

                              <div className="col-md-4 mt-2">
                                <label
                                  className="form-label "
                                  htmlFor="inputCity"
                                >
                                  Extend user validity{" "}
                                </label>
                                <div className="input-group mb-2 mr-sm-2">
                                  <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                  </div>
                                  <DatePicker
                                    className={`form-control btn-block ${
                                      this.state.specialUser ? " " : "pe-none"
                                    }`}
                                    dateFormat="yyyy/MM/dd"
                                    value={this.state.expiryDate}
                                    readOnly={
                                      this.state.userType === "reseller"
                                        ? true
                                        : false
                                    }
                                    onChange={this.dateFrom}
                                    selected={this.state.SelecteddateFrom}
                                    placeholderText="From Date"
                                  />
                                </div>
                              </div>

                              <div className="row ml-2">
                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    checked={
                                      this.state.checkBox.all === 1
                                        ? true
                                        : false
                                    }
                                    name="all"
                                    value={this.state.checkBox.all}
                                    onChange={this.handleCheckboxAll}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    All
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    checked={
                                      this.state.checkBox.sms === 1
                                        ? true
                                        : false
                                    }
                                    name="sms"
                                    value={this.state.checkBox.sms}
                                    onChange={this.handleCheckbox}
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    SMS
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="voice"
                                    checked={
                                      this.state.checkBox.voice === 1
                                        ? true
                                        : false
                                    }
                                    value={this.state.checkBox.voice}
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Voice
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    value={this.state.checkBox.email}
                                    checked={
                                      this.state.checkBox.email === 1
                                        ? true
                                        : false
                                    }
                                    name="email"
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Email
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="whatsapp"
                                    value={this.state.checkBox.whatsapp}
                                    checked={
                                      this.state.checkBox.whatsapp === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Whatsapp
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="recharge"
                                    value={this.state.checkBox.recharge}
                                    checked={
                                      this.state.checkBox.recharge === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Recharge
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="notification"
                                    value={this.state.checkBox.notification}
                                    checked={
                                      this.state.checkBox.notification === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Notification
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="rich_media"
                                    value={this.state.checkBox.rich_media}
                                    checked={
                                      this.state.checkBox.rich_media === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Rich Media
                                  </label>
                                </div>

                                <div className="form-check col-md-2">
                                  <input
                                    className="form-check-input cursor-pointer
                                  "
                                    type="checkbox"
                                    name="fallback"
                                    value={this.state.checkBox.fallback}
                                    checked={
                                      this.state.checkBox.fallback === 1
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleCheckbox}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Fallback
                                  </label>
                                </div>
                              </div>

                              {this.state.userType === "reseller" ? null : (
                                <>
                                  <div className="col-md-4 mt-5">
                                    <div className="ml-4">
                                      <input
                                        onChange={this.spamHandlar}
                                        type="checkbox"
                                        checked={this.state.spam_bypass}
                                        className="form-check-input mr-2"
                                        id="fallback"
                                      />{" "}
                                      <span className="p-2">Spam By Pass </span>
                                    </div>
                                  </div>

                                  <div className="col-md-4 mt-5">
                                    <div className="ml-4">
                                      <input
                                        defaultChecked={
                                          this.state.enable_ip_whitelist
                                            ? "checked"
                                            : null
                                        }
                                        onClick={(e) =>
                                          this.whiteLabelHandlar(e)
                                        }
                                        type="checkbox"
                                        className="form-check-input mr-2"
                                        id="fallback"
                                      />{" "}
                                      <span className="p-2">
                                        Add White Label IP Address{" "}
                                      </span>
                                    </div>

                                    {this.state.enable_ip_whitelist ===
                                    0 ? null : (
                                      <div className="mt-2">
                                        <input
                                          onChange={(event) =>
                                            this.setState({
                                              whiteLableIP: event.target.value,
                                            })
                                          }
                                          type="text"
                                          defaultValue={this.state.whiteLableIP}
                                          placeholder="Example : 192.168.4.2, 193.168.4.2, 196.128.4.2"
                                          className="form-control"
                                        />
                                        <small className="form-text text-info text-muted">
                                          Add comma(,) if you have multiple IP
                                          address
                                        </small>
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-4 mt-5">
                                    <div className="ml-4">
                                      <input
                                        onChange={this.awsHandler}
                                        type="checkbox"
                                        checked={this.state.awsSetting}
                                        className="form-check-input mr-2"
                                        id="fallback"
                                      />{" "}
                                      <span className="p-2"> AWS Setting </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <hr />

                            <div className="float-right">
                              <input
                                onClick={() => this.UpdateUserDetails()}
                                type="button"
                                value="Update User Details"
                                className="btn btn-sm  btn-primary"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default UserDetails;
