import React, { useState } from "react";
import "../Dashboard/assets/css/app.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUser,
  faWallet,
  faPowerOff,
  faTimesCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "../Dashboard/DashboardLeftMenu";
import { useEffect } from "react";
import { checkUserUrl } from "../Utils/Utils";
import { userloginDetails } from "../Utils/AdminAuth";
import { userProfile } from "../Dashboard/api/Api";

const DashboardHeader = (props) => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const url = lStorage?.url;

  const isAdmin = userloginDetails();
  const IsAdminlogin = isAdmin.success;
  const parent_type = lStorage?.user?.parent_type
  const [IsAdminLogin] = useState(IsAdminlogin);
  const [balance, setbalance] = useState(null);
  const [country_currency, setcountry_currency] = useState(null);

  useEffect(() => {
    userProfile().then((result) => {
      if (result.data.success === true) {
        setbalance(result.data.data[0].currency);
        setcountry_currency(result.data.data[0].country_currency);
      }
    })
  }, []);

  const goBackToAdmin = () => {
    localStorage.removeItem("login");
    window.location = "/admin";
  };

  return (
    <div  style={{ margin: "2em"}}>
    <nav className="navbar dashboard-header navbar-expand navbar-light navbar-bg fixed-top">
      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navheader navbar-align" style={{ justifyCcontent: "flex-end" }}>
 
          <li
            style={{ position: "relative" }}
            className="nav-item border-right"
          >
            <a
              style={{ color: "#3b7ddd" }}
              className="nav-icon"
              href={parent_type !== "reseller" && 
                `${checkUserUrl(url) === true
                ? "/dashboard/transaction-payment"
                : "/dashboard/transaction-history"
                }`}
            >
              <div className="position-relative wallet">
                <FontAwesomeIcon icon={faWallet} />{" "}
                <span className="wallet-price">
                  {" "}
                  {country_currency} : {parseFloat(balance).toFixed(2)}
                </span>
              </div>
              {parent_type !== "reseller" &&
              <span
                style={{
                  fontSize: 11 + "px",
                  position: "absolute",
                  left: 50 + "%",
                }}
              >
                Recharge
              </span>}
            </a>
          </li>

          <li className="nav-item border-right">
            <Link
              className="nav-icon"
              to="/dashboard/download-centre"
              id="alertsDropdown"
              data-toggle=""
            >
              <div className="position-relative">
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </Link>
          </li>
          {checkUserUrl(url) && (
            <li className="nav-item border-right">
              <Link
                className="nav-icon"
                to="/dashboard/notification"
                id="alertsDropdown"
                data-toggle=""
              >
                <div className="position-relative">
                  <FontAwesomeIcon icon={faBell} color="red" />
                  <span className="indicator">1</span>
                </div>
              </Link>
            </li>
          )}
          <li className="nav-item border-right">
            <Link
              className="nav-icon dropdown-toggle"
              to="/dashboard/my-profile"
              id="alertsDropdown"
            >
              <span style={{ cursor: "pointer" }} className="">
                <div className="position-relative">
                  <FontAwesomeIcon icon={faUser} />
                </div>
              </span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="nav-icon dropdown-toggle"
              to="/logout"
              id="alertsDropdown"
            >
              <span style={{ cursor: "pointer" }} className="">
                <div className="position-relative">
                  <FontAwesomeIcon icon={faPowerOff} />
                </div>
              </span>
            </Link>
          </li>

          {IsAdminLogin ? (
            <li className="nav-item dropdown">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => goBackToAdmin()}
                className="nav-icon"
              >
                <div color="red" className="position-relative">
                  <FontAwesomeIcon color="red" icon={faTimesCircle} />
                </div>
              </span>
            </li>
          ) : null}
        </ul>

      </div>
    </nav>
    </div>
  );
};

export default DashboardHeader;
