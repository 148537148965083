import React, { Component } from 'react';
import Dialog from 'react-dialog';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardLeftMenu from '../DashboardLeftMenu';
import { HOST_URL } from '../api/Api';

class EntityID extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            entity_id: '',
            updateId: '',
            deleteID: '',
            delete_entity_id: '',
            company_name: '',
            updateEntity: false,
            enitytList: null,
            errorMessage: '',
            isDialogOpen: false,
            successMessage: false,
            bgColor: "alert alert-success alert-dismissible",
            chainid: "",
            operator: ""
        }
    }


    componentDidMount() {

        this.ListOfEnlityId();

    }

    ListOfEnlityId() {

        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ enitytList: result });
                }

            })
        })
    }

    CreateEntityId() {

        var entidyData = {
            user_id: this.state.user_id,
            token: this.state.token,
            entity_id: this.state.entity_id,
            method: 'create',
            company_name: this.state.company_name,
            chainid: this.state.chainid,
            operator: this.state.operator
        };

        fetch(`${HOST_URL}/entityid.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(entidyData)
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", errorMessage: resp.message });
                    document.getElementById("EntityFormidFrom").reset();
                    this.ListOfEnlityId();
                } else {
                    this.setState({ bgColor: "alert alert-danger alert-dismissible", successMessage: true, errorMessage: resp.message });
                }
            })
        })
    }

    EditEntityID(id) {

        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=retrieveid&id=${id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                this.setState({
                    updateId: id,
                    updateEntity: true,
                    entity_id: result.data[0].entity_id,
                    company_name: result.data[0].company_name,
                    chainid: result.data[0].chainid,
                    operator: result.data[0].operator
                });
            })
        })

    }

    DeleteEntityID(id) {
        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=delete&id=${id}&entity_id=${this.state.delete_entity_id}&token=${this.state.token}`).then((response) => {
            // console.log(response);
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", deleteID: '' });
                    this.setState({ errorMessage: result.message });
                    this.ListOfEnlityId();
                    this.handleClose();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })
    }

    UpdateEntityID() {
        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=update&id=${this.state.updateId}&entity_id=${this.state.entity_id}&company_name=${this.state.company_name}&chainid=${this.state.chainid}&operator=${this.state.operator}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                    document.getElementById("EntityFormidFrom").reset();
                    this.ListOfEnlityId();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })

    }

    openDialog = (id, entity_id) => this.setState({
        isDialogOpen: true,
        deleteID: id,
        delete_entity_id: entity_id
    })

    handleClose = () => this.setState({ isDialogOpen: false, deleteID: '', delete_entity_id: '' })


    render() {
        console.log(this.state.enitytList)
        return (

            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">

                        <div className="card">
                            <div className="card-body">

                                <div className="container-fluid p-0">

                                    {
                                        this.state.isDialogOpen &&

                                        <Dialog
                                            title="Alert"
                                            modal={true}
                                            onClose={this.handleClose}
                                            buttons={
                                                [{
                                                    text: "Close",
                                                    className: 'btn-info btn-sm btn mr-2',
                                                    onClick: () => this.handleClose()
                                                },
                                                {
                                                    text: "ok",
                                                    className: 'btn btn-sm btn-danger',
                                                    onClick: () => this.DeleteEntityID(this.state.deleteID)
                                                }

                                                ]


                                            }>
                                            <p>Are You Sure ? This action will also delete all sender ids associated with this entity id </p>
                                        </Dialog>
                                    }

                                    <div className="">

                                        <div className="row">




                                            <div className="col-md-12">

                                                {
                                                    this.state.successMessage ?
                                                        <div className={this.state.bgColor} role="alert">
                                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                            <div className="">
                                                                {this.state.errorMessage}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }


                                                <h5 className="card-title">Add  Entity ID</h5>

                                                <div className="">
                                                    <form className="form-row" id="EntityFormidFrom">
                                                        <div className="mb-3  col-md-6">
                                                            <input type="text" defaultValue={this.state.company_name} onChange={(event) => this.setState({ company_name: event.target.value })} placeholder="Company Name" className="form-control" id="inputPasswordNew2" />
                                                        </div>

                                                        <div className="mb-3 col-md-6">
                                                            <input type="text" defaultValue={this.state.entity_id} onChange={(event) => this.setState({ entity_id: event.target.value })} placeholder="Enter Entity ID" className="form-control" id="inputPasswordNew2" />
                                                        </div>


                                                    </form>

                                                    <form className="form-row" id="EntityFormidFrom">
                                                        <div className="mb-3  col-md-6">
                                                            <input type="text" defaultValue={this.state.chainid} onChange={(event) => this.setState({ chainid: event.target.value })} placeholder="Chain ID" className="form-control" id="inputPasswordNew2" />
                                                        </div>

                                                        <div className="mb-3 col-md-6">
                                                            <select class="form-control" name="operator" value={this.state.operator} onChange={(e) => this.setState({ operator: e.target.value })} >
                                                                <option value="">Please Select Chain Operator</option>
                                                                <option value="Airtel">Airtel</option>
                                                                <option value="Vilpower">Vilpower</option>
                                                                <option value="Jio">Jio</option>
                                                                <option value="SmartPing">SmartPing</option>
                                                                <option value="BSNL">BSNL</option>
                                                                <option value="MTNL">MTNL</option>
                                                                <option value="Tata">Tata</option>
                                                            </select>
                                                        </div>
                                                    </form>

                                                    <form className="form-row" id="EntityFormidFrom">
                                                        <div className="col-md-12 d-flex justify-content-center">
                                                            {this.state.updateEntity
                                                                ? <button type="button" onClick={() => this.UpdateEntityID()} className="btn btn-sm btn-success">Update Entity ID</button>
                                                                : <button type="button" onClick={() => this.CreateEntityId()} className="btn btn-sm btn-success">Create New Entity</button>
                                                            }
                                                        </div>
                                                    </form>



                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="">

                                                <div className="p-2">

                                                    <div className="">
                                                        <div className=" flex-fill">
                                                            {this.state.enitytList ?
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className=" d-xl-table-cell"> Action </th>
                                                                                <th className=" d-md-table-cell">Entity ID</th>
                                                                                <th className=" d-md-table-cell">Company Name</th>
                                                                                <th className=" d-md-table-cell">Chain ID</th>
                                                                                <th className=" d-md-table-cell">Operator</th>
                                                                                <th className=" d-md-table-cell">Status</th>
                                                                                <th className=" d-xl-table-cell">Created Date / Time </th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {
                                                                                this.state.enitytList.data.map((item, i) =>
                                                                                    <tr key={i}>

                                                                                        <td>
                                                                                            <span onClick={() => this.EditEntityID(item.id)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                                            <span onClick={() => this.openDialog(item.id, item.entity_id)} className="badge bg-danger">
                                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                                            </span>
                                                                                        </td>

                                                                                        <td className=" d-xl-table-cell">{item.entity_id}</td>
                                                                                        <td className=" d-xl-table-cell">{item.company_name}</td>
                                                                                        <td className=" d-xl-table-cell">{item.chainid}</td>
                                                                                        <td className=" d-xl-table-cell">{item.operator}</td>
                                                                                        <td className=" d-xl-table-cell">{item.chain_status === 0 ? <span>No</span> : <span>Yes</span>}</td>
                                                                                        <td className=" d-md-table-cell">{item.created}</td>

                                                                                    </tr>
                                                                                )}
                                                                        </tbody>
                                                                    </table> </div> :
                                                                <div className="text-center">
                                                                    <div className="card-body">
                                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                        <p>No Entity ID Yet</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        );
    }
}

export default EntityID;