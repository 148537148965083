import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class TransactionHistory extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);

        super();
        this.state = {
            user_id: userId,
            userType: isAdmin,
            token: userToken,
            dateFrom: date,
            dateTo: date,
            allTransactionList: null,
            serchData: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }

    }

    componentDidMount() {
        this.onserch();
    }

    onserch() {
        fetch(`${HOST_URL}/transaction_history.php?user_id=${this.state.user_id}&method=transaction_history&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ allTransactionList: result });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    searchUser() {

        if (this.state.serchData === '') {
            this.onserch();
            this.setState({ successMessage: true });
            this.setState({ errorMessage: "Enter User Name " });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
        } else {
            fetch(`${HOST_URL}/transaction_history.php?user_id=${this.state.user_id}&method=transaction_history&type=${this.state.userType}&keyword=${this.state.serchData}&token=${this.state.token}`).then((response) => {
                response.json().then((result) => {
                    //console.log()
                    if (result.success === true) {
                        this.setState({ successMessage: false });
                        this.setState({ allTransactionList: result });
                    } else {
                        this.setState({ successMessage: true });
                        this.setState({ errorMessage: result.message });
                        this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    }
                })
            })
        }
    }

    searchWithDate() {

        fetch(`${HOST_URL}/transaction_history.php?user_id=${this.state.user_id}&method=transaction_history&type=${this.state.userType}&keyword=${this.state.serchData}&token=${this.state.token}&from_date=${this.state.dateFrom}&to_date=${this.state.dateTo}`).then((response) => {
            response.json().then((result) => {
                //console.log()
                if (result.success === true) {
                    this.setState({ successMessage: false, errorMessage: "Result Updated" });
                    this.setState({ allTransactionList: result });
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                }
            })
        })

    }

    dateFrom = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateFrom: dated,
            SelecteddateFrom: date
        });
    };

    dateTo = (date) => {
        let dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateTo: dated,
            SelecteddateTo: date
        });
    };



    render() {
        // console.log(this.state)
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Transaction </strong> History </h3>
                                </div>
                            </div>

                            <div className="pl-2 pr-2">
                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">

                                        <div className="card-body">

                                            <div className="filter-option">

                                                <h6 className="card-subtitle text-muted">Filter </h6>

                                                <div className="row">
                                                    <div className="col-md-9 mb-3">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /></div>
                                                            <input type="text" onChange={(event) => { this.setState({ serchData: event.target.value }) }} placeholder="Enter User Name  " className="form-control" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3 text-right">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <input type="button" onClick={() => this.searchUser()} value="Search" className="btn mt-1  btn-primary btn-block btn-sm ml-auto" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                                            <DatePicker className="form-control btn-block"
                                                                dateFormat="yyyy/MM/dd"
                                                                value={this.state.dateFrom}
                                                                onChange={this.dateFrom}
                                                                selected={this.state.SelecteddateFrom}
                                                                placeholderText='From Date'
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                                            <DatePicker className="form-control btn-block"
                                                                dateFormat="yyyy/MM/dd"
                                                                value={this.state.dateTo}
                                                                onChange={this.dateTo}
                                                                selected={this.state.SelecteddateTo}
                                                                placeholderText='Date To'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 text-right">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <input type="button" onClick={() => this.searchWithDate()} value="Search With Date Only" className="btn mt-1  btn-primary btn-block btn-sm ml-auto" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            {this.state.allTransactionList ?

                                                <div style={{ height: 400 + 'px' }} className="table-responsive">
                                                    <table className="table table-hover ">
                                                        <thead className="sticky-top">
                                                            <tr>

                                                                <th> User Email </th>

                                                                <th> Amount </th>

                                                                <th> Transaction Type </th>
                                                                <th style={{ width: 300 + 'px', 'whiteSpace': 'normal' }} > Description </th>
                                                                <th> Transaction ID </th>
                                                                <th> Gateway Req ID </th>

                                                                <th> Created Date / Time</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.allTransactionList.data.map((item, i) =>
                                                                    <tr key={i}>


                                                                        <td>{item.email}</td>

                                                                        <td className="text-primary font-weight-bold">Rs. {item.amount}</td>


                                                                        <td><span className="badge badge-success text-white">{item.trans_type}</span> </td>
                                                                        <td style={{ width: 300 + 'px', 'whiteSpace': 'normal' }}>{item.description}</td>
                                                                        <td>{item.transaction_id}</td>
                                                                        <td>{item.gateway_req_id}</td>
                                                                        <td>{item.created}</td>

                                                                    </tr>
                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                        <p> No Any Record Found </p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default TransactionHistory;